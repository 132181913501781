import resources from "../../data/catalogs/map/resources.json";
import * as gameIcons from "react-icons/gi";

export type Resource = keyof typeof resources;

export interface IResource {
    development: Record<string, number>,
    visual: keyof typeof gameIcons
}

export class ResourcesCatalog {
    private readonly _resources: Record<string, IResource>

    constructor() {
        this._resources = Object.fromEntries(Object.entries(resources).map(([name, data]) =>
            ([name, data as IResource]))
        );
    }
    
    getResource(key: string) {
        return this._resources[key];
    }
}
