import {Hex} from "../map/hex";
import {Controller} from "../controllers/controller";
import {IControlled} from "../controllers/controlled";
import {Game} from "../game";
import {IInteraction, IInteractionAttackAgent, IInteractionConsequence, IInteractionConstraint,
    IInteractionConstraintReputation, IInteractionConstraintTarget, IInteractionConstraintThreat,
    IInteractionCreateAgent, IMonsterSpawn, Interactions, IPlaceType, ISpawningControls, PlacesCatalog}
    from "./placesCatalog";
import {Agent} from "../agents/agent";
import {Terrain} from "../map/terrain";
import {Asset} from "../assets/asset";
import {AssetsCatalog, IDevelopment} from "../assets/assetsCatalog";
import {Card} from "../fight/card";
import {ResourcesCatalog} from "../map/resourcesCatalog.";
import {Vector2} from "../../utility/vector2";
import {DevelopmentTypes, TerrainsCatalog} from "../map/terrainsCatalog";

export const REPUTATION_LEVELS = ["unknown", "known", "noted", "celebrated", "renowned", "worshipped"];

const ReputationThresholds = [0, 0.05, 0.1, 0.2, 0.4, 0.6];

interface IResource {
    capacity: number
    production: number
    value: number
}

interface IPlaceTemplate extends IPlaceType {
    hex: Hex
    type: string
}

export interface IPlaceSave {
    assets: string[]
    controller: number
    hex: Vector2
    key: number
    reputation: number
    resources: Record<string, IResource>
    reward?: string
    type: string
}

export class Place implements IControlled {
    private static _created = 0;

    private readonly _key: number;
    private readonly _assets: Asset[] = [];

    private _controller: Controller;
    private _guardian?: Agent;
    private _network: Place[] = [];
    private _reward?: string = undefined;
    private _reputation: number = 0;
    private _resources: Record<string, IResource> = {};
    private _spawned = 0;

    constructor(private _props: IPlaceTemplate, controller: Controller, terrain: Terrain,
                private _resourceCatalog: ResourcesCatalog, private _terrainsCatalog: TerrainsCatalog, key?: number) {
        if (key) {
            this._key = key;
            Place._created = Math.max(key + 1, Place._created);
        } else {
            this._key = ++Place._created;
        }
        this._controller = controller;
        this._controller.giveControlPlace(this, terrain);
        this.setInitialResources(terrain);
    }

    get agents() {
        return {
            add: () => ++this._spawned,
            remove: () => --this._spawned
        };
    }

    get assets() {
        return this._assets
    }

    get category() {
        return this._props.category;
    }

    get controller() {
        return this._controller;
    }

    get guardian() {
        return this._guardian;
    }

    get hex() {
        return this._props.hex;
    }

    get interactions() {
        return this._props.interactions || {} as Record<Interactions, IInteraction>;
    }

    get key() {
        return this._key;
    }

    get reputation() {
        return this._reputation;
    }

    get resources() {
        return this._resources;
    }

    get reward() {
        return this._reward;
    }

    get type() {
        return this._props.type;
    }

    get spawned() {
        return this._spawned;
    }

    get vision() {
        return this._props.vision
    }

    get visual() {
        return this._props.visual;
    }

    acceptsOrder(order: string, controller: Controller) {
        return false;
    }
    claimReward() {
        const reward = this._reward;

        this._reward = undefined;
        return reward;
    }

    developAssets(game: Game) {
        const assets = game.assetsCatalog.getLegalAssets(this, game)
            .filter(asset => Object.entries(game.assetsCatalog.getAssetCost(asset))
                .every(([area, cost]) => this.getResources(area) >= cost));
        const random = game.random.getRandom(assets);

        if (random) {
            this.buildAsset(random, game.assetsCatalog);
            this.updateResources(game.terrain);
        } else {
            // Growth
            Object.values(this._resources).forEach(resource => resource.value =
                Math.min(resource.value + resource.production, resource.capacity));
        }
        return Promise.resolve(true);
    }

    getCards() {
        const cards = [Card.catalog.createFromDistribution(this._props.cards ?? {}, Card)];
        
        this.assets.forEach(asset => {
            cards.push(asset.getCards());
        })
        return cards.flat();
    }

    getGuardian(game: Game) {
        return game.getAgents(this.hex).filter(agent => agent.roles.includes("guardian")).pop();
    }

    private constraintLambdas: Record<string, (constraint: IInteractionConstraint, game: Game, hex: Hex) => boolean> = {
        reputation: (constraint) => {
            const reputationConstraint = constraint as IInteractionConstraintReputation;

            return this.reputation >= reputationConstraint.reputation;
        },
        target: ((constraint, game, hex) => {
            const targetConstraint = constraint as IInteractionConstraintTarget;

            return game.terrain.getHexDistance(this.hex, hex) <= targetConstraint.range &&
                game.getAgents(hex).some(agent =>
                    targetConstraint.target === "enemy" &&
                    (agent.controller.isEnemy(game.playerController.faction) || agent.getOutcast())
            );
        }),
        threat: ((constraint, game) => {
            const threatConstraint = constraint as IInteractionConstraintThreat;

            return threatConstraint.threat === "none" && game.getAgents(this.hex).every(agent =>
                ! agent.controller.isEnemy(game.playerController.faction));
        })
    };

    getInteraction(type: Interactions, game: Game, hex: Hex) {
        const interactions = [this.interactions[type], ...this.assets.map(asset => asset.interactions[type])]
            .filter(Boolean)
            .filter(interaction => interaction?.constraints.every(constraint =>
                this.constraintLambdas[constraint.type] && this.constraintLambdas[constraint.type](constraint, game, hex)))
            .sort((lhs, rhs) => lhs.priority - rhs.priority);

        return interactions.pop();
    }

    // The likelihood that an agent will loot it if they are able
    getPillageProbability() {
        return Math.min(1, Object.values(this.resources).reduce((maximum, resource) =>
            Math.max(maximum, resource.value), 0) / 100);
    }

    hasState(state: string) {
        return this._props.states.includes(state) || Boolean(this.assets.find(asset => asset.states.includes(state)));
    }

    impress(agent: Agent, impressiveness: number, game: Game) {
        if (this.category === "settlement") {
            let reputation = this._reputation;

            for (let threshold = this._reputation; threshold < ReputationThresholds.length; ++threshold) {
                if (impressiveness > ReputationThresholds[threshold]) {
                    reputation = threshold;
                }
            }
            if (reputation >= this._reputation) {
                this._reputation = reputation;
                this._reward = this.getReward(agent, this._reputation, game);
                this.controller.makeFriend(agent.controller.faction);
            }
        }
    }

    pillage(game: Game) {
        if (this.hasState("defended")) {
            Object.values(this._resources).forEach(resource => resource.value =
                Math.max(0, resource.value * Math.round(0.8)));
        } else {
            Object.values(this._resources).forEach(resource => resource.value = 0);
            this.updateResources(game.terrain);
        }
    }

    ransack(game: Game) {
        Object.values(this._resources).forEach(resource => resource.value = 0);
        if (this._assets.length === 0) {
            game.removePlace(this);
        } else {
            this._assets.splice(Math.floor(game.random.get() * this._assets.length), 1);
        }
    }

    private get spawning() {
        return this._props.spawning || {} as ISpawningControls;
    }

    private consequenceLambdas: Record<string, (consequence: IInteractionConsequence, game: Game) => void> = {
        deleteAsset: (consequence) => {
            const index = this.assets.findIndex(asset => asset.type === consequence.asset);
            
            if (index >= 0) {
                this.assets.splice(index, 1);
            }
        },
        deleteSettlement: (consequence, game) => {
            game.removePlace(this);
        },
        setResources: (consequence) => {
            Object.entries(consequence.resources || {}).forEach(([resource, value]) =>
                this._resources[resource].value = value)
        }
    };

    private interactionLambdas: Record<string, (interaction: IInteraction, game: Game, hex: Hex) => Promise<boolean>> = {
        attackAgent: (interaction, game, hex) => {
            const attackAgent = interaction as IInteractionAttackAgent;
            const agents = game.getAgents(hex)
                .filter(agent => agent.controller.isEnemy(game.playerController.faction))
                .filter(agent => agent.health <= attackAgent.damage);

            agents.forEach(agent => game.removeAgent(agent));
            return Promise.resolve(false);
        },
        createAgent: (interaction, game, hex) => {
            const createAgent = interaction as IInteractionCreateAgent;

            if (hex && game.getAgents(hex).length === 0) {
                game.addAgent(createAgent.agent, hex, this.controller, [], game.terrain);
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        },
        createAndEscortAgent: (interaction, game, hex) => {
            return this.interactionLambdas.createAgent(interaction, game, hex).then(created =>
                created && game.actions.moveAgent(game.playerController.playerAgent, hex));
        }
    }
    
    resolveInteraction(type: Interactions, game: Game, hex: Hex) {
        const interaction = this.getInteraction(type, game, hex);

        if (interaction) {
            if (this.interactionLambdas[interaction.type]) {
                this.interactionLambdas[interaction.type](interaction, game, hex).then(() => {
                    interaction.consequences.forEach(consequence => this.consequenceLambdas[consequence.type] &&
                        this.consequenceLambdas[consequence.type](consequence, game));
                    this.updateNetwork(game);
                });
            }
        }
    }
    
    spawnGuardian(game: Game) {
        const agentsOnHex = game.getAgents(this.hex);

        if (agentsOnHex.length === 0 && this.spawning) {
            const guardian = game.random.getFromDistribution(this.spawning.guardians ?? {});

            if (guardian) {
                this._guardian = game.addAgent(guardian, this.hex, this.controller, ["guardian"], game.terrain);
            }
        }
        return Promise.resolve(true);
    }

    spawnMonster(game: Game) {
        const monsterSpawning = [this.spawning.monsters, ...this.assets.map(asset => asset.spawning.monsters).flat()]
            .filter((spawn): spawn is IMonsterSpawn => Boolean(spawn));
        
        if (monsterSpawning.length > 0) {
            const maximum = monsterSpawning.reduce((maximum, spawn) => maximum + spawn.maximum, 0);

            monsterSpawning.forEach(monsterSpawn =>
                monsterSpawn.types.forEach(spawn => {
                    if (this._spawned < maximum && game.random.get() <= spawn.likelihood) {
                        const agentType = game.agentsCatalog.get(spawn.type);
                        const hexes = game.terrain.getSurroundingHexes(this.hex, monsterSpawn.range ?? 0)
                            .filter(hex => agentType?.passable.includes(hex.type))
                            .filter(hex => game.getAgents(hex).length === 0);
                        const spawnHex = game.random.getRandom(hexes);

                        if (spawnHex) {
                            game.addAgent(spawn.type, spawnHex, this.controller, spawn.roles || [], game.terrain);
                            ++this._spawned;
                        }
                    }
                })
            );
        }
    }

    static serialiseIn(save: IPlaceSave, controller: Controller, assetsCatalog: AssetsCatalog,
                       placesCatalog: PlacesCatalog, resourceCatalog: ResourcesCatalog,
                       terrainsCatalog: TerrainsCatalog, terrain: Terrain): Place {
        const template = placesCatalog.create(terrain.getHex(save.hex), save.type) as IPlaceTemplate;
        const place = new Place(template, controller, terrain, resourceCatalog, terrainsCatalog, save.key);

        place._assets.push(...save.assets.map(asset => assetsCatalog.generate(place, asset)));
        place._resources = {...save.resources};
        place._reputation = save.reputation || 0;
        place._reward = save.reward;
        place.updateResources(terrain);
        return place;
    }

    serialiseOut() {
        return {
            assets: this._assets.map(asset => asset.type),
            controller: this.controller.key,
            hex: this.hex.position,
            key: this._key,
            resources: this._resources,
            reputation: this._reputation,
            reward: this._reward,
            type: this.type
        };
    }

    // Update resources based on the network of places on the map
    updateNetwork(game: Game) {
        this._network = game.terrain.getSurroundingHexes(this.hex, 1)
            .filter(hex => hex !== this.hex)
            .map(hex => game.getPlace(hex))
            .filter(place => place?.category === "settlement") as Place[];
        this.updateResources(game.terrain);
    }

    upgradeSettlement(game: Game) {
        const upgrades = this.assets.map(asset => asset.upgrade).filter(upgrade => upgrade);
        const randomUpgrade = game.random.getRandom(upgrades);

        if (randomUpgrade && this.type !== randomUpgrade.type &&
            Object.entries(randomUpgrade.cost).every(([development, cost]) =>
                cost <= this._resources[development].value)) {
            Object.entries(randomUpgrade.cost).every(([development, cost]) =>
                this._resources[development].value -= cost);
            this._props.type = randomUpgrade.type;
            this._props.visual = game.placesCatalog.getVisual(randomUpgrade.type);
        }
    }

    private buildAsset(type: string, catalog: AssetsCatalog) {
        const asset = catalog.generate(this, type);

        Object.entries(asset.cost).forEach(([area, cost]) =>
            this._resources[area].value = Math.max(this._resources[area].value - cost, 0)
        );
        this.assets.push(asset);
    }

    private getResources(development: string) {
        return this._resources[development]?.value || 0;
    }

    private getReward(agent: Agent, reputation: number, game: Game) {
        const rewards: Record<string, number> = {};

        this.assets.forEach(asset => Object.entries(asset.getRewards(reputation))
            .filter(([reward]) => {
                const owned = agent.equipment.find(owned => owned.type === reward);
                
                return owned === undefined || owned.damage > 0;
            })
            .forEach(([reward, probability]) =>
                rewards[reward] = Math.max(rewards[reward] || 0, probability)));
        return game.random.getFromDistribution(rewards);
    }

    private getNetworkBonus() {
        return {
            "trade": 1
        }
    }

    private setInitialResources(terrain: Terrain) {
        this.updateResources(terrain);
    }

    private getPlaceResources(terrain: Terrain): Record<string, IResource> {
        const updateMember = (resources: Record<string, IResource>, member: "production" | "capacity",
                              development: Record<string, number>) => {
            Object.entries(development).forEach(([resource, value]) =>
                resources[resource][member] += value);
        };
        const getMembers = (member: "production" | "capacity", development: Record<string, IDevelopment>) => {
            return Object.fromEntries(Object.entries(development).map(([development, values]) =>
                ([development, values[member] || 0])));
        }

        const resources = Object.fromEntries(DevelopmentTypes.map(development => (
            [development, {
                capacity: this._props.development[development]?.capacity || 0,
                production: this._props.development[development]?.production || 0,
                value: this._resources[development]?.value || 0
            }])));

        // Increment place specific development
        updateMember(resources, "production", getMembers("production", this._props.development));
        // Increment asset specific development
        this.assets.forEach(asset => {
            updateMember(resources, "production", getMembers("production", asset.development));
            updateMember(resources, "capacity", getMembers("capacity", asset.development));
        });
        terrain.getSurroundingHexes(this.hex, 1)
            .forEach(hex => {
                // Increment terrain specific development
                updateMember(resources, "production", this._terrainsCatalog.getTerrain(hex.type).development);
                if (hex.resource) {
                    // Increment hex resource specific development
                    updateMember(resources, "production", this._resourceCatalog.getResource(hex.resource).development);
                }
            });
        // Increments do not go negative
        Object.values(resources).forEach(resource => {
            resource.capacity = Math.max(resource.capacity, 0);
            resource.production = Math.max(resource.production, 0);
        });
        return resources;
    }

    private getNetworkProduction(place: Place, terrain: Terrain): Record<string, number> {
        const networkProductionRatio = 0.2;

        return Object.fromEntries(Object.entries(place.getPlaceResources(terrain)).map(([type, resources]) =>
            ([type, Math.round(resources.production * networkProductionRatio)])
        ));
    }
    private updateResources(terrain: Terrain) {
        this._resources = this.getPlaceResources(terrain);

        // Networked settlements give a network bonus
        this._network.forEach(place => Object.entries(place.getNetworkProduction(place, terrain))
            .forEach(([type, production]) => this.resources[type].production += production));
    }
}