import {Box, Divider, Typography} from "@mui/material";
import {Agent} from "../../../game/agents/agent";
import {Localisation} from "../../localisation/localisation";
import {connect} from "react-redux";
import {TutorialTooltip} from "../../help/TutorialTooltip";
import React, {useContext} from "react";
import {DebugContext} from "../../debug/DebugProvider";
import {LabelledParameter} from "./LabelledParameter";

interface IAgentDetails {
    agent: Agent
    onClose: () => void
}
function AgentDetails({agent}: IAgentDetails) {
    const debug = useContext(DebugContext);
    const styles = {
        description: {
        },
        title: {
            flexGrow: 1,
            textTransform: "capitalize"
        }
    };

    return (
        <Box>
            <TutorialTooltip next="DetailsClose" placement="top" title="AgentTitle">
                <Box display="flex">
                    <Typography variant="h6" sx={styles.title}>{Localisation.GetText(agent.type)}</Typography>
                </Box>
            </TutorialTooltip>
            <Box sx={styles.description}>
                <Typography sx={styles.description}>{Localisation.GetText(agent.description)}</Typography>
            </Box>
            {debug.getVisible() &&
                <Box>
                    <Divider sx={{margin: 1}}/>
                    <LabelledParameter label="Faction" value={agent.controller.faction}/>
                    <LabelledParameter label="Outcast" value={agent.getOutcast() ? "yes" : "no"}/>
                </Box>
            }
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
});
const connected = connect(mapStateToProps, {
})(AgentDetails);

export {connected as AgentDetails}
