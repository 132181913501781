import {createDeepSlice} from "./base";

export interface IDialog {
    name: string
    data?: any
    help?: string
}

const slice = createDeepSlice<IDialog>("dialog", {name: ""});

export const {remove, update} = slice.actions;
export default slice.reducer;