import {Agent} from "../agents/agent";
import {Game} from "../game";
import {Hex} from "../map/hex";
import {Place} from "../places/place";
import {Terrain} from "../map/terrain";
import {IConstraints} from "../fight/constraints";
import {Fight} from "../fight/fight";
import {Skill} from "../skills/skill";
import {Card} from "../fight/card";
import {Random} from "../../utility/random";
import {Vector2} from "../../utility/vector2";

const KNOWLEDGE_SOURCES = ["seen", "visited"] as const;

type knowledgeSource = typeof KNOWLEDGE_SOURCES[number];

interface IKnowledge {
    hex: Vector2
    source: knowledgeSource
    timestamp: number
}

export interface IReward {
    skill?: string
    item?: string
    reputation: number[]
    feast: number[]
}

export interface IControllerSave {
    enemies: string[]
    faction: string
    friends: string[]
    key: number
    knowledge: Record<string, IKnowledgeSave>
    player: boolean
    random: number
    skills: string[]
}

interface IKnowledgeSave {
    hex: Vector2,
    source: knowledgeSource,
    timestamp: number
}
export interface IController {
    cards: { [type: string]: number }
    constraints: IConstraints
    enemies: string[]
    friends: string[]
    knowledge?: Record<string, IKnowledge>
    prey?: string[]
    skills: string[]
    type: string
}

export abstract class Controller {
    abstract resolve(game: Game): Promise<boolean>;

    private static _created = 0;

    private readonly _key: number;

    protected _cards: Record<string, number>;
    protected _constraints: IConstraints;
    protected _enemies: string[];
    protected _friends: string[];
    protected _knowledge: Record<string, IKnowledge>;
    protected _prey?: string[];
    protected _type: string;

    protected _random: Random;
    protected _agents: Agent[] = [];
    protected _places: Place[] = [];
    protected _skills: string[] = [];

    protected constructor(props: IController, private _faction: string, private _player: boolean, seed: number,
                          key?: number) {
        if (key) {
            this._key = key;
            Controller._created = Math.max(key + 1, Controller._created);
        } else {
            this._key = ++Controller._created;
        }
        this._cards = props.cards;
        this._constraints = {...props.constraints};
        this._enemies = [...props.enemies];
        this._friends = [...props.friends];
        this._knowledge = {...props.knowledge};
        this._prey = props.prey;
        this._random = new Random(seed);
        this._type = props.type;
        props.skills.forEach(skill => this.addSkill(skill));
    }

    get cards() {
        return this._cards;
    }

    get constraints() {
        return this._constraints;
    }

    get faction() {
        return this._faction;
    }

    get key() {
        return this._key;
    }

    get player() {
        return this._player;
    }

    get prey() {
        return this._prey || [];
    }

    get skills() {
        return this._skills;
    }

    get type() {
        return this._type;
    }
    controls(agent: Agent) {
        return this._agents.includes(agent);
    }

    getAttitude(faction: string) {
        if (this._friends.includes(faction)) {
            return "friend";
        } else if (this._enemies.includes(faction)) {
            return "enemy";
        }
        return "neutral";
    }

    getCards() {
        return Card.catalog.createFromDistribution(this.cards, Card);
    }

    getHexesKnown(terrain: Terrain) {
        return Object.values(this._knowledge).map(knowledge => terrain.getHex(knowledge.hex));
    }

    getPlayer() {
        return this._agents.find(agent => agent.roles.includes("player"));
    }

    getSkillKnown(skill: string) {
        return this.skills.includes(skill) ||
            this.getPlayer()?.getEquipped().find(item => item.skills.includes(skill)) !== undefined;
    }

    isEnemy(faction: string) {
        return this._enemies.includes(faction);
    }

    isFriend(faction: string) {
        return this._friends.includes(faction);
    }

    makeEnemy(faction: string) {
        if (!this._enemies.includes(faction)) {
            this._enemies.push(faction);
        }
        if (this._friends.includes(faction)) {
            this._friends.splice(this._friends.indexOf(faction), 1);
        }
    }

    makeFriend(faction: string) {
        if (!this._friends.includes(faction)) {
            this._friends.push(faction);
        }
        if (this._enemies.includes(faction)) {
            this._enemies.splice(this._enemies.indexOf(faction), 1);
        }
    }

    moveAgent(agent: Agent, hex: Hex, game: Game) {
        const self = this;
        const moved = agent.move(game, hex);

        if (moved) {
            self.updateKnowledge(agent.hex, agent.getVision(), game.terrain)
        }
        return moved;
    }

    giveControlAgent(agent: Agent, terrain: Terrain) {
        this._agents.push(agent);
        this.updateKnowledge(agent.hex, agent.getVision(), terrain);
    }

    giveControlPlace(place: Place, terrain: Terrain) {
        this._places.push(place);
        this.updateKnowledge(place.hex, place.vision, terrain);
    }

    removeControlAgent(agent: Agent) {
        this._agents.splice(this._agents.indexOf(agent), 1);
    }

    removeControlPlace(place: Place) {
        this._places.splice(this._places.indexOf(place), 1);
    }

    respondToAttack(defender: Agent, attacker: Agent) {
        const worthy = (agent: Agent) => agent.controller.faction !== "beasts" && ! agent.getOutcast();

        if (worthy(attacker) && worthy(defender)) {
            const faction = attacker.controller.faction;

            if (! this._enemies.includes(faction)) {
                this._enemies.push(faction);
            }

            const friendIndex = this._friends.indexOf(faction);

            if (friendIndex >= 0) {
                this._friends.splice(friendIndex, 1);
            }
        }
    }

    reward(fight: Fight) {
        const reward: IReward = {
            feast: [],
            reputation: []
        };

        if (this.player && fight.defeated && fight.victor?.controller === this) {
            reward.skill = this.learnSkill(fight.defeated);
            reward.item = this.lootItem(fight.victor, fight.defeated);
        }
        return reward;
    }

    serialiseOut(): IControllerSave {
        return {
            enemies: this._enemies,
            faction: this._faction,
            friends: this._friends,
            key: this._key,
            knowledge: this._knowledge,
            player: this.player,
            random: this._random.seed,
            skills: this._skills
        };
    }

    static initialise() {
        this._created = 0;
    }

    private addSkill(skill: string) {
        const add = Skill.catalog.create(skill, Skill);

        if (!this.skills.includes(skill) && add) {
            add.mutate(this.constraints);
            this.skills.push(skill);
        }
    }

    private learnSkill(agent: Agent): string | undefined {
        const learned = this._random.getFromDistribution(agent.learn);

        if (learned && !this.skills.includes(learned)) {
            this.addSkill(learned);
            return learned
        }
        return undefined;
    }

    private lootItem(victor: Agent, defeated: Agent): string | undefined {
        const looted = this._random.getFromDistribution(defeated.loot);

        if (looted && !victor.equipment.find(equipment => equipment.type === looted)) {
            victor.addEquipment(looted);
            return looted;
        }
        return undefined;
    }

    private updateKnowledge(hex: Hex, vision: number, terrain: Terrain) {
        const self = this;

        terrain.getSurroundingHexes(hex, vision)
            .filter(test => test !== hex)
            .forEach(test => self.updateHexKnowledge(test, "seen"));
        this.updateHexKnowledge(hex, "visited");
    }

    private updateHexKnowledge(hex: Hex, source: knowledgeSource) {
        const knowledge = this._knowledge[hex.key];

        if (knowledge === undefined ||
            KNOWLEDGE_SOURCES.indexOf(source) >= KNOWLEDGE_SOURCES.indexOf(knowledge.source)) {
            this._knowledge[hex.key] = {
                hex: hex.position,
                source,
                timestamp: Date.now()
            }
        }
    }
}