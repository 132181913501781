import {createContext, ReactElement, startTransition, useEffect, useState} from "react";
import {DebugController} from "./debugController";

const debug = new DebugController()
const DebugContext = createContext<DebugController>(debug);
interface IDebugProvider {
    children: ReactElement | ReactElement []
}
function DebugProvider({children}: IDebugProvider) {
    const [,updateState] = useState<any>();

    useEffect(() => {
        const handleDebugUpdate = () => {
            startTransition(() => updateState({}));
        };

        debug.addUpdateDelegate(handleDebugUpdate);
        return () => debug.removeUpdateDelegate(handleDebugUpdate);
    }, []);

    return (
        <DebugContext.Provider value={debug}>
            <>
                {children}
            </>
        </DebugContext.Provider>
    );
}

export {DebugContext, DebugProvider};