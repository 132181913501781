import {ICountTest, IRequirementCount, RequirementCount} from "./requirementCount";

export interface IRequirementTerrain extends IRequirementCount {
    type: "terrain"
    terrain: string
    range: number
}

export class RequirementTerrain extends RequirementCount {

    private readonly terrain: string;
    private readonly range: number;

    constructor(data: IRequirementTerrain) {
        super(data);
        this.count = data.count || 1;
        this.terrain = data.terrain;
        this.range = data.range;
    }

    getCount(props: ICountTest) {
        if (this.range === 0) {
            return props.hex.type === this.terrain ? 1 : 0
        } else {
            return props.game.terrain.getSurroundingHexes(props.hex, this.range).reduce((total, hex) =>
                hex.type === this.terrain ? total + 1 : total, 0);
        }
    }
}
