import {IRequirement, Requirement} from "./requirement";

export interface IRequirementCompare extends IRequirement {
    lhs: number | string[];
    rhs: number | string[];
}
abstract class RequirementCompare extends Requirement {
    lhs: number | string[];
    rhs: number | string[];

    constructor(data: IRequirementCompare) {
        super(data);
        this.lhs = data.lhs;
        this.rhs = data.rhs;
    }

    test(properties: any): boolean {
        return this.compare(
            this.lhs instanceof Array ? this.getProperty(properties, this.lhs) : this.lhs,
            this.rhs instanceof Array ? this.getProperty(properties, this.rhs) : this.rhs
        );
    }

    abstract compare(lhs: number, rhs: number): boolean;
}

export class RequirementEqual extends RequirementCompare {
    compare(lhs: number, rhs: number) {
        return lhs === rhs;
    }
}

export class RequirementGreaterThan extends RequirementCompare {
    compare(lhs: number, rhs: number) {
        return lhs > rhs;
    }
}

export class RequirementLessThan extends RequirementCompare {
    compare(lhs: number, rhs: number) {
        return lhs < rhs;
    }
}
