import {Box, Divider, Typography} from "@mui/material";
import {Localisation} from "../../localisation/localisation";
import {Place, REPUTATION_LEVELS} from "../../../game/places/place";
import {LabelledParameter} from "./LabelledParameter";
import {PlayerController} from "../../../game/controllers/playerController";
import React, {useContext} from "react";
import {DebugContext} from "../../debug/DebugProvider";
import {TutorialTooltip} from "../../help/TutorialTooltip";

interface IPlaceDetails {
    place: Place
    player: PlayerController
}
export function PlaceDetails({place, player}: IPlaceDetails) {
    const debug = useContext(DebugContext);
    const styles = {
        assets: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "20vh",
            overflowY: "scroll"
        },
        label: {
            flexGrow: 1,
            textTransform: "capitalize"
        },
        title: {
            flexGrow: 1,
            textTransform: "capitalize"
        },
        value: {
            textTransform: "capitalize"
        }
    };
    const bureaucrat = Boolean(player.getSkillKnown("bureaucrat") || debug.getVisible());

    return (
        <Box>
            <TutorialTooltip next="PlayerRenown" title="PlaceTitle">
                <Typography variant="h6" sx={styles.title}>{Localisation.GetText(place.type)}</Typography>
            </TutorialTooltip>
            <TutorialTooltip next="BureaucratDetails" title="PlayerRenown">
                <Box>
                    <LabelledParameter label="Renown" value={Localisation.GetText(REPUTATION_LEVELS[place.reputation])}/>
                </Box>
            </TutorialTooltip>
            <TutorialTooltip enabled={bureaucrat} next="PlaceAssets" title="BureaucratDetails">
                <Box>
                    {(player.getSkillKnown("bureaucrat") || debug.getVisible()) &&
                        Object.entries(place.resources).map(([resource, value]) =>
                            <LabelledParameter key={resource} label={resource}
                                               value={`${value.value}/${value.capacity} (+${value.production})`}/>
                        )
                    }
                </Box>
            </TutorialTooltip>
            <TutorialTooltip next="AgentTitle" placement="top" title="PlaceAssets">
                <Typography variant="h6">{Localisation.GetText("placeAssets")}</Typography>
            </TutorialTooltip>
            <Box sx={styles.assets}>
                {place.assets
                    .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
                    .map((asset, index) =>
                    <Typography key={index}>{Localisation.GetText(asset.name)}</Typography>
                )}
            </Box>
            {debug.getVisible() &&
                <Box>
                    <Divider sx={{margin: 1}}/>
                    <LabelledParameter label="Spawned" value={place.spawned.toString()}/>
                </Box>
            }
        </Box>
    );
}