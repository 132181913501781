import {Game} from "../../game/game";
import {Box, Button, Divider, IconButton} from "@mui/material";
import React, {useEffect, useReducer} from "react";
import {CompetitorView} from "./CompetitorView";
import {connect} from "react-redux";
import {update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";
import {selectHelp} from "../../redux/selectors/dialog";
import {TutorialTooltip} from "../help/TutorialTooltip";
import {Help} from "@mui/icons-material";

interface IFightView {
    game: Game
    help: string
    onClose: () => void
    updateDialog: Update
}
function FightView({game, help, onClose, updateDialog}: IFightView) {
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const handleFinishClick = () => {
        game.completeFight();
        onClose();
    };
    const handleHelpClick = () => {
        updateDialog({
            update: "FightFinish",
            store: ["help"]
        })
    }

    useEffect(() => {
        game.updateDelegates.add(forceUpdate);
        game.fight?.addUpdateDelegate(forceUpdate);
        return () => {
            game.updateDelegates.remove(forceUpdate);
            game.fight?.removeUpdateDelegate(forceUpdate);
        }
    }, [forceUpdate, game]);
    if (! game.fight) return null;

    const styles = {
        competitors: {
            display: "flex",
            flexGrow: 1
        },
        content: {
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            left: 0,
            position: "absolute",
            top: 0,
            width: {xs: "100%", md: 500}
        },
        dialog: {
            display: "flex",
            flexGrow: 1,
            height: 500,
            width: {xs: "100%", md: 500}
        },
        watermark: {
            backgroundImage: `url(./images/terrain/${game.fight.attacker.agent.hex.type}.jpg)`,
            backgroundSize: "cover",
            height: "100%",
            opacity: "30%",
            width: "100%"
        }
    };
    const playerAttacker = game.fight.attacker.agent.controller.player;
    const player = playerAttacker ? game.fight.attacker : game.fight.defender;
    const opponent = playerAttacker ? game.fight.defender : game.fight.attacker;

    return (
        <Box sx={styles.dialog}>
            <Box sx={styles.watermark}/>
            <Box sx={styles.content}>
                <Box sx={styles.competitors}>
                    <CompetitorView attacker={playerAttacker} competitor={player} fight={game.fight} player={player}/>
                    <Divider flexItem orientation="vertical"/>
                    <CompetitorView attacker={! playerAttacker} competitor={opponent} fight={game.fight} player={player}/>
                </Box>
                <TutorialTooltip title="FightFinish" next="PlayerDeck">
                    <Button disabled={game.fight.state === "initial" || game.fight.state === "ongoing"}
                            onClick={handleFinishClick}>Finish</Button>
                </TutorialTooltip>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton color="primary" onClick={handleHelpClick} size="small"><Help/></IconButton>
                </Box>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    help: selectHelp(store)
});
const connected = connect(mapStateToProps, {
    updateDialog,
})(FightView);

export {connected as FightView}
