import {createElement, MouseEventHandler, TouchEventHandler, useEffect, useReducer, useState} from "react";
import {Agent} from "../../game/agents/agent";
import {Box, useTheme} from "@mui/material";
import {Game} from "../../game/game";
import {Vector2} from "../../utility/vector2";
import * as gameIcons from "react-icons/gi";

const DEFAULT_AGENT_ICON = "GiHoodedFigure";
const DEFAULT_SAILING_ICON = "GiSinagot";

interface IAgent {
    agent: Agent
    game: Game
    selected: boolean
    onInteractionStart: (position: Vector2) => void
    onInteractionFinish: (position: Vector2) => void
}

export function AgentView({agent, game, selected, onInteractionStart, onInteractionFinish}: IAgent) {
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const [touchPosition, setTouchPosition] = useState(Vector2.zero);
    const handleMouseDown: MouseEventHandler = (event) => {
        if (event.button === 0) {
            onInteractionStart(new Vector2(event.pageX, event.pageY));
            event.stopPropagation();
        }
    }
    const handleMouseUp: MouseEventHandler = ({pageX, pageY}) => {
        onInteractionFinish(new Vector2(pageX, pageY));
    }
    const handleTouchEnd: TouchEventHandler = () => {
        onInteractionFinish(touchPosition);
    }
    const handleTouchMove: TouchEventHandler = ({touches}) => {
        setTouchPosition(new Vector2(touches[0].pageX, touches[0].pageY));
    }
    const handleTouchStart: TouchEventHandler = (event) => {
        const touch = event.touches[0];

        setTouchPosition(new Vector2(touch.pageX, touch.pageY));
        onInteractionStart(new Vector2(touch.pageX, touch.pageY));
        event.stopPropagation();
    }
    const theme = useTheme();
    const color = agent.controller.player ? theme.map.color.player : theme.map.color[game.getPlayerAttitude(agent)];
    const styles = {
        agentContainer: {
            display: "flex",
        },
        gameIconContainer: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
        },
        selected: {
            borderRadius: 4,
            borderStyle: "solid",
            borderWidth: 2,
            margin: "2px",
            cursor: "grab"
        },
        unselected: {
            margin: "4px"
        }
    };
    const icon = agent.sailing ? gameIcons[DEFAULT_SAILING_ICON] : gameIcons[agent.visual] ??
        gameIcons[DEFAULT_AGENT_ICON];
    const iconProps = {
        ...theme.map.icon,
        color
    };

    useEffect(() => {
        agent?.addUpdateDelegate(forceUpdate);
        return () => agent?.removeUpdateDelegate(forceUpdate);
    }, [agent, forceUpdate]);
    return (
        <Box sx={styles.agentContainer}>
            <Box onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
                 onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}
                 sx={{
                     ...selected ? styles.selected : styles.unselected,
                     ...styles.gameIconContainer,
                     color
            }}>
                {createElement(icon, iconProps)}
            </Box>
        </Box>
    );
}
