import {Random} from "./random";
import {Vector2} from "./vector2";

export class Perlin {
    private readonly gradients: Vector2[][];

    constructor(private readonly _size: number, private _random: Random) {
        this.gradients = [];
        for (let x = 0; x <= _size; ++x) {
            this.gradients[x] = [];
            for (let y = 0; y <= _size; ++y) {
                const theta = _random.get() * 2 * Math.PI;

                this.gradients[x][y] = new Vector2({x: Math.cos(theta), y: Math.sin(theta)});
            }
        }
    }
    
    get(normalisedPoint: Vector2) {
        const smooth = (x: number) => 6 * Math.pow(x, 5) - 15 * Math.pow(x, 4) + 10 * Math.pow(x, 3);
        const interpolate = (a:number, b:number, w:number) => (b - a) * smooth(w) + a;
        const point = normalisedPoint.multiply(this._size);
        const left = Math.floor(point.x);
        const right = left + 1;
        const top = Math.floor(point.y);
        const bottom = top + 1;
        const deltaTopLeft = point.minus(new Vector2({x: left, y: top}));
        const deltaTopRight = point.minus(new Vector2({x: right, y: top}));
        const deltaBottomLeft = point.minus(new Vector2({x: left, y: bottom}));
        const deltaBottomRight = point.minus(new Vector2({x: right, y: bottom}));
        const topLeft = this.gradients[left][top];
        const topRight = this.gradients[right][top];
        const bottomLeft = this.gradients[left][bottom];
        const bottomRight = this.gradients[right][bottom];
        const weightX = point.x - left;
        const weightY = point.y - top;
        const interpolatedTop = interpolate(deltaTopLeft.dot(topLeft), deltaTopRight.dot(topRight), weightX);
        const interpolatedBottom = interpolate(deltaBottomLeft.dot(bottomLeft), deltaBottomRight.dot(bottomRight), weightX);
        const interpolated = interpolate(interpolatedTop, interpolatedBottom, weightY);
        
        return interpolated * 0.5 + 0.5;
    }
}
