import {IconButton, useTheme} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {useState} from "react";
import {GameDialog} from "./dialogs/GameDialog";
import {Game} from "../game/game";

interface IGameMenu {
    game: Game
}
export function GameMenu({game}: IGameMenu) {
    const [showDialog, setShowDialog] = useState(false);
    const theme = useTheme();
    const styles = {
        button: {
            backgroundColor: theme.palette.common.white,
            margin: 1,
            zIndex: 1000
        }
    }
    const handleClose = () => setShowDialog(false)

    return (
        <>
            <IconButton color="primary" onClick={() => setShowDialog(true)} sx={styles.button}>
                <Menu/>
            </IconButton>
            <GameDialog game={game} open={showDialog} onClose={handleClose}/>
        </>
    );
}