export class Vector2 {
    x: number = 0;
    y: number = 0;

    static one = new Vector2(1, 1);
    static zero = new Vector2(0, 0);

    constructor(arg1: any, arg2?: number) {
        if (arg1.x !== undefined) {
            this.x = arg1.x;
            this.y = arg1.y;
        } else if ((arg1 as number[]).length) {
            this.serialize(arg1);
        } else {
            this.x = arg1 as number;
            this.y = arg2 as number;
        }
    }
    axial() {
        return new Vector2(this.x - Math.ceil(this.y * 0.5), this.y);
    }
    divide(divisor: number) {
        return new Vector2({x: this.x / divisor, y: this.y / divisor});
    }
    dot(compare: Vector2) {
        return this.x * compare.x + this.y * compare.y; 
    }
    equals(compare: Vector2) {
        return this.x === compare.x && this.y === compare.y;
    }
    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    lengthSquared() {
        return this.x * this.x + this.y * this.y;
    }
    max(compare: Vector2) {
        return new Vector2(Math.max(this.x, compare.x), Math.max(this.y, compare.y));
    }
    maxX(compare: number) {
        return new Vector2(Math.max(this.x, compare), this.y);
    }
    maxY(compare: number) {
        return new Vector2(this.x, Math.max(this.y, compare));
    }
    min(compare: Vector2) {
        return new Vector2(Math.min(this.x, compare.x), Math.min(this.y, compare.y));
    }
    minX(compare: number) {
        return new Vector2(Math.min(this.x, compare), this.y);
    }
    minY(compare: number) {
        return new Vector2(this.x, Math.min(this.y, compare));
    }
    minus(delta: Vector2) {
        return new Vector2({x: this.x - delta.x, y: this.y - delta.y});
    }
    multiply(multiple: number) {
        return new Vector2({x: this.x * multiple, y: this.y * multiple});
    }
    plus(plus: Vector2) {
        return new Vector2({x: this.x + plus.x, y: this.y + plus.y});
    }
    toString() {
        return `{x: ${this.x}, y: ${this.y}}`;
    }
    
    // Note that serialise works in both directions - if you pass in coordinates then it writes them
    // if not then it just returns them
    serialize(coordinates?: number[]) {
        if (coordinates) {
            this.x = coordinates[0];
            this.y = coordinates[1];
        }
        return [this.x, this.y];
    }
}