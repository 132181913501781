import terrains from "../../data/catalogs/map/terrains.json";
import {Resource} from "./resourcesCatalog.";

export const DevelopmentTypes = ["infrastructure", "growth", "trade"] as const;

export type Development = typeof DevelopmentTypes[number];
export type TerrainType = keyof typeof terrains;

interface ITerrain {
    altitudes: string []
    development: Record<Development, number>
    latitudes: string []
    resources: Record<Resource, number>
    vision: number
}

export class TerrainsCatalog {
    private readonly _terrains: Record<string, ITerrain>

    constructor() {
        this._terrains = Object.fromEntries(Object.entries(terrains).map(([name, data]) =>
            ([name, data as ITerrain]))
        );
    }

    getLegalTerrains() {
        const legalTerrains: Record<string, Record<string, string[]>> = {}
        
        Object.entries(this._terrains).forEach(([key, terrain]) => {
            terrain.latitudes.forEach(latitude =>
                terrain.altitudes.forEach(altitude => {
                    if (! legalTerrains[latitude]) {
                        legalTerrains[latitude] = {};
                    }
                    if (! legalTerrains[latitude][altitude]) {
                        legalTerrains[latitude][altitude] = [];
                    }
                    legalTerrains[latitude][altitude].push(key)
                })
            )
        });
        return legalTerrains;
    }

    getTerrain(key: string) {
        return this._terrains[key];
    }
}
