import React, {TouchEventHandler, useEffect, useState} from 'react';
import {Box, ThemeProvider, useMediaQuery, Backdrop} from "@mui/material";
import {Vector2} from "../utility/vector2";
import {Game} from "../game/game";
import {DebugProvider} from "./debug/DebugProvider";
import {FightDialog} from "./dialogs/FightDialog";
import {Localisation} from "./localisation/localisation";
import {DetailsDialog} from "./dialogs/DetailsDialog";
import {Background} from "./Background";
import {EquipmentDialog} from "./dialogs/EquipmentDialog";
import {MapSize} from "./map/mapSize";
import {FeastDialog} from "./dialogs/FeastDialog";
import {Welcome} from "./Welcome";
import {AnalyticsProvider} from "./analytics/AnalyticsProvider";
import {GameMenu} from "./GameMenu";
import {MapContainer} from "./map/MapContainer";
import {connect} from "react-redux";
import {update as updateMap} from "../redux/slices/map";
import {update as updateDialog} from "../redux/slices/dialog";
import {Update} from "../redux/slices/base";
import {selectHelp} from "../redux/selectors/dialog";
import {ErrorDialog} from "./dialogs/ErrorDialog";
import {getTheme} from "./theme";
import {AchievementAlert} from "./alerts/AchievementAlert";
import {Achievement} from "../game/achievements/achievement";

interface INationOfOne {
    help: string
    updateDialog: Update
    updateMap: Update
}
function NationOfOne({help, updateDialog, updateMap}: INationOfOne) {
    const [achievements, setAchievements] = useState<Achievement[]>([]);
    const [error, setError] = useState<Error>()
    const [focus, setFocus] = useState<Vector2>();
    const [game, setGame] = useState<Game>();
    const [mapSize, setMapSize] = useState<MapSize>();
    const small = ! useMediaQuery("@media only screen and (min-width: 600px) and (min-height: 600px)");
    const theme = getTheme(small);
    const styles = {
        backdrop: {
            zIndex: theme.zIndex.modal + 1
        },
        content: {
            overflow: "hidden",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    };
    const handleCloseTutorial = () => updateDialog({update: undefined, store: ["help"]});
    const handleTouchMove: TouchEventHandler = (event) => {
//        event.preventDefault();
    }

    useEffect(() => {
        setMapSize(new MapSize(20, 70, small ? 64 : 42));
    }, [small]);
    useEffect(() => {
        Localisation.Initialise("enGB").catch(() => console.error);
    }, []);
    useEffect(() => {
        if (mapSize) {
            try {
                const game = new Game({mapSize});
                setFocus(game.playerController?.getPlayer()?.hex.position);
                setGame(game);
            }
            catch (error) {
                setError(error as Error);
            }
        }
    }, [mapSize]);
    useEffect(() => {
        const handleGameUpdate = (event: any) => {
            if (game && event.message === "reinitialise") {
                setFocus(game.playerController?.getPlayer()?.hex.position);
            }
            if (event.message === "settledVillage") {
                updateMap({update: undefined, store: ["selected", "agent"]});
            }
            if (event.message === "fighting") {
                updateDialog({update: { name: "fight" }})
            }
            if (event.message === "achievementsComplete") {
                setAchievements(event.complete);
            }
        }

        if (game) {
            game.updateDelegates.add(handleGameUpdate);
            return () => game.updateDelegates.remove(handleGameUpdate);
        }
    }, [updateDialog, updateMap, game]);
    return (
        <ThemeProvider theme={theme}>
            <DebugProvider>
                <AnalyticsProvider>
                    <Background/>
                    <ErrorDialog error={error}/>
                    <Welcome/>
                    {game && mapSize ?
                        <Box sx={styles.content} onTouchMove={handleTouchMove}>
                            <GameMenu game={game}/>
                            <DetailsDialog game={game}/>
                            <EquipmentDialog game={game}/>
                            <FightDialog game={game} mapSize={mapSize}/>
                            <FeastDialog game={game}/>
                            <MapContainer focus={focus} game={game} mapSize={mapSize}/>
                            <AchievementAlert achievements={achievements}/>
                        </Box> : <></>
                    }
                    <Backdrop onClick={handleCloseTutorial} open={help !== undefined} style={styles.backdrop}/>
                </AnalyticsProvider>
            </DebugProvider>
        </ThemeProvider>
    );
}
const mapStateToProps = (store: never) => ({
    help: selectHelp(store)
});
const connected = connect(mapStateToProps, {
    updateDialog,
    updateMap
})(NationOfOne);
export {connected as NationOfOne}
