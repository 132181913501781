import {IRequirement, ITest, Requirement} from "./requirement";
import {Hex} from "../map/hex";
import {Game} from "../game";

export interface IRequirementCount extends IRequirement {
    count?: number
}

export interface ICountTest extends ITest {
    hex: Hex
    game: Game
}

export abstract class RequirementCount extends Requirement {

    protected count: number;

    protected constructor(data: IRequirementCount) {
        super(data)
        this.count = data.count || 1;
    }

    protected abstract getCount(props: ICountTest) : number;

    test(props: ICountTest): boolean {
        return this.getCount(props) >= this.count;
    }
}