import {Achievement, IAchievement, Progress} from "./achievement";
import {GameEvent} from "./gameEvent";

interface IAchievementAccumulate extends IAchievement {
    accumulate: {
        key: string
        value: string[]
    }
}

export class AchievementAccumulate extends Achievement {
    private readonly _key: string;
    private readonly _value: string[] | string;

    constructor(props: IAchievement, progress: Progress) {
        super(props, progress);
        this._key = (props as IAchievementAccumulate).accumulate.key;
        this._value = (props as IAchievementAccumulate).accumulate.value;
    }

    protected update(event: GameEvent) {
        if (this._progress !== true) {
            const increment = this.getProperty(event, this._value);

            this._progress[this._key] = (this._progress[this._key] || 0) + increment;
        }
    }
}