import {ICountTest, IRequirementCount, RequirementCount} from "./requirementCount";

export interface IRequirementAsset extends IRequirementCount {
    type: "asset"
    asset: string
    range: number
}

export class RequirementAsset extends RequirementCount {

    private readonly asset: string;
    private readonly range: number;

    constructor(data: IRequirementAsset) {
        super(data);
        this.asset = data.asset;
        this.range = data.range;
    }

    getCount(props: ICountTest) {
        if (! this.range) {
            return props.game.getPlace(props.hex)?.assets.find(asset => asset.type === this.asset) ? 1 : 0;
        } else {
            return props.game.places.reduce((total, place) =>
                props.game.terrain.getHexDistance(place.hex, props.hex) <= this.range &&
                place.assets.find(asset => asset.type === this.asset) ? total + 1 : total, 0);
        }
    }
}