import {Vector2} from "./vector2";

export class Bounds2 {
    static zeroEmpty = new Bounds2(Vector2.zero, Vector2.zero);

    constructor(public min: Vector2, public max: Vector2) {}

    constrain(point: Vector2) {
        return new Vector2(
            Math.min(Math.max(point.x, this.min.x), this.max.x),
            Math.min(Math.max(point.y, this.min.y), this.max.y));
    }
    contains(test: Vector2) {
        return test.x >= this.min.x && test.x <= this.max.x && test.y >= this.min.y && test.y <= this.max.y;
    }
    equals(compare: Bounds2) {
        return this.min.equals(compare.min) && this.max.equals(compare.max);
    }
    size() {
        return new Vector2(this.max.x - this.min.x, this.max.y - this.min.y);
    }
    toString() {
        return `{min: ${this.min.toString()}, max: ${this.max.toString()}}`;
    }
}