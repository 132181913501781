import {Vector2} from "../../utility/vector2";
import {TerrainType} from "./terrainsCatalog";

// Highest number of columns (to generate key)
const MAX_X = 1000;
interface IHex {
    position: Vector2
    type: TerrainType
    resource?: string
    vision?: number
}
export class Hex {
    private static _created = 0;

    constructor(private details: IHex) {
    }

    static initialise() {
        this._created = 0;
    }

    get key() {
        return this.details.position.x + this.details.position.y * MAX_X;
    }
    get position() {
        return this.details.position;
    }
    get resource() {
        return this.details.resource;
    }
    get type() {
        return this.details.type;
    }
    get vision() {
        return this.details.vision ?? 0;
    }
}