import {Achievement} from "../../game/achievements/achievement";
import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Fade} from "@mui/material";
import {Localisation} from "../localisation/localisation";

interface IAchievementAlert
{
    achievements: Achievement[]
}
export function AchievementAlert({achievements}: IAchievementAlert) {
    const [pending, setPending] = useState<Achievement []>([]);
    const [achievement, setAchievement] = useState<Achievement>();
    const [visible, setVisible] = useState(false);
    const styles = {
        alert: {
            bottom: 1,
            left: 1,
            margin: 2,
            position: "absolute",
            right: 1
        }
    }

    useEffect(() => {
        setPending(achievements);
    }, [achievements]);
    useEffect(() => {
        if (pending.length) {
            setTimeout(() => {
                setVisible(true);
                setAchievement(pending.pop());
                setPending([...pending]);
            }, 1000);
        } else {
            setTimeout(() => {
                setVisible(false);
            }, 3000);
        }
    }, [pending]);
    return (
        <Fade in={visible}>
            <Alert severity="success" sx={styles.alert}>
                <AlertTitle>
                    {Localisation.GetText("achievementTitle", {
                        achievement: Localisation.GetText(achievement?.name || "")
                    })}
                </AlertTitle>
                {Localisation.GetText(achievement?.description || "")}
            </Alert>
        </Fade>
    );
}