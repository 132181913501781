import {selectHelp} from "../../redux/selectors/dialog";
import {connect} from "react-redux";
import {update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";
import React, {ReactElement, useEffect} from "react";
import {Box, Button, IconButton, Tooltip, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {Localisation} from "../localisation/localisation";
import {TooltipProps} from "@mui/material/Tooltip/Tooltip";

interface ITutorialTooltip extends TooltipProps {
    children: ReactElement
    enabled?: boolean
    next?: string
    selected: string
    title: string
    updateDialog: Update
}
function TutorialTooltip({children, enabled = true, next, placement, selected, title, updateDialog}: ITutorialTooltip) {
    const styles = {
        toolbar: {
            justifyContent: "flex-end"
        },
        tooltip: {
        }
    };
    const handleClose = () => updateDialog({
        update: undefined,
        store: ["help"]
    });
    const handleNext = () => updateDialog({
        update: next,
        store: ["help"]
    });
    const text = Localisation.GetText(`tutorial${title}`);

    useEffect(() => {
        if (title === selected && enabled === false) {
            updateDialog({
                update: next,
                store: ["help"]
            });
        }
    }, [enabled, title, next, selected, updateDialog]);

    if (! enabled) {
        return (
            <>{children}</>
        );
    }
    return (
        <Tooltip arrow open={title === selected} placement={placement} title={
            <Box>
                <Box display="flex" sx={styles.toolbar}>
                    <IconButton onClick={handleClose} size="small"><Close/></IconButton>
                </Box>
                <Typography>{text}</Typography>
                <Box display="flex" flexGrow={1} justifyContent="flex-end">
                    {next === undefined ?
                        <Button onClick={handleClose}>{Localisation.GetText("buttonClose")}</Button> :
                        <Button onClick={handleNext}>{Localisation.GetText("buttonNext")}</Button>}
                </Box>
            </Box>
        }>
            {children}
        </Tooltip>
    );
}
const mapStateToProps = (store: never) => ({
    selected: selectHelp(store)
});
const connected = connect(mapStateToProps, {
    updateDialog,
})(TutorialTooltip);

export {connected as TutorialTooltip}
