import {Vector2} from "../../utility/vector2";

const HEX_WIDTH_EDGE_RATIO = 1 / Math.sqrt(3);

export class MapSize {
    readonly HexEdge: number;
    readonly HexMargin = 1;
    readonly HexWidth: number;
    readonly HexHeight: number;
    readonly HalfHexEdge: number;
    readonly HalfHexHeight: number;
    readonly HalfHexWidth: number;
    readonly MapDimensions: Vector2;
    readonly MapSize: Vector2;

    constructor(private _height: number, _width: number, private _hexEdge = 64) {
        this.HexEdge = _hexEdge;
        this.HalfHexEdge = _hexEdge * 0.5;
        this.HexWidth = _hexEdge / HEX_WIDTH_EDGE_RATIO;
        this.HalfHexWidth = this.HexWidth * 0.5;
        this.HexHeight = _hexEdge + 2 * Math.sqrt(_hexEdge * _hexEdge - this.HalfHexWidth * this.HalfHexWidth);
        this.HalfHexHeight = this.HexHeight * 0.5;
        this.MapSize = new Vector2(_width, _height);
        this.MapDimensions = new Vector2(this.hexToPosition(this.MapSize))
            .plus(new Vector2(0, this.HalfHexHeight));
    }

    getDirection(from: Vector2, to: Vector2) {
        const eastWestDegrees = (this.MapSize.x + to.x - from.x) % this.MapSize.x;
        const eastWestDelta = eastWestDegrees > this.MapSize.x * 0.5 ? - (this.MapSize.x - eastWestDegrees) : eastWestDegrees;
        const northSouthDelta = to.y - from.y;

        if (Math.abs(eastWestDelta) > Math.abs(northSouthDelta * 2)) {
            return eastWestDelta < 0 ? "compassWest" : "compassEast";
        } else if (Math.abs(northSouthDelta) > Math.abs(eastWestDelta * 2)) {
            return northSouthDelta < 0 ? "compassNorth" : "compassSouth";
        } else {
            if (northSouthDelta > 0) {
                return eastWestDelta > 0 ? "compassSoutheast" : "compassSouthwest"
            } else {
                return eastWestDelta > 0 ? "compassNortheast" : "compassNorthwest"
            }
        }
    }

    positionToHex(position: Vector2) {
        const y = Math.floor(position.y / (this.HexEdge * 1.5));
        let x = Math.floor((position.x + (y % 2 ? (this.HalfHexWidth + this.HexMargin) : 0)) /
            (this.HexWidth + this.HexMargin));

        while (x < 0) {
            x += this.MapSize.x;
        }
        if (x >= this.MapSize.x) {
            x %= this.MapSize.x;
        }
        return new Vector2(x, y);
    }

    hexToPosition(hex: Vector2) {
        return new Vector2(
            hex.x * (this.HexWidth + this.HexMargin) - this.HalfHexWidth - this.HexMargin,
            hex.y * this.HexEdge * 1.5 - this.HalfHexWidth
        );
    }
}
