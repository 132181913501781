import {Catalog} from "../catalog/catalog";
import {ICatalogEntry} from "../catalog/catalogEntry";
import armour from "../../data/catalogs/loot/armour.json";
import items from "../../data/catalogs/loot/items.json";
import shields from "../../data/catalogs/loot/shields.json";
import weapons from "../../data/catalogs/loot/weapons.json";
import {Card} from "../fight/card";

interface IItem {
    cards?: {[item: string]: number}
    create?: string[]
    description: string
    name: string
    repair?: string[]
    resilience: number
    slot: string
    skills?: string[]
}
export class Item {
    static catalog = new Catalog<IItem>([armour, items, shields, weapons]);

    private _damage: number = 0;

    constructor(private _props: IItem & ICatalogEntry) {
    }

    get cards() {
        return this._props.cards || {};
    }
    get create() {
        return this._props.create || {};
    }
    get damage() {
        return this._damage;
    }
    get description() {
        return this._props.description;
    }
    get name() {
        return this._props.name;
    }
    get repair() {
        return this._props.repair || [];
    }
    get resilience() {
        return this._props.resilience;
    }
    get slot() {
        return this._props.slot;
    }
    get skills() {
        return this._props.skills || [];
    }
    get type() {
        return this._props.type;
    }

    damageItem(damage: number) {
        this._damage += damage;
    }
    getCards() {
        return Card.catalog.createFromDistribution(this._props.cards ?? {}, Card);
    }
    repairItem() {
        this._damage = 0;
    }
}