import {createTheme} from "@mui/material";
import {blue} from "@mui/material/colors";

declare module '@mui/material/styles' {
    interface Theme {
        fight: {
            color: string,
            fontSize: string
            icon: {
                color: string
                size: string
            }
            size: number
        },
        map: {
            button: {
                size: number
            },
            color: {
                enemy: string
                friend: string
                neutral: string
                player: string
                known: string
            }
            icon: {
                size: string
            }
        }
    }
    interface ThemeOptions {
        fight: {
            color: string,
            fontSize: string
            icon: {
                color: string
                size: string
            }
            size: number
        },
        map: {
            button: {
                size: number
            }
            color: {
                enemy: string
                friend: string
                neutral: string
                player: string
                known: string
            }
            icon: {
                size: string
            }
        }
    }
}

export const getTheme = (small: boolean) => {
    const fontSize = small ? 1.4 : 2;
    const textColor = "black";

    return createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    arrow: {
                        color: blue[100]
                    },
                    tooltip: {
                        color: textColor,
                        backgroundColor: blue[100]
                    }
                }
            }
        },
        fight: {
            color: textColor,
            fontSize: `${fontSize}em`,
            icon: {
                color: textColor,
                size: `${fontSize}em`
            },
            size: fontSize
        },
        map: {
            button: {
                size: small ? 50 : 30
            },
            color: {
                enemy: "orange",
                friend: "paleGreen",
                known: "paleGreen",
                neutral: "yellow",
                player: "white"
            },
            icon: {
                size: small ? "30px" : "20px"
            }
        }
    });
}
