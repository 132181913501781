import {Card} from "../../game/fight/card";
import {Box, useTheme} from "@mui/material";
import {
    GiArrowFlights,
    GiBeastEye,
    GiCardPlay,
    GiCardRandom,
    GiDodging,
    GiDragonShield,
    GiEnergyShield,
    GiFencer,
    GiFireBreath,
    GiFoamyDisc,
    GiGladius,
    GiHammerBreak,
    GiHeartShield,
    GiInvisible,
    GiShield,
    GiSwordBrandish
} from "react-icons/gi";
import {createElement} from "react";
import {IconBaseProps, IconType} from "react-icons";
import {Localisation} from "../localisation/localisation";

export const GetCardVisual = (card: string, props: IconBaseProps) => {
    const visuals: { [key: string]: IconType } = {
        attack: GiGladius,
        block: GiShield,
        crush: GiHammerBreak,
        default: GiCardRandom,
        deflect: GiDragonShield,
        dodge: GiDodging,
        fireBreathing: GiFireBreath,
        flameGuard: GiEnergyShield,
        invisibility: GiInvisible,
        petrification: GiBeastEye,
        prescience: GiBeastEye,
        protection: GiHeartShield,
        poison: GiFoamyDisc,
        ranged: GiArrowFlights,
        recklessAttack: GiSwordBrandish,
        thrust: GiFencer
    };

    return createElement(visuals[card] || visuals.default, props);
};

interface ICardView {
    card?: Card
}
export function CardView({card}: ICardView) {
    const theme = useTheme();
    const styles = {
        card: {
            display: "flex",
            borderColor: theme.fight.icon.color,
            borderRadius: 1,
            borderStyle: "solid",
            borderWidth: 2,
            height: `${theme.fight.size}em`,
            width: `${theme.fight.size * 0.7}em`,
        },
        content: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between"
        },
    };

    return (
        <Box sx={styles.content}>
            {card === undefined ?
                <Box sx={styles.card}>
                    <GiCardPlay {...theme.fight.icon}/>
                </Box> :
                <Box sx={styles.card} title={Localisation.GetText(card.name)}>
                    {GetCardVisual(card.type, theme.fight.icon)}
                </Box>
            }
        </Box>
    );
}