import {Fight} from "../../game/fight/fight";
import {Card} from "../../game/fight/card";
import {Competitor} from "../../game/fight/competitor";
import {Box, Button, Slide, useTheme} from "@mui/material";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {TutorialTooltip} from "../help/TutorialTooltip";
import {CardView} from "./CardView";
import {GiCardDiscard, GiCardPlay} from "react-icons/gi";

interface IOldHandView {
    competitor: Competitor
    fight: Fight
    player: Competitor
}
export function OldHandView({competitor, fight, player}: IOldHandView) {
    const [showCards, setShowCards] = useState(true);
    const handRef = useRef(null);
    const theme = useTheme();
    const styles = {
        button: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 0
        },
        card: {
            color: theme.fight.color,
            display: "flex",
            marginTop: 1,
        },
        hand: {
            flexGrow: 1,
            marginBottom: 1
        },
    };
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const tutorialPrefix = competitor === player ? "Player" : "Monster";
    const handlePassCard = (card: Card) => () => {
        fight.passCard(competitor, card);
        forceUpdate();
    }
    const handlePlayCard = (card: Card) => () => fight.playCard(competitor, card);

    useEffect(() => {
        setShowCards(false);
        setTimeout(() => setShowCards(true), 50);
    }, [competitor.hand]);
    return (
        <Box ref={handRef} sx={styles.hand}>
            {player.hand.map((card, index) =>
                <Box key={index} sx={styles.card}>
                    <Box flexGrow={1}>
                        <TutorialTooltip enabled={player === competitor && index === 0}
                                         next={player === competitor ? `${tutorialPrefix}DiscardCard${index}`:
                                             undefined} placement="top-start"
                                         title={`${tutorialPrefix}Card${index}`}>
                            <Slide container={handRef.current} direction="down" exit={false} in={showCards}>
                                <Box>
                                    {player === competitor || player.peekEnemy() > index ?
                                        <CardView card={card}/> : <CardView/>}
                                </Box>
                            </Slide>
                        </TutorialTooltip>
                    </Box>
                    {player === competitor &&
                        <Box>
                            <TutorialTooltip enabled={index === 0} next={`${tutorialPrefix}PlayCard${index}`}
                                             placement="top-start" title={`${tutorialPrefix}DiscardCard${index}`}>
                                <Button disabled={competitor.getPass() === 0} onClick={handlePassCard(card)}
                                        startIcon={<GiCardDiscard color={theme.palette.common.white}/>}
                                        sx={{...styles.button, marginRight: 1, paddingLeft: 1, paddingRight: 0}}
                                        variant="contained">
                                </Button>
                            </TutorialTooltip>
                            <TutorialTooltip enabled={index === 0} next={`${tutorialPrefix}Played`}
                                             placement="top" title={`${tutorialPrefix}PlayCard${index}`}>
                                <Button disabled={fight.state === "complete"} onClick={handlePlayCard(card)}
                                        startIcon={<GiCardPlay color={theme.palette.common.white}/>}
                                        size="small" sx={styles.button} variant="contained">
                                    Play
                                </Button>
                            </TutorialTooltip>
                        </Box>
                    }
            </Box>)}
        </Box>
    );
}