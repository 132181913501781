import {Box, Button, Typography} from "@mui/material";
import {Game} from "../../game/game";
import {Localisation} from "../localisation/localisation";

interface ILostView {
    game: Game
    onClose: () => void
}
export function LostView({game, onClose}: ILostView) {
    const styles = {
        content: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: 2,
        },
        dialog: {
            width: "100%",
            "& .MuiDialog-paper": {
                margin: {xs: 2, md: 4},
                width: {xs: "100%", md: 500}
            }
        }
    }
    const handleRestart = () => {
        game.restart(Date.now());
        onClose();
    }

    if (game.fight?.state !== "complete") {
        return null;
    }
    return (
        <Box sx={styles.content}>
            <Typography variant="h6">
                {Localisation.GetText("defeatedTitle", {
                    victor: Localisation.GetText(game.fight!.victor?.name),
                    turn: game.turn.toString()
                })}
            </Typography>
            <Typography>
                {Localisation.GetText("defeatedSummary", {
                    items: game.player!.equipment.length.toString(),
                    victories: game.playerController.history.filter(event => event.type === "victory").length.toString()
                })}
            </Typography>
            <Button onClick={handleRestart}>Restart?</Button>
        </Box>
    );
}