import React from "react";
import {Game} from "../../game/game";
import {Details} from "./details/Details";
import {Box, Button, Dialog, IconButton, Typography, useTheme} from "@mui/material";
import version from "../../data/version.json";
import {selectDialog} from "../../redux/selectors/dialog";
import {connect} from "react-redux";
import {IDialog, update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";
import {Help} from "@mui/icons-material";
import {TutorialTooltip} from "../help/TutorialTooltip";
import {Localisation} from "../localisation/localisation";

interface IFightDialog {
    dialog: IDialog
    game: Game
    updateDialog: Update
}
function DetailsDialog({dialog, game, updateDialog}: IFightDialog) {
    const theme = useTheme();
    const styles = {
        dialog: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: 1
        },
        version: {
            display: "flex",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.6em",
            justifyContent: "flex-end",
            margin: 1
        },
        watermark: {
            backgroundImage: "url(./images/dialogs/details.jpg)",
            backgroundSize: "cover",
            height: "100%",
            opacity: "30%"
        }
    }
    const handleClose = () => {
        updateDialog({update: "", store: ["name"]});
    }
    const handleHelpClick = () => {
        updateDialog({
            update: "DetailsTitle",
            store: ["help"]
        })
    }

    return (
        <Dialog fullWidth maxWidth="md" onClose={handleClose} open={dialog.name === "details"} sx={styles.dialog}>
            <Box sx={styles.watermark}/>
            <Details game={game} onClose={handleClose}/>
            <Box sx={styles.version}>
                <Typography variant="inherit">{version.version}</Typography>
            </Box>
            <TutorialTooltip title="DetailsClose">
                <Button onClick={handleClose}>{Localisation.GetText("buttonClose")}</Button>
            </TutorialTooltip>
            <Box display="flex" justifyContent="flex-end">
                <IconButton color="primary" onClick={handleHelpClick} size="small"><Help/></IconButton>
            </Box>
        </Dialog>
    );
}
const mapStateToProps = (store: never) => ({
    dialog: selectDialog(store),
});
const connected = connect(mapStateToProps, {
    updateDialog
})(DetailsDialog);

export {connected as DetailsDialog}
