import assets from "../../data/catalogs/assets/assets.json";
import {Place} from "../places/place";
import {Asset} from "./asset";
import {Requirement} from "../requirements/requirement";
import {IRequirementConstrained, RequirementsFactory} from "../requirements/requirementsFactory";
import {Game} from "../game";
import {IInteraction, Interactions, ISpawningControls} from "../places/placesCatalog";

type AssetData = Record<string, IAssetData>;

export interface IDevelopment {
    capacity?: number
    production?: number
}

interface IUpgrade {
    type: string
    cost: Record<string, number>
}
export interface IAssetData extends IRequirementConstrained {
    cards?: {[card: string]: number}
    cost: Record<string, number>
    development: Record<string, IDevelopment>
    interactions?: Record<Interactions, IInteraction>
    name: string
    places: string[]
    reputationCreate?: number
    reputationRepair?: number
    rewards?: Record<string, number>
    spawning?: ISpawningControls
    states?: string[]
    upgrade?: IUpgrade
}

export class AssetsCatalog {
    private readonly _assetData: AssetData;

    constructor() {
        this._assetData = RequirementsFactory.InitialiseRequirements(assets as AssetData);
    }

    generate(place: Place, type: string) {
        return new Asset(type, this._assetData[type], place);
    }
    getAssetCost(asset: string) {
        return this._assetData[asset].cost;
    }
    getLegalAssets(place: Place, game: Game) {
        return Object.keys(this._assetData)
            .filter(key => ! place.assets.find(asset => asset.type === key))
            .filter(key => this._assetData[key].places.includes(place.category) ||
                this._assetData[key].places.includes(place.type))
            .filter(key => this._assetData[key].requirement === undefined ||
                (this._assetData[key].requirement as Requirement).test({hex: place.hex, game}));
    }
}