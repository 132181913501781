import {Box, Divider, Typography} from "@mui/material";
import {Game} from "../../../game/game";
import {Localisation} from "../../localisation/localisation";
import {HexDetails} from "./HexDetails";
import {AgentDetails} from "./AgentDetails";
import {selectMap} from "../../../redux/selectors/map";
import {connect} from "react-redux";
import {IMap} from "../../../redux/slices/map";
import {Vector2} from "../../../utility/vector2";
import {TutorialTooltip} from "../../help/TutorialTooltip";

interface IDetailsBox {
    game: Game
    map: IMap
    onClose: () => void
}
function Details({game, map, onClose}: IDetailsBox) {
    const styles = {
        content: {
            margin: 1,
            /*
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
            */
        },
        details: {
        },
        title: {
            flexGrow: 1,
            textTransform: "capitalize"
        },
    };
    const selectedAgent = map.selected.agent && game.getAgentFromKey(map.selected.agent);
    const selectedHex = map.selected.hex && game.getHex(new Vector2(map.selected.hex));

    if (selectedHex === undefined) return null;

    return (
        <Box sx={styles.content}>
            <TutorialTooltip next="TerrainType" title="DetailsTitle">
                <Box display="flex">
                    <Typography variant="h6" sx={styles.title}>{Localisation.GetText(selectedHex.type)}</Typography>
                </Box>
            </TutorialTooltip>
            <Divider/>
            <Box sx={styles.details}>
                <HexDetails hex={selectedHex} game={game}/>
                {selectedAgent &&
                    <Box marginTop={1}>
                        <AgentDetails agent={selectedAgent} onClose={onClose}/>
                    </Box>
                }
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    map: selectMap(store)
});
const connected = connect(mapStateToProps, {
})(Details);

export {connected as Details}
