import {NationOfOne} from "./NationOfOne";
import {Logjam} from "./Logjam";

export function App() {
    const domain = window.location.hostname.toLowerCase();

    if (domain === "nationofone.logjam.co.uk" || domain === "localhost" || domain === "192.168.0.80") {
        return <NationOfOne/>
    } else {
        return <Logjam/>
    }
}