import {createDeepSlice} from "./base";

export interface IMap {
    turn: number
    selected: ISelected
}
interface ISelected {
    agent?: number
    hex?: number[]
    place?: number
}

const initialState: IMap = {
    turn: 0,
    selected: {}
};
const slice = createDeepSlice<IMap>("selected", initialState);

export const {remove, update} = slice.actions;
export default slice.reducer;