import {createContext, ReactElement} from "react";
import {AnalyticsController} from "./analyticsController";

const analytics = new AnalyticsController()
const AnalyticsContext = createContext<AnalyticsController>(analytics);

interface IAnalyticsProvider {
    children: ReactElement | ReactElement []
}
function AnalyticsProvider({children}: IAnalyticsProvider) {
    return (
        <AnalyticsContext.Provider value={analytics}>
            <>
                {children}
            </>
        </AnalyticsContext.Provider>
    );
}

export {AnalyticsContext, AnalyticsProvider};