import {Box, Checkbox, Dialog, FormControlLabel, IconButton, Link, Toolbar, Typography} from "@mui/material";
import {ChangeEventHandler, useState} from "react";
import {Close} from "@mui/icons-material";
import {Localisation} from "./localisation/localisation";

export function Welcome() {
    const [open, setOpen] = useState(localStorage.getItem("welcome.dontShowDialog") !== "true");
    const styles = {
        body: {
            margin: 1
        },
        dialog: {
            borderRadius: 2
        },
        paragraph: {
            display: "flex"
        }
    }
    const handleClose = () => setOpen(false);
    const handleDontShowChange:ChangeEventHandler<HTMLInputElement> = ({target}) =>
        localStorage.setItem("welcome.dontShowDialog", target.checked.toString());

    return (
        <Dialog open={open} onClose={handleClose} sx={styles.dialog}>
            <Box>
                <Toolbar disableGutters variant="dense">
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </Toolbar>
                <Box sx={styles.body}>
                    <Typography variant="h3">{Localisation.GetText("welcomeTitle")}</Typography>
                    <Typography variant="h5">{Localisation.GetText("welcomeSubtitle")}</Typography>
                    <Typography variant="caption">{Localisation.GetText("welcomeAnalytics")}</Typography>
                    <Link href="https://www.logjam.co.uk">
                        <Typography>{Localisation.GetText("welcomeLink")}</Typography>
                    </Link>
                    <Box display="flex" flexDirection="row-reverse">
                        <FormControlLabel control={
                            <Checkbox onChange={handleDontShowChange} size="small"/>
                        } label={
                            <Typography variant="caption">{Localisation.GetText("welcomeDontShow")}</Typography>
                        }/>
                    </Box>

                    <Typography>{}</Typography>
                </Box>
            </Box>
        </Dialog>
    );
}