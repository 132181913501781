import {Controller, IController, IControllerSave} from "./controller";
import {Game} from "../game";
import {Agent} from "../agents/agent";
import {Place} from "../places/place";
import {Hex} from "../map/hex";

const placeCategoryPreference: { [category: string]: { [action: string]: number } } = {
    default: {
        developAssets: 1
    },
    lair: {
        developAssets: 0.1,
        spawnMonster: 0.7,
        spawnGuardian: 0.2
    },
    settlement: {
        developAssets: 0.8,
        spawnMonster: 0.1,
        upgradeSettlement: 0.1
    }
}

export class MonsterController extends Controller {
    constructor(controller: IController, faction: string, seed: number, key?: number) {
        super(controller, faction, false, seed, key);
    }

    resolve(game: Game) {
        return Promise.all(this._places.map(place => this.resolvePlace(place, game))).then(() =>
            Promise.all(this._agents.map(agent => this.resolveAgent(agent, game))).then(() => true)
        )
    }

    static serialiseIn(controller: IController, save: IControllerSave) {
        return new MonsterController({
            ...controller,
            enemies: save.enemies,
            friends: save.friends,
            knowledge: save.knowledge,
            skills: save.skills
        }, save.faction, save.random, save.key);
    }

    private enemiesInHex(agent: Agent, hex: Hex, game: Game) {
        return game.getAgents(hex).filter(hexAgent => {
            const faction = hexAgent.controller.faction;

            return this._enemies.includes(faction) || (agent.getOutcast() && agent !== hexAgent &&
                (faction === agent.controller.faction || agent.controller.isFriend(faction)))
        });
    }

    private preyInHex(hex: Hex, game: Game) {
        return game.getAgents(hex).filter(hexAgent => this.prey.includes(hexAgent.type));
    }

    private resolveAgent(agent: Agent, game: Game) {
        const enemiesInHex = this.enemiesInHex(agent, agent.hex, game);
        const preyInHex = agent.type === "settlers" ? [] : this.preyInHex(agent.hex, game);
        const place = game.getPlace(agent.hex)

        if (enemiesInHex.length > 0) {
            return agent.fight(enemiesInHex[0], game);
        } else if (preyInHex.length > 0) {
            return preyInHex.forEach(prey => game.removeAgent(prey));
        } else if (place && agent.getSkill("ransack")) {
            place.ransack(game);
        } else if (place && agent.controller.isEnemy(place.controller.faction) &&
            ! place.hasState("walled") && game.random.get() <= place.getPillageProbability()) {
            return place.pillage(game);
        } else if (agent.roles.includes("guardian")) {
            // Guardian's do nothing, sleeping guardians however, sometimes wake
            if (agent.getSkill("sleeping") && game.random.get() <= 0.01) {
                agent.removeRole("guardian");
            }
        } else if (game.random.get() <= agent.mobility) {
            const candidates = game.getSurroundingHexes(agent.hex, 1)
                .filter(hex => game.getAgents(hex)
                    .filter(agent => !(agent.controller.player || agent.type === "settlers")).length === 0)
                .filter(hex => agent.getPassable(hex.type));
            const enemyHexes = candidates.filter(hex => this.enemiesInHex(agent, hex, game).length > 0);
            const preyHexes = candidates.filter(hex => this.preyInHex(hex, game).length > 0);

            if (candidates.length > 0) {
                const move = (candidates: Hex[]) => {
                    agent.move(game, game.random.getRandom(candidates)!)
                };

                if (preyHexes.length > 0) {
                    const hex = game.random.getRandom(preyHexes);

                    if (hex && agent.move(game, hex)) {
                        if (agent.getSkill("flying")) {
                            this.preyInHex(hex, game).forEach(prey => game.removeAgent(prey));
                        }
                        return Promise.resolve(true);
                    }
                } else if (enemyHexes.length > 0) {
                    const hex = game.random.getRandom(enemyHexes);

                    if (hex && agent.move(game, hex)) {
                        if (agent.getSkill("flying")) {
                            const enemy = this.enemiesInHex(agent, hex, game).pop();

                            if (enemy && ! enemy.getSkill("invisible")) {
                                return agent.fight(enemy, game);
                            }
                        }
                        return Promise.resolve(true);
                    }
                }
                move(candidates);
                return Promise.resolve(true);
            }
        }
        return Promise.resolve(false);
    }

    private resolvePlace(place: Place, game: Game) {
        const distribution = placeCategoryPreference[place.category] || placeCategoryPreference.default;

        switch (game.random.selectFromDistribution(distribution)) {
            case "developAssets": {
                return place.developAssets(game);
            }
            case "spawnMonster": {
                return place.spawnMonster(game);
            }
            case "spawnGuardian": {
                return place.spawnGuardian(game);
            }
            case "upgradeSettlement": {
                return place.upgradeSettlement(game)
            }
        }
        return Promise.resolve(false);
    }
}