import {IRequirementCompound} from "./requirementCompound";
import {RequirementAnd} from "./requirementAnd";
import {RequirementOr} from "./requirementOr";
import {IRequirementNot, RequirementNot} from "./requirementNot";
import {IRequirementResource, RequirementResource} from "./requirementResource";
import {IRequirementAsset, RequirementAsset} from "./requirementAsset";
import {IRequirementPlace, RequirementPlace} from "./requirementPlace";
import {IRequirementTerrain, RequirementTerrain} from "./requirementTerrain";
import {IRequirement, Requirement} from "./requirement";
import {IRequirementCompare, RequirementEqual, RequirementGreaterThan, RequirementLessThan} from "./requirementCompare";

export interface IRequirementConstrained {
    requirement?: IRequirement | Requirement
}

export class RequirementsFactory {
    static InitialiseRequirements<T extends IRequirementConstrained>(data: Record<string, T>): Record<string, T> {
        return Object.fromEntries(Object.entries(data).map(([key, value]) =>
            ([key, {
                ...value,
                requirement: value.requirement ? RequirementsFactory.Create(value.requirement as IRequirement) : undefined
            }])));
    }

    static Create(data: IRequirement) {
        switch(data.type) {

            case "and":
                return new RequirementAnd(data as IRequirementCompound);
            case "or":
                return new RequirementOr(data as IRequirementCompound);
            case "not":
                return new RequirementNot(data as IRequirementNot);
            case "asset":
                return new RequirementAsset(data as IRequirementAsset)
            case "equal":
                return new RequirementEqual(data as IRequirementCompare)
            case "greaterThan":
                return new RequirementGreaterThan(data as IRequirementCompare)
            case "lessThan":
                return new RequirementLessThan(data as IRequirementCompare)
            case "place":
                return new RequirementPlace(data as IRequirementPlace)
            case "resource":
                return new RequirementResource(data as IRequirementResource);
            case "terrain":
                return new RequirementTerrain(data as IRequirementTerrain);
        }
        throw new Error(`Unknown requirement type ${data.type}`);
    }
}