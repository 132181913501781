type Modifier = (text: string) => string

const files = ["achievements", "agents", "assets", "cards", "items", "text", "tutorial"];

const Modifiers: Record<string, Record<string, Modifier>> = {
    default: {
        article: (text: string) =>
            ["a", "e", "i", "o", "u"].includes(text.charAt(0)) ? "an" : "a",
        lowerCase: (text: string) => 
            text.toLowerCase(),
        sentenceCase: (text: string) =>
            text.replace(/^\w/, char => char.toUpperCase()),
        upperCase: (text: string) =>
            text.toUpperCase()
    }
}
export class Localisation {
    private static catalog: Record<string, string> = {};
    private static modifiers: Record<string, Modifier> = {};

    static Initialise(language: string) {
        return Promise.all(files.map(file => new Promise<void>(async (resolve, reject) => {
            Localisation.modifiers = Modifiers[language] || Modifiers.default;
            try {
                const contents = await require(`../../data/localisation/${language}/${file}.json`);

                Localisation.catalog = {...Localisation.catalog, ...contents};
                resolve();
            }
            catch(exception: any) {
                console.error(exception.message);
                reject();
            }
        })));
    }

    static GetText(key?: string, parameters: Record<string, string> = {}) {
        if (!key) {
            return Localisation.catalog.debugUndefined;
        }
        const modify = (text: string, modifiers: string[]) => {
            let modified = text;

            modifiers.forEach(modifier => {
                if (Localisation.modifiers[modifier]) {
                    modified = Localisation.modifiers[modifier](modified)
                }
            })
            return modified;
        };
        let text = Localisation.catalog[key] || key;

        Object.entries(parameters).forEach(([key, value]) => {
            const matches = Array.from(text.matchAll(
                new RegExp(`(?<replace>{${key}:?(?<modifiers>[^}]*)})`, "g")));

            matches.forEach(match => {
                if (match.groups) {
                    text = text.replace(match.groups.replace, modify(value, match.groups.modifiers.split(",")));
                }
            });
        })
        return text;
    }
}