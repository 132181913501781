import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import {initializeApp} from "@firebase/app";
import firebaseConfigDevelop from "../../firebase/development.json";

export class AnalyticsController {
    private readonly analytics: Analytics;

    constructor() {
        const app = initializeApp(firebaseConfigDevelop);

        this.analytics = getAnalytics(app);
    }
    
    logAchievement(achievement: string) {
        logEvent(this.analytics, "unlock_achievement", {achievement_id: achievement});
    }
}