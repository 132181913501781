import places from "../../data/catalogs/places/places.json";
import {Hex} from "../map/hex";
import {IRequirementConstrained, RequirementsFactory} from "../requirements/requirementsFactory";
import {Requirement} from "../requirements/requirement";
import {Game} from "../game";
import * as gameIcons from "react-icons/gi";
import {Random} from "../../utility/random";
import {Terrain} from "../map/terrain";
import {IDevelopment} from "../assets/assetsCatalog";

export const PLACE_TYPES = ["dungeon", "ruins", "village"];
type PlaceType = typeof PLACE_TYPES[number];

type Places = Record<string, IPlaceType>;
type Visual = keyof typeof gameIcons;

export type Interactions = "move";

export interface ISpawningControls {
    guardians?: Record<string, number>
    monsters?: IMonsterSpawn
    settlers?: ISettlersSpawn
}
export interface IInteraction
{
    consequences: IInteractionConsequence[] 
    constraints: IInteractionConstraint[]
    priority: number
    type: string
}

export interface IInteractionConsequence {
    type: string
    asset?: string
    resources?:Record<string, number>
}

export interface IInteractionConstraint
{
    type: string
}
export interface IInteractionAttackAgent extends IInteraction
{
    damage: number
}
export interface IInteractionCreateAgent extends IInteraction
{
    agent: string
}
export interface IInteractionConstraintReputation extends IInteractionConstraint
{
    reputation: number
}
export interface IInteractionConstraintTarget extends IInteractionConstraint
{
    target: string
    range: number
}
export interface IInteractionConstraintThreat extends IInteractionConstraint
{
    threat: string
}
export interface IMonsterSpawn {
    maximum: number
    range: number
    types: IMonsterSpawnType[]
}
interface IMonsterSpawnType {
    likelihood: number
    roles?: string[]
    type: string
}
export interface ISettlersSpawn {
    agent: string
    consequences: ISpawnConsequence[]
}
interface ISpawnConsequence {
    action: string
}
export interface IPlaceType extends IRequirementConstrained {
    interactions?: Record<Interactions, IInteraction>
    cards?: {[card: string]: number}
    category: string
    development: Record<string, IDevelopment>
    distribution: Record<string, number>
    faction: string
    name: string
    spawning?: ISpawningControls
    states: string[]
    vision: number
    visual: Visual
}

export class PlacesCatalog {
    private readonly _places: Places;

    constructor() {
        this._places = RequirementsFactory.InitialiseRequirements(places as Places);
    }

    create(hex: Hex, type?: PlaceType) {
        return type ? {...this._places[type], hex, type} : undefined;
    }

    generate(hex: Hex, game: Game, random: Random, terrain: Terrain) {
        let total = 0;
        const candidates = Object.fromEntries(Object.entries(this._places)
            .filter(([, distributed]) => distributed.distribution[hex.type])
            .filter(([, distributed]) => distributed.requirement === undefined ||
                (distributed.requirement as Requirement).test({hex, game}))
            .map(([type, distributed]) => {
                const probability = distributed.distribution[hex.type] ?? 0;

                total += probability;
                return [type, total];
            }));
        const type = random.getFromDistribution(candidates);

        return this.create(hex, type);
    }

    getVisual(type: string) {
        return this._places[type]?.visual;
    }
}