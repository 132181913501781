import {Agent} from "../agents/agent";
import {Hex} from "../map/hex";
import {Game} from "../game";

export class Move {
    static resolve(agent: Agent, hex: Hex, game: Game) {
        if (hex && game.terrain.getHexDistance(agent.hex, hex) <= agent.range) {
            if (agent.getPassable(hex.type)) {
                agent.setSailing(false);
                return hex;
            } else if ((hex.type === "ocean" || hex.type === "sea") && agent.controller.player) {
                if (agent.sailing) {
                    return hex;
                } else {
                    const place = game.getPlace(agent.hex);

                    if (place && place.assets.find(asset => asset.type === "boatyard") &&
                        place.controller.isFriend(agent.controller.faction)) {
                        agent.setSailing(true);
                        return hex;
                    }
                }
            } 
        }
        return undefined;
    }
}