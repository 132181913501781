interface ICatalogEntry {
    type: string
}

export class Catalog<TData> {
    protected readonly _entries: Record<string, TData>

    constructor(entries: Record<string, TData> | Record<string, TData> []) {
        if (entries instanceof Array) {
            this._entries = entries.reduce((all, entry) => ({...all, ...entry}), {});
        } else {
            this._entries = entries;
        }
    }

    create<TEntry>(type: string, constructor: new (data: TData & ICatalogEntry) => TEntry) {
        const data = this.get(type);

        if (data) {
            return new constructor({...data});
        }
    }

    createFromDistribution<TEntry>(distribution: Record<string, number>,
                                   constructor: new (data: TData & ICatalogEntry) => TEntry) {
        const entries: TEntry[] = [];

        Object.entries(distribution).forEach(([type, number]) => {
            while (number-- > 0) {
                const entry = this.create(type, constructor);

                if (entry) {
                    entries.push(entry);
                }
            }
        });
        return entries;
    }

    get(type: string) {
        const data = this._entries[type];

        if (data) {
            return {...data, type};
        }
    }
    
    getTypes() {
        return Object.keys(this._entries);
    }
}
