import {IRequirement, Requirement} from "./requirement";
import {RequirementsFactory} from "./requirementsFactory";

export interface IRequirementCompound extends IRequirement {
    type: "and" | "or"
    requirements: IRequirement[]
}

export abstract class RequirementCompound extends Requirement {
    protected requirements: Requirement[];
    
    constructor(data: IRequirementCompound) {
        super(data);
        this.requirements = data.requirements.map(data => RequirementsFactory.Create(data));
    }
}