import {Box, useTheme} from "@mui/material";
import {createElement} from "react";
import {IconType} from "react-icons";
import {GiChiliPepper, GiCoins, GiCottonFlower, GiDeer, GiElephant, GiFallingLeaf, GiFishing, GiGoldBar, GiHammerNails,
    GiHorseHead, GiJeweledChalice, GiOysterPearl, GiSheep, GiWheat} from "react-icons/gi";
import {Hex} from "../../game/map/hex";

const icons: {[visual: string]: IconType} = {
    copper: GiCoins,
    cotton: GiCottonFlower,
    deer: GiDeer,
    default: GiDeer,
    elephants: GiElephant,
    fish: GiFishing,
    gold: GiGoldBar,
    horses: GiHorseHead,
    pearls: GiOysterPearl,
    pepper: GiChiliPepper,
    sheep: GiSheep,
    silver: GiJeweledChalice,
    tea: GiFallingLeaf,
    tin: GiHammerNails,
    wheat: GiWheat
};

interface IResourceView {
    hex: Hex
}
export function ResourceView({hex}: IResourceView) {
    const theme = useTheme();
    const styles = {
        resourceContainer: {
            display: "flex",
        }
    };
    const icon = hex.resource ? icons[hex.resource] : icons.default;
    const iconProps = {
        ...theme.map.icon,
        color: theme.map.color.neutral
    };

    return (
        <Box sx={styles.resourceContainer}>
            {createElement(icon, iconProps)}
        </Box>
    );
}