import {Box, useTheme} from "@mui/material";
import {Place} from "../../game/places/place";
import {createElement, MouseEventHandler, TouchEventHandler, useState} from "react";
import {Game} from "../../game/game";
import {Vector2} from "../../utility/vector2";
import * as gameIcons from "react-icons/gi";

const DEFAULT_PLACE_ICON = "GiDirectionSigns";

interface IPlaceView {
    game: Game
    onInteractionStart: (position: Vector2) => void
    onInteractionFinish: (position: Vector2) => void
    place: Place
    selected: boolean
}
export function PlaceView({game, onInteractionStart, onInteractionFinish, place, selected}: IPlaceView) {
    const theme = useTheme();
    const color = place.reputation > 1 ? theme.map.color.known : theme.map.color[game.getPlayerAttitude(place)];
    const [touchPosition, setTouchPosition] = useState(Vector2.zero);
    const styles = {
        container: {
            display: "flex"
        },
        iconContainer: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
        },
        selected: {
            borderColor: color,
            borderRadius: 4,
            borderStyle: "solid",
            borderWidth: 2,
            margin: "2px",
            cursor: "grab"
        },
        unselected: {
            margin: "4px"
        }
    };
    const handleDragStart: MouseEventHandler = event => event.preventDefault();
    const handleMouseDown: MouseEventHandler = (event) => {
        if (event.button === 0) {
            onInteractionStart(new Vector2(event.pageX, event.pageY));
            event.stopPropagation();
        }
    }
    const handleMouseUp: MouseEventHandler = ({pageX, pageY}) => {
        onInteractionFinish(new Vector2(pageX, pageY));
    }
    const handleTouchEnd: TouchEventHandler = () => {
        onInteractionFinish(touchPosition);
    }
    const handleTouchMove: TouchEventHandler = ({touches}) => {
        setTouchPosition(new Vector2(touches[0].pageX, touches[0].pageY));
    }
    const handleTouchStart: TouchEventHandler = (event) => {
        onInteractionStart(new Vector2(event.touches[0].pageX, event.touches[0].pageY));
        event.stopPropagation();
    }

    return (
        <Box sx={styles.container}>
            <Box onDragStart={handleDragStart} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
                 onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}
                 sx={{...styles.iconContainer, ...selected ? styles.selected : styles.unselected}}>
                {createElement(gameIcons[place.visual] || gameIcons[DEFAULT_PLACE_ICON],
                    {...theme.map.icon, color})}
            </Box>
        </Box>
    );
}