import {ICountTest, IRequirementCount, RequirementCount} from "./requirementCount";

export interface IRequirementResource extends IRequirementCount {
    type: "resource"
    resource: string
    range: number
}

export class RequirementResource extends RequirementCount {
   
    private readonly resource: string;
    private readonly range: number;

    constructor(data: IRequirementResource) {
        super(data);
        this.resource = data.resource;
        this.range = data.range;
    }

    getCount(props: ICountTest) {
        const testResource = (resource?: string) =>
            resource === this.resource || (resource && this.resource === "any")

        if (this.range === 0) {
            return testResource(props.hex.resource) ? 1 : 0
        } else {
            return props.game.terrain.getSurroundingHexes(props.hex, this.range).reduce((total, hex) =>
                testResource(hex.resource) ? total + 1 : total, 0);
        }
    }
}