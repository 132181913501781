import {MapController} from "./MapController";
import {MapView} from "./MapView";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Vector2} from "../../utility/vector2";
import {connect} from "react-redux";
import {Game} from "../../game/game";
import {MapSize} from "./mapSize";
import {selectMap} from "../../redux/selectors/map";
import {IMap, update as updateMap} from "../../redux/slices/map";

interface IProps {
    focus?: Vector2
    game: Game
    map: IMap
    mapSize: MapSize
}

function MapContainer({focus, game, map, mapSize}: IProps) {
    const [mapOffset, setMapOffset] = useState(new Vector2(0, 0));
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const handleDragMoveAgent = useCallback((position: Vector2) => {
        if (game && map.selected.agent) {
            const selectedAgent = game.getAgentFromKey(map.selected.agent);
            const targetHex = game.getHex(position);

            if (selectedAgent && ! selectedAgent.hex.position.equals(targetHex.position)) {
                return game.actions.moveAgent(selectedAgent, targetHex);
            }
        }
        return Promise.resolve(false);
    }, [game, map.selected.agent]);
    const handleDragMovePlace = useCallback((position: Vector2) => {
        if (game && map.selected.place) {
            const targetHex = game.getHex(position);
            const selectedPlace = game.getPlaceFromKey(map.selected.place);

            if (selectedPlace) {
                selectedPlace.resolveInteraction("move", game, targetHex)
            }
        }
        return Promise.resolve(false);
    }, [game, map.selected.place]);

    useEffect(() => {
        const handleGameUpdate = (message: any) => {
            if (message) {
                forceUpdate();
            }
        }
        if (game) {
            game.updateDelegates.add(handleGameUpdate);
            return () => game.updateDelegates.remove(handleGameUpdate);
        }
    }, [forceUpdate, game]);
    if (mapSize === undefined) return null;
    return (
        <MapController focus={focus} onSetOffset={setMapOffset} mapSize={mapSize}>
            <MapView game={game} mapOffset={mapOffset} mapSize={mapSize}
                     onDragAgent={handleDragMoveAgent} onDragPlace={handleDragMovePlace}/>
        </MapController>
    );
}

const mapStateToProps = (store: never) => ({
    map: selectMap(store)
});
const connected = connect(mapStateToProps, {
    updateMap
})(MapContainer);

export {connected as MapContainer}
