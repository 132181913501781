import {Game} from "../../game/game";
import {FightView} from "../fight/FightView";
import {Dialog} from "@mui/material";
import {useEffect, useState} from "react";
import {RewardView} from "../fight/RewardView";
import {LostView} from "../fight/LostView";
import {MapSize} from "../map/mapSize";
import {connect} from "react-redux";
import {update as updateMap} from "../../redux/slices/map";
import {Update} from "../../redux/slices/base";
import {IDialog, update as updateDialog} from "../../redux/slices/dialog";
import {selectDialog} from "../../redux/selectors/dialog";

interface IFightDialog {
    dialog: IDialog
    game: Game
    mapSize: MapSize
    updateDialog: Update
    updateMap: Update
}
function FightDialog({dialog, game, mapSize, updateDialog, updateMap}: IFightDialog) {
    const [view, setView] = useState("fight");
    const styles = {
        dialog: {
            width: "100%",
            "& .MuiDialog-paper": {
                margin: {xs: 2, md: 4},
                width: {xs: "100%", md: 500}
            }
        }
    }
    const handleClose = () => {
        if (view === "fight" && game.fight?.state === "complete") {
            if (game.state === "lost") {
                setView("lost");
            } else {
                setView("reward");
            }
        } else {
            updateDialog({update: "", store: ["name"]});
        }
    }

    useEffect(() => {
        if (dialog?.name === "fight" && game.fight?.state === "initial") {
            setView("fight");
            updateMap({
                update: {
                },
                store: ["selected"]
            });
        }
    }, [game, game.fight, updateMap, dialog]);
    return (
        <Dialog maxWidth="md" open={dialog?.name === "fight"} sx={styles.dialog}>
            {view === "fight" ?
                <FightView game={game} onClose={handleClose}/> : view === "reward" ?
                <RewardView game={game} mapSize={mapSize} onClose={handleClose}/> :
                <LostView game={game} onClose={handleClose}/>
            }
        </Dialog>
    );
}

const mapStateToProps = (store: never) => ({
    dialog: selectDialog(store)
});
const connected = connect(mapStateToProps, {
    updateDialog,
    updateMap
})(FightDialog);

export {connected as FightDialog}
