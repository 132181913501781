import {Box, Card as MuiCard, CardContent, CardHeader, Typography, useTheme} from "@mui/material";
import {Competitor} from "../../game/fight/competitor";
import {Fight} from "../../game/fight/fight";
import React, {MouseEventHandler, TouchEventHandler, useReducer, useState} from "react";
import {GetCardVisual} from "./CardView";
import {Localisation} from "../localisation/localisation";

interface IHandView {
    fight: Fight
    player: Competitor
}
export function HandView({fight, player}: IHandView) {
    const [dragging, setDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState(0);
    const [dragStart, setDragStart] = useState(0);
    const [selected, setSelected] = useState<number>();
    const theme = useTheme();
    const styles = {
        card: {
            height: "90%",
            left: "8%",
            position: "absolute",
            top: "5%",
            transformOrigin: "bottom left",
            width: "80%"
        },
        hand: {
            position: "relative",
            top: 0,
            height: "100%",
            width: "100%"
        }
    };
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const handleFinishInteraction = () => {
        setDragging(false);
        if (dragOffset > 100) {
            fight.playCard(player, player.hand[selected!]);
        } else if (dragOffset < -100) {
            fight.passCard(player, player.hand[selected!]);
            forceUpdate();
        }
    }
    const handleMouseDown = (index: number): MouseEventHandler => ({pageX}) => {
        setDragging(true);
        setSelected(index);
        setDragStart(pageX);
        setDragOffset(0);
    }
    const handleTouchStart = (index: number): TouchEventHandler => ({touches}) => {
        if (touches.length === 1) {
            setDragging(true);
            setSelected(index);
            setDragStart(touches[0].pageX);
            setDragOffset(0);
        }
    }
    const handleMouseMove: MouseEventHandler = ({pageX}) => {
        if (dragging) {
            setDragOffset(pageX - dragStart);
        }
    }
    const handleTouchMove: TouchEventHandler = ({touches}) => {
        if (dragging && touches.length === 1) {
            setDragOffset(touches[0].pageX - dragStart);
        }
    }

    return (
        <Box sx={styles.hand}>
            {player.hand.map((card, index) => {
                const transform = (selected === index && dragging && Math.abs(dragOffset) > 4) ?
                    `translate(${dragOffset}px, 0)` : `rotate(${10 * index - 4}deg)`;

                return (<MuiCard onMouseDown={handleMouseDown(index)} onMouseMove={handleMouseMove}
                         onMouseUp={handleFinishInteraction} onTouchEnd={handleFinishInteraction}
                         onTouchMove={handleTouchMove} onTouchStart={handleTouchStart(index)} key={index}
                         sx={{
                             ...styles.card,
                             transform,
                             zIndex: index === selected ? 1000 : 0
                         }}>
                    <CardHeader avatar={GetCardVisual(card.type, theme.fight.icon)}
                                title={Localisation.GetText(card.name)}/>
                    <CardContent>
                        <Typography variant="body2">
                            {Localisation.GetText(card.description)}
                        </Typography>
                    </CardContent>
                </MuiCard>)}
            )}
        </Box>
    );
}