import {Agent} from "../agents/agent";
import {Game} from "../game";
import {Card} from "./card";
import {Competitor} from "./competitor";

type FightState = "abandoned" | "initial" | "ongoing" | "complete";
type UpdateDelegate = () => void

interface IProps {
    attacker: Agent
    defender: Agent
    game: Game
    defended?: Agent
}

export class Fight {
    private readonly _updateDelegates: UpdateDelegate[] = [];

    private readonly _attacker: Competitor;
    private readonly _defender: Competitor;

    private _state: FightState = "initial";

    constructor(private _props: IProps) {
        this._props.defender.controller.respondToAttack(this._props.defender, this._props.attacker);
        this._props.attacker.controller.respondToAttack(this._props.attacker, this._props.defender);
        this._attacker = new Competitor({
            agent: this._props.attacker,
            defended: this._props.attacker.controller.player ? this._props.defended : undefined, 
            fight: this,
            game: this._props.game
        });
        this._defender = new Competitor({
            agent: this._props.defender,
            defended: this._props.defender.controller.player ? this._props.defended : undefined,
            fight: this,
            game: this._props.game
        })
    }

    get attacker() {
        return this._attacker;
    }

    get defender() {
        return this._defender;
    }

    get defeated() {
        if (this.defender.hearts <= 0) {
            return this.defender.agent;
        } else if (this.attacker.hearts <= 0) {
            return this.attacker.agent;
        }
        return undefined;
    }

    get state() {
        return this._state;
    }

    get victor() {
        if (this.defender.hearts <= 0) {
            return this.attacker.agent;
        } else if (this.attacker.hearts <= 0) {
            return this.defender.agent;
        }
        return undefined;
    }

    addUpdateDelegate(delegate: UpdateDelegate) {
        this._updateDelegates.push(delegate)
    }

    removeUpdateDelegate(delegate: UpdateDelegate) {
        const index = this._updateDelegates.indexOf(delegate);

        if (index >= 0) {
            this._updateDelegates.splice(index, 1);
        }
    }

    passCard(competitor: Competitor, card: Card) {
        competitor.pass(card);
    }

    playCard(competitor: Competitor, card: Card) {
        const opponent = this._attacker === competitor ? this._defender : this._attacker;

        if (this.state === "initial") {
            this._state = "ongoing";
        }
        if (this.state === "ongoing") {
            // Each competitor plays their card to set the damages and blocks
            competitor.play(this, card);
            opponent.play(this);
            // Resolve the damages and blocks
            if (this._defender.resolve() || this._attacker.resolve()) {
                if ((this.defender.retreated && this.defender.hearts > 0) ||
                    (this._attacker.retreated && this._attacker.hearts > 0)) {
                    this._state = "abandoned";
                } else {
                    this._state = "complete";
                }
            }
        }
        this._updateDelegates.forEach(delegate => delegate());
    }

    shuffle(cards: Card[]) {
        for (let i = cards.length - 1; i > 0; i--) {
            const j = Math.floor(this._props.game.random.get() * (i + 1));
            [cards[i], cards[j]] = [cards[j], cards[i]];
        }
        return cards;
    }
}