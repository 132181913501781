import React from 'react';
import {createRoot} from 'react-dom/client';
import {store} from "./redux/store";
import { Provider } from 'react-redux';
import {App} from "./components/App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById("root");

if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>
    );
}

serviceWorkerRegistration.register();
