import {Perlin} from "../../utility/perlin";
import {Vector2} from "../../utility/vector2";
import {Hex} from "./hex";
import terrain from "../../data/catalogs/map/terrain.json";
import {Random} from "../../utility/random";
import {MapSize} from "../../components/map/mapSize";
import {ResourcesCatalog} from "./resourcesCatalog.";
import {TerrainsCatalog, TerrainType} from "./terrainsCatalog";

const PERLIN_SIZE = 7;

interface ITerrainData {
    altitudes: Record<string, number>
    latitudes: Record<string, number>
    start: TerrainType[]
}

export class Terrain {
    private static data = terrain as ITerrainData;
    private perlin: Perlin;
    private hexes: Hex[][] = [];

    constructor(size: MapSize, private _seed: number, private _resourcesCatalog: ResourcesCatalog,
                private _terrainsCatalog: TerrainsCatalog) {
        const legalTerrains = _terrainsCatalog.getLegalTerrains();
        const random = new Random(_seed);

        this.perlin = new Perlin(PERLIN_SIZE, random);
        for (let row = 0; row < size.MapSize.y; ++row) {
            this.hexes[row] = [];
            for (let column = 0; column < size.MapSize.x; ++column) {
                const position = new Vector2(column, row);
                const altitude = this.perlin.get(new Vector2(column / size.MapSize.x, row / size.MapSize.y));
                const latitude = 2 * Math.abs((row / size.MapSize.y) - 0.5);
                const latitudeType = Object.keys(Terrain.data.latitudes)
                    .find(type => Terrain.data.latitudes[type] >= latitude)!;
                const altitudeLevel = Object.keys(Terrain.data.altitudes)
                    .find(type => Terrain.data.altitudes[type] >= altitude)!;
                const select = altitude * 100 - Math.floor(altitude * 100);
                const legal = legalTerrains[latitudeType][altitudeLevel];
                const type = legal ? legal[Math.floor(select * legal.length)] as TerrainType : "sea";
                const terrain = _terrainsCatalog.getTerrain(type)!;

                if (!legal) {
                    console.error(`No legal terrain found for ${latitudeType}, ${altitudeLevel}`);
                }
                this.hexes[row][column] = new Hex({
                    position,
                    type,
                    resource: random.getFromDistribution(terrain.resources),
                    vision: terrain.vision
                });
            }
        }
    }

    get columns() {
        return this.hexes.length ? this.hexes[0].length : 0;
    }

    get rows() {
        return this.hexes.length;
    }

    get seed() {
        return this._seed;
    }

    getCandidateHexes() {
        const candidates: Hex[] = [];

        this.hexes.forEach((columns) =>
            columns.forEach((hex) =>
                Terrain.data.start.includes(hex.type) && candidates.push(hex)
            )
        );
        return candidates;
    }

    getHexes() {
        return this.hexes.flat();
    }

    getHex(position: Vector2) {
        return this.hexes[position.y][position.x];
    }

    getHexDistance(from: Hex, to: Hex) {
        const mapWidth = this.columns;
        const halfMapWidth = Math.floor(mapWidth * 0.5);

        if (Math.abs(from.position.x - to.position.x) > halfMapWidth) {
            const wrappedFrom = new Vector2((from.position.x + halfMapWidth) % mapWidth, from.position.y);
            const wrappedTo = new Vector2((to.position.x + halfMapWidth) % mapWidth, to.position.y);

            return Terrain.getAxialDistance(wrappedFrom.axial(), wrappedTo.axial());
        }
        return Terrain.getAxialDistance(from.position.axial(), to.position.axial());
    }

    getSurroundingHexes(hex: Hex, maxRange: number, minRange = 0) {
        const withinRange: Hex [] = [];

        for (let x = hex.position.x - maxRange; x <= hex.position.x + maxRange; ++x) {
            for (let y = hex.position.y - maxRange; y <= hex.position.y + maxRange; ++y) {
                const column = (x + this.columns) % this.columns;
                const row = Math.min(Math.max(y, 0), this.rows - 1);
                const test = this.getHex(new Vector2(column, row));
                const distance = this.getHexDistance(test, hex);

                if (distance <= maxRange && distance >= minRange) {
                    withinRange.push(test);
                }
            }
        }
        return withinRange;
    }

    private static getAxialDistance(axialFrom: Vector2, axialTo: Vector2) {
        return (Math.abs(axialTo.x - axialFrom.x) + Math.abs(axialTo.y - axialFrom.y) +
            Math.abs((-axialTo.x - axialTo.y) - (-axialFrom.x - axialFrom.y))) * 0.5;
    }
}