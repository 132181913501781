import {Catalog} from "../catalog/catalog";
import cards from "../../data/catalogs/cards/cards.json";
import {Competitor} from "./competitor";

export interface ICard {
    description: string
    effects: {
        self: IEffect []
        other: IEffect []
    }
    name: string
}

interface IEffect {
    type: string
    data: any
}

interface IBlockEffect extends IEffect {
    type: "block"
    data: {
        types: Record<string, number>
    }
}

interface IDamageEffect extends IEffect {
    type: "damage",
    data: {
        types: Record<string, number>
    }
}

interface IStateEffect extends IEffect {
    type: "state",
    data: {
        name: string
        prevents: Record<string, number>
    }
}

interface IStateMagic extends IEffect {
    type: "magic",
    data: {
        duration?: number
        magic: string
    }
}

interface IProps extends ICard {
    type: string
}

export class Card {
    static catalog = new Catalog<ICard>(cards)

    constructor(private _props: IProps) {
    }

    get description() {
        return this._props.description;
    }

    get effects() {
        return this._props.effects;
    }

    get type() {
        return this._props.type;
    }

    get name() {
        return this._props.name;
    }

    play(self: Competitor, other: Competitor) {
        this.effects.other.forEach(effect => Card.affect(effect, other));
        this.effects.self.forEach(effect => Card.affect(effect, self));
    }

    private static affect(effect: IEffect, target: Competitor) {
        if (effect.type === "damage") {
            const damage = effect as IDamageEffect;

            target.damage(damage.data.types);
        } else if (effect.type === "block") {
            const block = effect as IBlockEffect;

            target.block(block.data.types);
        } else if (effect.type === "state") {
            const state = effect as IStateEffect;

            target.setPrevented(state.data.prevents)
        } else if (effect.type === "magic") {
            const magic = effect as IStateMagic;

            if (magic.data.magic === "retreat") {
                target.retreat();
            } else if (magic.data.magic === "heal" && target.hearts < target.maxHearts) {
                target.heal(1);
            } else if (magic.data.magic === "prescience" && magic.data.duration) {
                target.setMagic("prescient", magic.data.duration);
            }
        }
    }
}