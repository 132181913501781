import {Box, Button, Dialog, Typography} from "@mui/material";
import {Game} from "../../game/game";
import {IFeast} from "../../game/agents/agent";
import {Localisation} from "../localisation/localisation";
import {REPUTATION_LEVELS} from "../../game/places/place";
import {useReducer} from "react";
import {selectDialog} from "../../redux/selectors/dialog";
import {connect} from "react-redux";
import {IDialog, update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";

interface IFeastDialog {
    dialog: IDialog
    game: Game
    updateDialog: Update
}
function FeastDialog({dialog, game, updateDialog}: IFeastDialog) {
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: 1
        },
        dialog: {
            display: "flex",
            flexGrow: 1,
            height: 500,
            width: {xs: "100%", md: 500}
        },
        title: {
            fontWeight: "bold",
            textTransform: "Capitalize"
        },
    };
    const player = game.player;
    const history = game.playerController.history;
    const event = history && history.length > 0 && history[history.length - 1];
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;

    if (! event || event.type !== "feast") return null;

    const eventData = event.data as IFeast;
    const reward = player?.getItem(eventData.reward);
    const handleClose = () => updateDialog({update: "", store: ["name"]});
    const handleEquipClick = () => {
        if (player && reward) {
            player.equipItem(reward.type);
            forceUpdate();
        }
    }
    const place = game.player && game.getPlace(game.player.hex);

    if (! place) return null;
    return (
        <Dialog open={dialog.name === "feast"} onClose={handleClose}>
            <Box sx={styles.dialog}>
                <Box sx={styles.content}>
                    <Typography sx={styles.title}>{Localisation.GetText("feastTitle")}</Typography>
                    <Typography>
                        {Localisation.GetText("feastContent")
                            .replace("{reputation}", REPUTATION_LEVELS[place.reputation])}
                    </Typography>
                    {reward &&
                        <Box>
                            <Typography>
                                {Localisation.GetText("feastReward")}
                            </Typography>
                            <Box>
                                <Typography sx={styles.title}>{Localisation.GetText(reward.name)}</Typography>
                                <Typography>
                                    {Localisation.GetText(reward.description)}
                                </Typography>
                                {player?.getEquippedItem(reward.slot) !== reward &&
                                    <Button disabled={player?.getEquippedItem(reward.slot) !== undefined}
                                            onClick={handleEquipClick}>
                                        Equip
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }
                    <Button onClick={handleClose}>Finish</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
const mapStateToProps = (store: never) => ({
    dialog: selectDialog(store)
});
const connected = connect(mapStateToProps, {
    updateDialog
})(FeastDialog);

export {connected as FeastDialog}
