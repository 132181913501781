import {Box} from "@mui/material";
import React from "react";
import {Game} from "../../game/game";
import {Hex} from "../../game/map/hex";
import {AgentView} from "./AgentView";
import {Agent} from "../../game/agents/agent";
import {Vector2} from "../../utility/vector2";
import {PlaceView} from "./PlaceView";
import {ResourceView} from "./ResourceView";
import {Place} from "../../game/places/place";
import {selectMap} from "../../redux/selectors/map";
import {connect} from "react-redux";
import {IMap} from "../../redux/slices/map";

interface IHexChildren {
    game: Game
    hex: Hex
    map: IMap
    onAgentInteractionStart: (agent: Agent, position: Vector2) => void
    onAgentInteractionFinish: (agent: Agent, position: Vector2) => void
    onPlaceInteractionStart: (place: Place, position: Vector2) => void
    onPlaceInteractionFinish: (place: Place, position: Vector2) => void
}
function HexContents({game, hex, map, onAgentInteractionStart, onAgentInteractionFinish,
                                onPlaceInteractionStart, onPlaceInteractionFinish}: IHexChildren) {
    const styles = {
        contents: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: 1,
            marginTop: "10px"
        },
        row: {
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            height: "50%"
        }
    }
    const agents = game.getAgents(hex);
    const agentView = (agent: Agent, index: number) =>
        <AgentView key={index} agent={agent} game={game}
                   onInteractionStart={(position: Vector2) => onAgentInteractionStart(agent, position)}
                   onInteractionFinish={(position: Vector2) => onAgentInteractionFinish(agent, position)}
                   selected={agent?.key === map?.selected.agent}/>
    const place = game.getPlace(hex);

    return (
        <Box sx={styles.contents}>
            <Box sx={styles.row}>
                {game.getHexVisible(hex) &&
                    <>
                        <Box>
                            {agents.filter(agent => agent.controller.player).map(agentView)}
                        </Box>
                        <Box>
                            {agents.filter(agent => ! agent.controller.player).map(agentView)}
                        </Box>
                    </>
                }
            </Box>
            <Box sx={styles.row}>
                <Box>
                    {hex.resource &&
                        <ResourceView hex={hex}/>
                    }
                </Box>
                <Box>
                    {place && <PlaceView onInteractionStart={position => onPlaceInteractionStart(place, position)}
                                         onInteractionFinish={position => onPlaceInteractionFinish(place, position)}
                                         place={place} game={game} selected={place?.key === map?.selected.place}/>}
                </Box>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    map: selectMap(store)
});
const connected = connect(mapStateToProps, {
})(HexContents);

export {connected as HexContents}
