import {Game} from "../../game/game";
import {Box, Button, IconButton, Typography} from "@mui/material";
import {Localisation} from "../localisation/localisation";
import {Skill} from "../../game/skills/skill";
import React, {useReducer} from "react";
import {IVictory} from "../../game/agents/agent";
import {Place, REPUTATION_LEVELS} from "../../game/places/place";
import {MapSize} from "../map/mapSize";
import {Help} from "@mui/icons-material";
import {selectHelp} from "../../redux/selectors/dialog";
import {connect} from "react-redux";
import {update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";
import {TutorialTooltip} from "../help/TutorialTooltip";

interface IRewardView {
    game: Game
    mapSize: MapSize
    onClose: () => void
    updateDialog: Update
}

function RewardView({game, mapSize, onClose, updateDialog}: IRewardView) {
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: {xs: "100%", md: 500}
        },
        dialog: {
            display: "flex",
            flexGrow: 1,
            height: 500,
            width: {xs: "100%", md: 500}
        },
        event: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: 1
        },
        reputation: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            marginTop: 1,
            width: "100%"
        },
        title: {
            fontWeight: "bold",
            textTransform: "Capitalize"
        },
        watermark: {
            backgroundImage: "url(./images/dialogs/reward.jpg)",
            backgroundSize: "cover",
            height: "100%",
            opacity: "30%",
            width: "100%"
        }
    };
    const player = game.fight?.victor;
    const history = game.playerController.history;
    const event = history && history.length > 0 && history[history.length - 1];
    
    if (! player || ! event || event.type !== "victory") return null;

    const eventData = event.data as IVictory;
    const skill = eventData.reward.skill && Skill.catalog.create(eventData.reward.skill, Skill);
    const item = eventData.reward.item && player.getItem(eventData.reward.item);
    const getLocation = (place: Place) =>
        place.hex === player.hex ?
            Localisation.GetText("mixinSettlementLocal", {
                settlement: place.type
            }) :
            Localisation.GetText("mixinSettlementDirection", {
                direction: Localisation.GetText(mapSize.getDirection(player.hex.position, place.hex.position)),
                settlement: place.type
            });
    const handleEquipClick = () => {
        if (player && item) {
            player.equipItem(item.type);
            forceUpdate();
        }
    }
    const handleHelpClick = () => {
        updateDialog({
            update: "RewardsTitle",
            store: ["help"]
        })
    }
    const reputationRewards = eventData.reward.reputation.map(place => game.getPlaceFromKey(place))
        .filter(place => place !== undefined);
    const feastRewards = eventData.reward.feast.map(key => game.getPlaceFromKey(key))
        .filter(place => place !== undefined);


    return (
        <Box sx={styles.dialog}>
            <Box sx={styles.watermark}/>
            <Box sx={styles.content}>
                <Box sx={styles.event}>
                    <TutorialTooltip title="RewardsTitle" next="RewardsList">
                        <Typography variant="h5">You have defeated a {eventData.type} on turn {event.turn}</Typography>
                    </TutorialTooltip>
                    <TutorialTooltip next="RewardsSkill" placement="top" title="RewardsList">
                        <Typography variant="h6">Rewards</Typography>
                    </TutorialTooltip>
                    <TutorialTooltip enabled={Boolean(skill)} placement="top" title="RewardsSkill">
                        <>
                            {skill &&
                                <Box>
                                    <Typography sx={styles.title}>{Localisation.GetText(skill.type)}</Typography>
                                    <Typography>
                                        {Localisation.GetText(skill.description)}
                                    </Typography>
                                </Box>
                            }
                        </>
                    </TutorialTooltip>
                    <TutorialTooltip enabled={Boolean(item)} next="RewardsReputation" placement="top"
                                     title="RewardsItem">
                        <>
                            {item &&
                                <Box>
                                    <Typography sx={styles.title}>{Localisation.GetText(item.name)}</Typography>
                                    <Typography>
                                        {Localisation.GetText(item.description)}
                                    </Typography>
                                    {player?.getEquippedItem(item.slot) !== item &&
                                        <Button onClick={handleEquipClick}>Equip</Button>
                                    }
                                </Box>
                            }
                        </>
                    </TutorialTooltip>
                    <Box sx={styles.reputation}>
                        <TutorialTooltip enabled={Boolean(reputationRewards.length)} next="RewardsFeast"
                                         placement="top" title="RewardsReputation">
                            <>
                                {
                                    reputationRewards.map((place, index) =>
                                            <Box key={index}>
                                                <Typography>
                                                    {Localisation.GetText("reputationImproved", {
                                                        renown: REPUTATION_LEVELS[place!.reputation],
                                                        location: getLocation(place!)
                                                    })}
                                                </Typography>
                                            </Box>
                                        )
                                }
                            </>
                        </TutorialTooltip>
                        <TutorialTooltip enabled={Boolean(feastRewards.length)} next="RewardsFeast"
                                         placement="top" title="RewardsFeast">
                            <>
                                {
                                    feastRewards.map((place, index) =>
                                            <Box key={index}>
                                                {place!.reward &&
                                                <Typography>
                                                    {Localisation.GetText("reputationFeast", {
                                                        location: getLocation(place!)
                                                    })}
                                                </Typography>
                                                }
                                            </Box>
                                        )
                                }
                            </>
                        </TutorialTooltip>
                </Box>
                </Box>
                <Button onClick={() => onClose()}>Finish</Button>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton color="primary" onClick={handleHelpClick} size="small"><Help/></IconButton>
                </Box>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    help: selectHelp(store)
});
const connected = connect(mapStateToProps, {
    updateDialog,
})(RewardView);

export {connected as RewardView}
