import {Box, List, ListItemText, Modal, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import MuiMarkdown from "mui-markdown";
import welcome from "../data/welcome.md";

export function Logjam() {
    const [content, setContent] = useState("");
    const theme = useTheme();
    const styles = {
        body: {
            overflowY: "auto"
        },
        close: {
            position: "absolute",
            top: {xs: theme.spacing(1), md: theme.spacing(8)},
            right: {xs: theme.spacing(1), md: theme.spacing(8)}
        },
        content: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto"
        },
        dialog: {
            background: theme.palette.background.paper,
            borderRadius: 2,
            height: {xs: "calc(100% - 32px)", md: "calc(100% - 256px)"},
            margin: {xs: 1, md: 8},
            padding: {xs: 1, md: 4}
        },
        paragraph: {
            display: "flex"
        }
    }
    const overrides = {
        li: {
            component: ListItemText,
            props: {
            }
        },
        ul: {
            component: List,
            props: {
                dense: true
            }
        }
    };

    useEffect(() => {
        fetch(welcome).then(response => response.text()).then(text => {
            setContent(text);
        });
    }, []);
    return (
        <Modal open={true}>
            <Box sx={styles.dialog}>
                <Box sx={styles.content}>
                    <Box sx={styles.body}>
                        <MuiMarkdown children={content} overrides={overrides}/>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}