import {Hex} from "../map/hex";
import beasts from "../../data/catalogs/agents/beasts.json";
import citizens from "../../data/catalogs/agents/citizens.json";
import monsters from "../../data/catalogs/agents/monsters.json";
import villains from "../../data/catalogs/agents/villains.json";
import archetypes from "../../data/catalogs/agents/archetypes.json";
import {Random} from "../../utility/random";
import * as gameIcons from "react-icons/gi";
import {Catalog} from "../catalog/catalog";
import {Game} from "../game";

type Visual = keyof typeof gameIcons;
type Distribution = Record<string, number>;

const defaultAgentType: IAgentType = {
    description: "unset",
    faction: "",
    distribution: {},
    health: 3,
    learn: {},
    loot: {},
    name: "Agent",
    mobility: 0,
    passable: [],
    range: 1,
    renown: 0,
    vision: 1,
    visual: "GiHoodedFigure"
}
export interface IArchetype {
    acceptsOrders?: boolean
    archetype?: string
    cards?: Distribution
    description: string
    distribution: Distribution
    faction: string
    health: number
    items?: string[]
    learn: Distribution
    loot: Distribution
    mobility: number
    passable: string []
    range: number
    renown: number
    settlements?: ISettlement[]
    skills?: string[]
    stalking?: IStalking
    vision: number
    visual: Visual
}
interface ISettlement {
    type: string
    constraint?: ISettlementConstraint
}
interface ISettlementConstraint {
    type: string
    data: any
}
interface IStalking {
    from?: number
    likelihood: number
    max: number
    to?: number
}
export interface IAgentType extends IArchetype {
    archetype?: string
    name: string
}
export class AgentsCatalog extends Catalog<IAgentType> {

    private static buildAgentTypes(agents: Record<string, any>) {
        return Object.fromEntries(Object.entries(agents).map(([name, data]) =>
            ([name, {
                ...defaultAgentType,
                ...data.archetype ? AgentsCatalog.getArchetype(data.archetype) : {},
                ...data as IAgentType
            }]))
        )
    }

    private static getArchetype(name: string): IArchetype {
        const archetype = (archetypes as Record<string, any>)[name] as IArchetype;

        if (! archetype) {
            console.error(`Failed to find archetype named ${name}`);
        }
        return {
            ...archetype.archetype ? this.getArchetype(archetype.archetype as string) : {},
            ...archetype
        };
    }

    constructor() {
        super([
            AgentsCatalog.buildAgentTypes(beasts),
            AgentsCatalog.buildAgentTypes(citizens),
            AgentsCatalog.buildAgentTypes(monsters),
            AgentsCatalog.buildAgentTypes(villains)
        ]);
    }

    generate(hex: Hex, random: Random) {
        const candidates = Object.fromEntries(Object.entries(this._entries)
            .filter(([, distributed]) => distributed.distribution[hex.type])
            .map(([type, distributed]) => {
                return [type, distributed.distribution[hex.type] ?? 0];
            }));
        const type = random.getFromDistribution(candidates);

        return type ? {...this._entries[type], type} : undefined;
    }
    getPlayerType() {
        return this._entries.hero;
    }

    getStalkersDistribution(game: Game) {
        return Object.fromEntries(Object.entries(this._entries)
            .filter(([, details]) => details.stalking &&
                (details.stalking.from === undefined || game.turn >= details.stalking.from) &&
                (details.stalking.to === undefined || game.turn <= details.stalking.to)
            ).map(([type, details]) => ([type, details.stalking?.likelihood ?? 0]))
        );
    }
}