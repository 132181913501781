import React from "react";
import {Alert, Dialog} from "@mui/material";

interface IError {
    error?: Error
}
export function ErrorDialog({error}: IError) {
    const styles = {
        body: {
            margin: 1
        },
        dialog: {
            borderRadius: 2
        },
        paragraph: {
            display: "flex"
        }
    }
    if (error) {
        console.error(error.message);
        console.error(error.stack);
    }

    return (
        <Dialog open={error !== undefined} sx={styles.dialog}>
            <Alert severity="error">
                {error?.message}
            </Alert>
        </Dialog>
    );
}