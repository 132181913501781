import React, {MouseEventHandler, TouchEventHandler, useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {HexView} from "./HexView";
import {Game} from "../../game/game";
import {DebugContext} from "../debug/DebugProvider";
import {Agent} from "../../game/agents/agent";
import {Hex} from "../../game/map/hex";
import {Vector2} from "../../utility/vector2";
import {HexContents} from "./HexContents";
import {Place} from "../../game/places/place";
import {MapSize} from "./mapSize";
import {RadialInterface} from "./RadialInterface";
import {Bounds2} from "../../utility/bounds2";
import {usePrevious} from "../../utility/usePrevious";
import {connect} from "react-redux";
import {IMap, update as updateMap} from "../../redux/slices/map";
import {Update} from "../../redux/slices/base";
import {selectMap} from "../../redux/selectors/map";

const DRAG_RANGE = 100;
const hexColors: {[type: string]: string} = {
    desert: "#CC6",
    forests: "#363",
    grasslands: "#6A6",
    hills: "#484",
    ice: "#CCF",
    jungle: "#364",
    mesas: "#644",
    mountains: "#666",
    ocean: "#039",
    rainforest: "#253",
    savannah: "#AC6",
    sea: "#36C",
    tundra: "#9C6"
};
const defaultHexColor = "#333";

interface IHexes {
    game: Game
    map: IMap
    mapOffset: Vector2
    mapSize: MapSize
    onDragAgent: (position: Vector2) => Promise<boolean>
    onDragPlace: (position: Vector2) => Promise<boolean>
    updateMap: Update
}
function MapView({game, map, mapOffset, mapSize, onDragAgent, onDragPlace, updateMap}: IHexes) {
    const debug = useContext(DebugContext);
    const [draggingAgent, setDraggingAgent] = useState<Agent>();
    const [draggingPlace, setDraggingPlace] = useState<Place>();
    const [interactionStart, setInteractionStart] = useState(Vector2.zero);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showCoords, setShowCoords] = useState(Boolean(debug.getDebugValue("HexCoordinatesVisible")));
    const [visibleHexes, setVisibleHexes] = useState<Hex[]>([]);
    const [hexBounds, setHexBounds] = useState([new Bounds2(Vector2.zero, mapSize.MapSize)]);
    const style = {
        canvas: {
            height: "100%",
            width: "100%"
        },
        coordinates: {
            color: "white",
            fontSize: {xs: "2em", md: "1.2em"},
            fontWeight: "bold",
            opacity: 0.6
        },
        coordinatesContainer: {
            pointerEvents: "none",
            position: "absolute",
            left: mapSize.HexWidth * 0.1,
            top: mapSize.HexHeight * 0.25
        }
    };
    const getHexPosition = (position: Vector2) => {
        const hex = game.getHex(position);
        const height = Math.round(mapSize.HexHeight);
        const oddLine = Math.abs(hex.position.y) % 2;
        const offset = (mapSize.HalfHexWidth + mapSize.HexMargin) * oddLine;
        const mapWidth = mapSize.MapSize.x * (mapSize.HexWidth + mapSize.HexMargin);
        let left = hex.position.x * (mapSize.HexWidth + mapSize.HexMargin) - offset;

        while (left + mapOffset.x <= 0) {
            left += mapWidth;
        }
        while (left + mapOffset.x > mapWidth) {
            left -= mapWidth;
        }
        return new Vector2(left, Math.floor(hex.position.y * (height - mapSize.HalfHexEdge)));
    }
    const handleAgentInteractionStart = (agent: Agent, position: Vector2) => {
        if (agent.key === map.selected.agent) {
            setDraggingAgent(agent);
        }
        setInteractionStart(position);
    };
    const handleAgentInteractionFinish = (agent: Agent, position: Vector2) => {
        if (position.minus(interactionStart).lengthSquared() < DRAG_RANGE) {
            updateMap({
                update: agent.key,
                store: ["selected", "agent"]
            });
            updateMap({
                update: undefined,
                store: ["selected", "place"]
            });
            setDraggingAgent(undefined);
        }
    }
    const handleHexInteractionStart = (position: Vector2) => {
        setInteractionStart(position);
    };
    const handleHexInteractionFinish = (hex: Hex) => (position: Vector2) => {
        if (position.minus(interactionStart).lengthSquared() < DRAG_RANGE) {
            if (map.selected.agent && ! game.getAgents(hex).find(agent => agent.key === map.selected.agent)) {
                updateMap({
                    update: undefined,
                    store: ["selected", "agent"]
                });
            }
            if (map.selected.place && game.getPlace(hex)?.key !== map.selected.place) {
                updateMap({
                    update: undefined,
                    store: ["selected", "place"]
                });
            }
            updateMap({
                update: hex.position.serialize(),
                store: ["selected", "hex"]
            });
        }
    }
    const handlePlaceInteractionStart = (place: Place, position: Vector2) => {
        if (place.key === map.selected.place) {
            setDraggingPlace(place);
        }
        setInteractionStart(position);
    };
    const handlePlaceInteractionFinish = (place: Place, position: Vector2) => {
        if (position.minus(interactionStart).lengthSquared() < DRAG_RANGE) {
            updateMap({
                update: place.key,
                store: ["selected", "place"]
            });
            updateMap({
                update: undefined,
                store: ["selected", "agent"]
            });
            setDraggingPlace(undefined);
        }
    }
    const handleMouseDown: MouseEventHandler = ({button, pageX, pageY}) => {
        if (button === 0) {
            setInteractionStart(new Vector2(pageX, pageY));
        }
    };
    const handleMapInteractionFinish = (position: Vector2) => {
        if (position.minus(interactionStart).lengthSquared() >= DRAG_RANGE) {
            if (draggingAgent) {
                const hexPosition = mapSize.positionToHex(position.minus(mapOffset));

                onDragAgent(hexPosition).then(moved => {
                    if (moved) {
                        updateMap({
                            update: hexPosition.serialize(),
                            store: ["selected", "hex"]
                        });
                    }
                });
                setDraggingAgent(undefined);
            } else if (draggingPlace) {
                const hexPosition = mapSize.positionToHex(position.minus(mapOffset));

                onDragPlace(hexPosition).then(moved => {
                    if (moved) {
                        updateMap({
                            update: hexPosition.serialize(),
                            store: ["selected", "hex"]
                        });
                    }
                });
                setDraggingPlace(undefined);
            }
        }
    };
    const handleMouseMove: MouseEventHandler = event => {
        event.preventDefault();
    }
    const handleMouseUp: MouseEventHandler = ({button, pageX, pageY}) => {
        if (button === 0) {
            handleMapInteractionFinish(new Vector2(pageX, pageY));
        }
    };
    const handleTouchEnd: TouchEventHandler = ({changedTouches}) => {
        if (changedTouches.length === 1) {
            handleMapInteractionFinish(new Vector2(changedTouches[0].pageX, changedTouches[0].pageY));
        }
    }
    const handleTouchStart: TouchEventHandler = ({touches}) => {
        if (touches.length === 1) {
            setInteractionStart(new Vector2(touches[0].pageX, touches[0].pageY));
        }
    }
    const playerHex = game.player?.hex.key;
    const previousHex = usePrevious(playerHex);
    const previousBounds = usePrevious(hexBounds);

    useEffect(() => {
        const handleUpdate = () => {
            if (map.selected.agent && map.selected.hex) {
                const selectedAgent = game.getAgentFromKey(map.selected.agent);

                if (selectedAgent) {
                    const agentHex = selectedAgent.hex.position.serialize();

                    if (agentHex[0] !== map.selected.hex[0] || agentHex[1] !== map.selected.hex[1]) {
                        updateMap({update: agentHex, store: ["selected", "hex"]});
                    }
                }
            }
        }

        if (game) {
            game.updateDelegates.add(handleUpdate);
            return () => game.updateDelegates.remove(handleUpdate);
        }
    }, [game, map, updateMap]);
    useEffect(() => {
        const handleHexCoordinatesVisible = (visible: boolean) => setShowCoords(visible);

        debug.addUpdateValueDelegates("HexCoordinatesVisible", handleHexCoordinatesVisible);
        return () => debug.removeUpdateValueDelegates("HexCoordinatesVisible", handleHexCoordinatesVisible);
    }, [debug]);
    useEffect(() => {
        if (playerHex !== previousHex ||
            (! previousBounds || previousBounds.length !== hexBounds.length ||
                previousBounds.some((bounds, index) => ! hexBounds[index].equals(bounds)))) {
            setVisibleHexes(game.playerController.getHexesKnown(game.terrain));
        }
    }, [game.playerController, game.terrain, hexBounds, previousBounds, playerHex, previousHex]);
    useEffect(() => {
        if (map) {
            const viewDifference = new Vector2(20, 0);
            const viewOffset = mapOffset.plus(viewDifference);
            const viewport = new Vector2(Math.ceil(window.innerWidth / mapSize.HexWidth) + 1,
                Math.ceil(window.innerHeight / mapSize.HexWidth) + 1);
            const offset = new Vector2(Math.floor(- (viewOffset.x / mapSize.HexWidth)),
                Math.floor(- viewOffset.y / mapSize.HexWidth));

            if (offset.x < - viewport.x) {
                // Entirely wrapped
                const wrappedOffset = new Vector2(offset.x + mapSize.MapSize.x, offset.y);

                setHexBounds([new Bounds2(wrappedOffset, wrappedOffset.plus(viewport))]);
            } else if (offset.x < 0 || offset.x > mapSize.MapSize.x - viewport.x) {
                // Wrapped across zero latitude
                const maxY = offset.y + viewport.y;

                setHexBounds([
                    new Bounds2(new Vector2((offset.x + mapSize.MapSize.x) % mapSize.MapSize.x, offset.y),
                        new Vector2(mapSize.MapSize.x, maxY)),
                    new Bounds2(new Vector2(0, offset.y),
                        new Vector2((offset.x + viewport.x) % mapSize.MapSize.x, maxY))
                ]);
            } else {
                setHexBounds([new Bounds2(offset, viewport.plus(offset))]);
            }
        }
    }, [mapOffset, mapSize, map, map.turn]);
    useEffect(() => {
        const handleResize = () =>
            setScreenWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <Box sx={style.canvas} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}
             onTouchEnd={handleTouchEnd} onTouchStart={handleTouchStart}>
            {visibleHexes.map((hex, index) => {
                const visible = hexBounds.some(bounds => bounds.contains(hex.position));

                return (
                    <Box key={hex.key} display={visible ? "block": "none"}>
                        <HexView key={index} options={{
                            size: mapSize,
                            color: hexColors[hex.type] ?? defaultHexColor,
                            type: hex.type
                        }} hex={hex}
                                 onInteractionStart={handleHexInteractionStart}
                                 onInteractionFinish={handleHexInteractionFinish(hex)}
                                 screenWidth={screenWidth}
                                 selected={!!map.selected.hex && hex.position.equals(new Vector2(map.selected.hex))}
                                 mapOffset={mapOffset} visible={visible}>
                            <>
                                <HexContents game={game} hex={hex}
                                             onAgentInteractionStart={handleAgentInteractionStart}
                                             onAgentInteractionFinish={handleAgentInteractionFinish}
                                             onPlaceInteractionStart={handlePlaceInteractionStart}
                                             onPlaceInteractionFinish={handlePlaceInteractionFinish}/>
                                {showCoords &&
                                    <Box sx={style.coordinatesContainer}>
                                        <Typography sx={style.coordinates}>
                                            {hex.position.x}, {hex.position.y}
                                        </Typography>
                                    </Box>
                                }
                            </>
                        </HexView>
                    </Box>
                );
            })}
            {map?.selected.hex &&
                <RadialInterface dragging={Boolean(draggingAgent || draggingPlace)} game={game}
                                 position={getHexPosition(new Vector2(map.selected.hex))}
                                 size={new Vector2(mapSize.HexWidth, mapSize.HexHeight)}/>
            }
        </Box>
    );
}

const mapStateToProps = (store: never) => ({
    map: selectMap(store)
});
const connected = connect(mapStateToProps, {
    updateMap
})(MapView);

export {connected as MapView}
