import {Catalog} from "../catalog/catalog";
import achievements from "../../data/catalogs/achievements/achievements.json";
import {Achievement, IAchievement, Progress} from "./achievement";
import {GameEvent} from "./gameEvent";
import {AchievementAccumulate} from "./achievementAccumulate";
import {AchievementCount} from "./achievementCount";

export class Achievements {
    private _achievements: Achievement[] = [];
    private _catalog = new Catalog<IAchievement>([achievements]);

    constructor() {
        this._achievements = this._catalog.getTypes().map(type => this.create(type, {}))
            .filter(Boolean) as Achievement[];
    }
    
    reinitialise() {
        this._achievements.forEach(achievement =>
            achievement.completed || ! achievement.game || achievement.reinitialise())
    }
    serialiseIn(data: Record<string, Progress>) {
        this._achievements = this._catalog.getTypes().map(type => this.create(type, data[type]))
            .filter(Boolean) as Achievement[];
    }
    
    serialiseOut(): Record<string, Progress> {
        return Object.fromEntries(this._achievements.map(achievement =>
            ([achievement.type, achievement.serialiseOut()])));
    }
    
    test(event: GameEvent) {
        return this._achievements
            // Don't test achievements we have completed
            .filter(achievement => ! achievement.completed)
            .filter(achievement => achievement.test(event));
    }

    private create(type: string, progress: Progress) {
        const achievement = this._catalog.get(type);

        if (achievement) {
            switch (achievement.achievement) {
                case "accumulate":
                    return new AchievementAccumulate(achievement, progress);
                case "count":
                    return new AchievementCount(achievement, progress);
                case "achievement":
                    return new Achievement(achievement, progress);
            }
        }
    }
}