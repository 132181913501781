import {Controller, IController, IControllerSave, IReward} from "./controller";
import {Game} from "../game";
import {Agent, IFeast, IVictory} from "../agents/agent";
import {Place} from "../places/place";

type EventData = IVictory | IFeast
interface IEvent {
    data: EventData
    turn: number
    type: string
}

export interface IPlayerControllerSave extends IControllerSave {
    history: IEvent[]
}

export class PlayerController extends Controller {

    private readonly _history: IEvent[] = [];

    constructor(controller: IController, seed: number, key?: number, history: IEvent[] = []) {
        super(controller, "players", true, seed, key);
    }

    static serialiseIn(controller: IController, save: IPlayerControllerSave) {
        return new PlayerController({
            ...controller,
            enemies: save.enemies,
            friends: save.friends,
            knowledge: save.knowledge,
            skills: save.skills
        }, save.random, save.key, save.history);
    }

    serialiseOut(): IPlayerControllerSave {
        return {
            ...super.serialiseOut(),
            history: this._history
        };
    }

    get history() {
        return this._history;
    }

    get playerAgent() {
        return this._agents[0];
    }

    claimReward(agent: Agent, place: Place) {
        const reward = place.claimReward();

        if (reward) {
            const owned = agent.equipment.find(equipment => equipment.type === reward);

            if (owned) {
                owned.repairItem();
            } else {
                agent.addEquipment(reward);
            }
        }
    }

    record(type: string, turn: number, data: EventData) {
        this._history.push({type, turn, data});
    }

    recordFeast(place: Place, game: Game) {
        this.record("feast", game.turn, {
            place: place.key.toString(),
            reward: place.reward || ""
        });
    }

    recordVictory(loser: Agent, game: Game, reward: IReward) {
        this.record("victory", game.turn, {
            reward,
            type: loser.type
        });
    }

    resolve(game: Game) {
        console.error("Probably do not intend to drive player agents without player input")
        return Promise.resolve(false);
    }
}