import {Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, useTheme} from "@mui/material";
import {Localisation} from "../localisation/localisation";
import React, {ChangeEventHandler, startTransition, useContext, useEffect, useState} from "react";
import {Game} from "../../game/game";
import {DebugDialog} from "./DebugDialog";
import {DebugContext} from "../debug/DebugProvider";

const SEED_MAX = 9999999999;
const JSON_PREPEND = "data:text/json;charset=utf-8,";

interface IGameDialog {
    game: Game
    onClose: () => void
    open: boolean
}
export function GameDialog({game, onClose, open}: IGameDialog) {
    const debug = useContext(DebugContext);
    const [debugClicks, setDebugClicks] = useState(0);
    const getRandomSeed = () => Math.floor(game.random.get() * SEED_MAX);
    const [seed, setSeed] = useState(getRandomSeed());
    const [,setDebugUpdated] = useState<any>();
    const theme = useTheme();
    const styles = {
        button: {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
            marginLeft: 1,
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
            }
        },
        input: {
            flexGrow: 1
        },
        log: {
            height: 100,
            overflowY: "scroll"
        },
        row: {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 1,
            marginTop: 1
        },
        seed: {
            entered: {
                display: "flex"
            }
        }
    };
    const handleClose = () => {
        onClose();
        setSeed(getRandomSeed());
    }
    const handleDebugClick = () => {
        setTimeout(() => {
            setDebugClicks(debugClicks - 1);
        }, 3500);

        setDebugClicks(debugClicks + 1);
        if (debugClicks >= 6) {
            debug.setVisible(! debug.getVisible());
        }
    }
    const handleExport = () => {
        const save = localStorage.getItem("save");
        
        if (save) {
            const link = document.createElement("a");

            link.href = `${JSON_PREPEND}${encodeURIComponent(save)}`;
            link.download = "save.json";
            link.click();
        }
    };
    const handleImport:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        if (target.files) {
            const fileReader = new FileReader();

            fileReader.onload = (({target}) => {
                if (target && target.result) {
                    localStorage.setItem("save", target.result as string);
                    game.load();
                }
            });
            fileReader.readAsText(target.files[0]);
            onClose();
        }
    };
    const handleRestart = () => {
        game.restart(seed);
        onClose();
        setSeed(getRandomSeed());
    }
    const handleSeedChange: ChangeEventHandler<HTMLInputElement> = ({target}) =>
        setSeed(Number.parseInt(target.value));

    useEffect(() => {
        const handleDebugUpdate = () => {
            startTransition(() => setDebugUpdated({}));
        };

        debug.addUpdateDelegate(handleDebugUpdate);
        return () => debug.removeUpdateDelegate(handleDebugUpdate);
    }, [debug])
    return (
        <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
            <DialogTitle>{Localisation.GetText("titleGameMenu")}</DialogTitle>
            <DialogContent>
                <Box sx={styles.row}>
                    <TextField label={Localisation.GetText("Seed")} onChange={handleSeedChange}
                               size="small" sx={styles.input} value={seed}/>
                    <Button onClick={handleRestart} sx={styles.button}>
                        {Localisation.GetText("Restart")}
                    </Button>
                </Box>
                <Divider/>
                <Box sx={styles.row}>
                    <Box flexGrow={1} onClick={handleDebugClick}/>
                    <Button onClick={handleExport} sx={styles.button}>{Localisation.GetText("Export")}</Button>
                    <Button component="label" sx={styles.button}>
                        {Localisation.GetText("Import")}
                        <input accept="text/json" hidden id="import-file" onChange={handleImport}  type="file"/>
                    </Button>
                </Box>
                {debug.getVisible() && <DebugDialog debug={debug}/>}
            </DialogContent>
        </Dialog>
    );
}
