import {IRequirement, Requirement} from "../requirements/requirement";
import {GameEvent} from "./gameEvent";
import {ICatalogEntry} from "../catalog/catalogEntry";
import {RequirementsFactory} from "../requirements/requirementsFactory";

export type Progress = Record<string, number> | true

export interface IAchievement
{
    achievement: string
    requirement: IRequirement
    description: string
    event: string
    game: boolean
    name: string
}

export class Achievement {
    protected _props: IAchievement & ICatalogEntry;
    protected _requirement: Requirement;

    constructor(props: IAchievement, protected _progress: Progress = {}) {
        this._props = props as IAchievement & ICatalogEntry;
        this._requirement = RequirementsFactory.Create(props.requirement)
    }

    get completed() {
        return this._progress === true;
    }
    
    get description() {
        return this._props.description;
    }

    get game() {
        return this._props.game;
    }

    get name() {
        return this._props.name;
    }

    get type() {
        return this._props.type;
    }

    getProperty(event: GameEvent, path: string[] | string) {
        if (path instanceof Array) {
            return path.reduce((property, member) => {
                return property ? property[member] : undefined;
            }, event.properties);
        }
        return event.properties[path];
    }

    reinitialise() {
        this._progress = {};
    }

    test(event: GameEvent): boolean {
        if (this._progress !== true && event.type === this._props.event) {
            this.update(event);
            if (this._requirement.test({
                event: event.properties,
                progress: this._progress
            })) {
                this._progress = true;
            }
        }
        return this._progress === true;
    }

    serialiseOut() {
        return this._progress;
    }

    // Override this to update progress on receiving relevant event 
    protected update(event: GameEvent): void {
    }
}