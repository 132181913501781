import {Agent} from "../agents/agent";
import {Card} from "./card";
import {Fight} from "./fight";
import effects from "../../data/catalogs/cards/effects.json"
import {Game} from "../game";

interface IEffect {
    damage: number
    prevents: string[]
}

interface ICompetitor {
    agent: Agent
    defended?: Agent
    fight: Fight
    game: Game
}

export class Competitor {
    private readonly _cards: Card[];
    private readonly _effects: { [effect: string]: IEffect };

    private _blocking: Record<string, number> = {};
    private _damage: Record<string, number> = {};
    private _discarded: Card[] = [];
    private _hand: Card[] = [];
    private _magic: Record<string, number> = {}
    private _passed = 0;
    private _prevented: Record<string, number> = {};
    private _retreated = false;

    private _hearts: number;
    private _played?: Card;

    constructor(private _props: ICompetitor) {
        this._cards = this._props.fight.shuffle(this._props.agent.getCards(this._props.game));
        this._effects = effects;
        this._hearts = this._props.agent.health;
        this.dealHand();
    }

    get agent() {
        return this._props.agent;
    }

    get defended() {
        return this._props.defended;
    }

    get hand() {
        return this._hand;
    }

    get hearts() {
        return this._hearts;
    }

    get maxHearts() {
        return this._props.agent.health;
    }

    get played() {
        return this._played;
    }

    get prevented() {
        return this._prevented;
    }
    
    get retreated() {
        return this._retreated;
    }

    prevent(prevented: Record<string, number>) {
        Object.entries(prevented).forEach(([card, duration]) => {
            if (this._prevented[card] === undefined || this._prevented[card] < duration) {
                this._prevented[card] = duration;
            }
        })
    }

    advance() {
        const reduceDuration = (state: Record<string, number>) =>
            Object.fromEntries(Object.entries(state)
                .map(([key, duration]) => ([key, --duration]))
                .filter(([, duration]) => duration > 0))
            
        this._magic = reduceDuration(this._magic);
        this._prevented = reduceDuration(this._prevented);
        this._blocking = {};
    }

    block(blocking: Record<string, number>) {
        this._blocking = blocking;
    }

    damage(damage: Record<string, number>) {
        this._damage = damage;
    }

    deckSize() {
        return this._cards.length;
    }

    discardedSize() {
        return this._discarded.length;
    }

    getHandSize() {
        return this._hand.length;
    }

    getMagic(type: string) {
        return this._magic[type] || 0;
    }

    getPass() {
        return this._props.agent.controller.constraints.pass - this._passed;
    }

    heal(hearts: number) {
        this._hearts += hearts;
    }

    pass (card: Card) {
        const index = this.hand.indexOf(card);

        if (index !== -1 && this.hand.length > 1 && this.getPass() > 0) {
            this._passed += 1;
            this._hand.splice(index, 1);
            this._discarded.push(card);
        }
    }

    peek(fight: Fight) {
        return this._props.agent.controller.constraints.peekDeck ?
            this.deal(this._props.agent.controller.constraints.peekDeck) : [];
    }

    peekEnemy() {
        return Math.max(this.getMagic("prescient") ? 1 : 0,
            this._props.agent.controller.constraints.peekEnemyHand);
    }

    play(fight: Fight, card?: Card) {
        const other = fight.attacker === this ? fight.defender : fight.attacker;

        if (!card) {
            card = this.selectCard();
        }
        if (this.discard(card)) {
            const prevented = this._prevented[card.type];

            this.advance();
            if (! prevented) {
                card.play(this, other);
            }
            this.dealHand();
            this._passed = 0;
            this._played = card;
            this._discarded.push(card);
            return true;
        }
        return false;
    }

    resolve() {
        Object.entries(this._damage).forEach(([type, damage]) => {
            if (this._blocking[type]) {
                this.damageAgent(type, damage - this._blocking[type]);
            } else {
                this.damageAgent(type, damage);
            }
        });
        this._damage = {};
        this._blocking = {};
        return this._hearts <= 0 || this._retreated;
    }
    
    retreat() {
        this._retreated = true;
    }

    setMagic(type: string, duration: number) {
        if (! this._magic[type]) {
            this._magic[type] = 0;
        }
        this._magic[type] = Math.max(this._magic[type], duration);
    }

    setPrevented(prevented: Record<string, number>) {
        Object.entries(prevented).forEach(([card, duration]) => {
            if (this._prevented[card] === undefined || this._prevented[card] < duration) {
                this._prevented[card] = duration;
            }
        });
    }

    private damageAgent(type: string, damage: number) {
        if (damage > 0) {
            const armour = this.agent.getEquippedItem("armour");

            if (armour && armour.damage < armour.resilience) {
                armour.damageItem(damage);
            } else if (this._props.defended) {
                this._props.game.removeAgent(this._props.defended);
                this._props.defended = undefined;
            } else {
                this._hearts -= damage;
            }
        }
    }

    private deal(count: number) {
        if (this._cards.length < count && this._discarded.length > 0) {
            this.shuffle();
        }
        return count ? this._cards.slice(-count) : [];
    }

    private dealHand() {
        const newCards = this.deal(this._props.agent.controller.constraints.hand - this._hand.length);

        this._hand = [...newCards, ...this._hand];
        this._cards.splice(-newCards.length, newCards.length);
        return this._hand;
    }

    private discard(card: Card) {
        const index = this._hand.indexOf(card);

        if (index >= 0) {
            return this._hand.splice(index, 1);
        }
        return undefined;
    }
    private selectCard() {
        if (this.getHandSize() === 1) {
            return this.hand[0];
        } else {
            return this.hand[Math.floor(this._props.game.random.get() * this.getHandSize())];
        }
    }

    private shuffle() {
        this._cards.unshift(...this._props.fight.shuffle(this._discarded));
        this._discarded.length = 0;
        return this._cards;
    }
/*

    private update(update: IState) {
        if (!this.states.find(state => (this._effects[state.name].prevents as string[]).includes(update.name))) {
            // No state preventing this new state
            const state = this.states.find(state => state.name === update.name);

            if (state) {
                // Already had the state so extend if necessary
                state.duration = Math.max(state.duration, update.duration);
            } else {
                this._states.push({...update});
            }
        }
    }
*/
}