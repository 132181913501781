import {Competitor} from "../../game/fight/competitor";
import {Box, Divider, Fade, Slide, Typography, useTheme} from "@mui/material";
import {CardView, GetCardVisual} from "./CardView";
import {GiAttachedShield, GiCardDiscard, GiCardDraw, GiHearts, GiOldWagon, GiShoulderArmor, GiSwordBrandish}
    from "react-icons/gi";
import {Fight} from "../../game/fight/fight";
import React, {useContext, useEffect, useRef, useState} from "react";
import {TutorialTooltip} from "../help/TutorialTooltip";
import {HandView} from "./HandView";
import {OldHandView} from "./OldHandView";
import {DebugContext} from "../debug/DebugProvider";

interface ICompetitorView {
    attacker: boolean
    competitor: Competitor
    fight: Fight
    player: Competitor
}

export function CompetitorView({attacker, competitor, fight, player}: ICompetitorView) {
    const [showCards, setShowCards] = useState(true);
    const handRef = useRef(null);
    const heartsRef = useRef(null);
    const outcomeRef = useRef(null);
    const peekRef = useRef(null);
    const theme = useTheme();
    const styles = {
        agent: {
            marginTop: 1,
            display: "flex"
        },
        attacker: {
            flexGrow: 1
        },
        button: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 0
        },
        card: {
            color: theme.fight.color,
            display: "flex",
            marginTop: 1,
        },
        content: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: 1,
            width: "50%"
        },
        deck: {
            justifyContent: "space-between",
            display: "flex"
        },
        deckItem: {
            alignItems: "center",
            display: "flex"
        },
        hand: {
            flexGrow: 1,
            marginBottom: 1
        },
        hearts: {
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-end"
        },
        heartsSection: {
            whiteSpace: "nowrap"
        },
        opponent: {
            marginLeft: 1
        },
        outcome: {
            height: theme.fight.icon.size,
            marginBottom: 1,
            marginTop: 1,
        },
        peek: {
            display: "flex",
            height: theme.fight.icon.size,
            marginBottom: 1,
            marginTop: 1
        },
        peekCard: {
            margin: "1px"
        },
        player: {
        },
        state: {
            opacity: 0.5
        },
        states: {
            display: "flex"
        }
    };
    const peek = competitor.peek(fight);
    const armourResilience = competitor.agent.getEquippedItem("armour")?.resilience ?? 0;
    const armourDamage = competitor.agent.getEquippedItem("armour")?.damage ?? 0;
    const heartsDamage = competitor.maxHearts - competitor.hearts;
    const tutorialPrefix = competitor === player ? "Player" : "Monster";
    const debug = useContext(DebugContext);

    useEffect(() => {
        setShowCards(false);
        setTimeout(() => setShowCards(true), 50);
    }, [competitor.hand]);
    return (
        <Box sx={{...styles.content, ...competitor.agent.controller.player ? styles.player : styles.opponent}}>
            <Box sx={styles.deck}>
`                <TutorialTooltip title={`${tutorialPrefix}Deck`} next={`${tutorialPrefix}Discard`}>
                    <Box sx={styles.deckItem}>
                        <GiCardDraw {...theme.fight.icon}/>
                        <Typography sx={theme.fight}>{competitor.deckSize()}</Typography>
                    </Box>
                </TutorialTooltip>
                <TutorialTooltip title={`${tutorialPrefix}Discard`} next={`${tutorialPrefix}AttackerDefender`}>
                    <Box sx={styles.deckItem}>
                        <GiCardDiscard {...theme.fight.icon}/>
                        <Typography sx={theme.fight}>{competitor.discardedSize()}</Typography>
                    </Box>
                </TutorialTooltip>
            </Box>
            <Divider/>
            <Box sx={styles.agent}>
                <Box sx={styles.attacker} title={attacker ? "Attacker" : "Defender"}>
                    <TutorialTooltip title={`${tutorialPrefix}AttackerDefender`} next={`${tutorialPrefix}Prevented`}>
                        <span>
                        {attacker ?
                            <GiSwordBrandish {...theme.fight.icon}/> : <GiAttachedShield {...theme.fight.icon}/>
                        }
                        </span>
                    </TutorialTooltip>
                </Box>
                <TutorialTooltip enabled={Object.keys(competitor.prevented).length > 0}
                                 title={`${tutorialPrefix}Prevented`} next={`${tutorialPrefix}Armour`}>
                    <Box sx={styles.states}>
                        {Object.keys(competitor.prevented)
                            .map((card, index) =>
                                <Box key={index} sx={styles.state} title={card}>
                                    {GetCardVisual(card, theme.fight.icon)}
                                </Box>
                            )}
                    </Box>
                </TutorialTooltip>
                <Box ref={heartsRef} sx={styles.hearts}>
                    <TutorialTooltip enabled={Boolean(armourResilience)} title={`${tutorialPrefix}Armour`}
                                     next={`${tutorialPrefix}Defended`}>
                        <Box component="span" sx={styles.heartsSection}>
                            {[...new Array(armourResilience)].map((_, index) =>
                                <Fade in={index >= armourDamage} key={index}>
                                    <Box component="span">
                                        <GiShoulderArmor {...theme.fight.icon}/>
                                    </Box>
                                </Fade>
                            )}
                        </Box>
                    </TutorialTooltip>
                    <TutorialTooltip enabled={Boolean(competitor.defended)} title={`${tutorialPrefix}Defended`}
                                     next={`${tutorialPrefix}Health`}>
                        <Box component="span" sx={styles.heartsSection}>
                            {competitor.defended &&
                                <Fade in={Boolean(competitor.defended)}>
                                    <Box component="span">
                                        <GiOldWagon {...theme.fight.icon}/>
                                    </Box>
                                </Fade>}
                        </Box>
                    </TutorialTooltip>
                    <TutorialTooltip title={`${tutorialPrefix}Health`} next={`${tutorialPrefix}Peek`}>
                        <Box component="span" sx={styles.heartsSection}>
                            {[...new Array(competitor.maxHearts)].map((_, index) =>
                                <Fade in={index >= heartsDamage} key={index}>
                                    <Box component="span">
                                        <GiHearts {...theme.fight.icon}/>
                                    </Box>
                                </Fade>
                            )}
                        </Box>
                    </TutorialTooltip>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.peek} ref={peekRef}>
                <TutorialTooltip enabled={Boolean(player === competitor && peek.length)}
                                 title={`${tutorialPrefix}Peek`} next={`${tutorialPrefix}Hand`}>
                    <span>
                        {player === competitor &&
                            peek.map((card, index) =>
                                <Box key={index} sx={styles.peekCard}>
                                    <Slide container={peekRef.current} direction="down" exit={false} in={showCards}>
                                        <Box><CardView card={card}/></Box>
                                    </Slide>
                                </Box>)
                        }
                    </span>
                </TutorialTooltip>
            </Box>
            <Divider/>
            <TutorialTooltip title={`${tutorialPrefix}Hand`} next={`${tutorialPrefix}Card0`} placement="top">
                <Box ref={handRef} sx={styles.hand}>
                    {debug.getDebugValue("NewHandView") ?
                        player === competitor && <HandView fight={fight} player={player}/> :
                        <OldHandView competitor={competitor} fight={fight} player={player}/>
                    }
                </Box>
            </TutorialTooltip>
            <Divider/>
            <TutorialTooltip next={competitor === player ? "MonsterDeck" : undefined} placement="top"
                             title={`${tutorialPrefix}Played`}>
                <Box ref={outcomeRef} sx={styles.outcome}>
                    {competitor.played &&
                        <Slide container={outcomeRef.current} direction="down" exit={false} in={showCards}>
                            <Box><CardView card={competitor.played}/></Box>
                        </Slide>
                    }
                </Box>
            </TutorialTooltip>
        </Box>
    );
}