import {Terrain} from "./map/terrain";
import {AgentsCatalog, IAgentType, IArchetype} from "./agents/agentsCatalog";
import {Agent, IAgentSave} from "./agents/agent";
import {Hex} from "./map/hex";
import {Vector2} from "../utility/vector2";
import {Controller, IController, IControllerSave, IReward} from "./controllers/controller";
import {IPlayerControllerSave, PlayerController} from "./controllers/playerController";
import {MonsterController} from "./controllers/monsterController";
import {Fight} from "./fight/fight";
import {IPlaceSave, Place} from "./places/place";
import {PlacesCatalog} from "./places/placesCatalog";
import {IControlled} from "./controllers/controlled";
import factions from "../data/catalogs/factions/factions.json";
import {AssetsCatalog} from "./assets/assetsCatalog";
import {Random} from "../utility/random";
import {MapSize} from "../components/map/mapSize";
import {ResourcesCatalog} from "./map/resourcesCatalog.";
import {TerrainsCatalog} from "./map/terrainsCatalog";
import {store} from "../redux/store";
import {update as updateMap} from "../redux/slices/map";
import {Achievements} from "./achievements/achievements";
import {Progress} from "./achievements/achievement";
import {GameEvent} from "./achievements/gameEvent";

const GAME_STATES = ["exploring", "fighting", "lost"] as const;
type GameState = typeof GAME_STATES[number];

type UpdateDelegate = (event: any) => void

interface IFaction {
    controller: IController
}

interface IProps {
    mapSize: MapSize
    seed?: number
}

interface ISave {
    achievements: Record<string, Progress>
    game: IGameSave
}
interface IGameSave {
    agents: IAgentSave[]
    controllers: IControllerSave[]
    mapSize: MapSize
    places: IPlaceSave[]
    playerController: IPlayerControllerSave
    playerHex: Vector2
    terrainSeed: number
    seed: number
    state: GameState
    turn: number
}

export class Game {
    private readonly _agentsCatalog = new AgentsCatalog();
    private readonly _assetsCatalog = new AssetsCatalog();
    private readonly _factions: Record<string, IFaction>;
    private readonly _mapSize: MapSize;
    private readonly _placesCatalog = new PlacesCatalog();
    private readonly _resourcesCatalog = new ResourcesCatalog();
    private readonly _terrainsCatalog = new TerrainsCatalog();
    private readonly _updateDelegates: UpdateDelegate[] = [];

    private _achievements: Achievements
    private _activeController: Controller
    private _playerController!: PlayerController
    private _fight?: Fight;
    private _random!: Random;
    private _terrain!: Terrain;

    private _controllers: MonsterController[] = [];
    private _state: GameState = "exploring";
    private _agents: Agent[] = [];
    private _places: Place[] = [];

    // Public so that react can depend on it
    public turn: number = 0;

    constructor(props: IProps) {
        const save = localStorage.getItem("save");

        this._achievements = new Achievements();
        this._factions = factions;
        this._mapSize = props.mapSize;
        if (save) {
            try {
                this.serialiseIn(save);
            } catch (error: any) {
                console.error(error.message);
                console.log("Failed to load from save game, starting new game");
                this.initialise(props.mapSize);
                this.save();
            }
        } else {
            this.initialise(props.mapSize);
            this.save();
        }
        this._activeController = this._playerController;
        // Update the network
        this.places.forEach(place => place.updateNetwork(this));
    }

    get actions() {
        return {
            moveAgent: (agent: Agent, hex: Hex) => {
                if (this.activeController.controls(agent)) {
                    const from = {
                        place: this.getPlace(agent.hex)?.type || "",
                        terrain: agent.hex.type,
                        x: agent.hex.position.x,
                        y: agent.hex.position.y
                    };

                    if (this.activeController.moveAgent(agent, hex, this)) {
                        this.post(new GameEvent("PlayerMoved", {
                            from,
                            to: {
                                place: this.getPlace(hex)?.type || "",
                                terrain: hex.type,
                                x: hex.position.x,
                                y: hex.position.y
                            }
                        }));
                        return this.advance();
                    }
                } else if (this.player && agent.acceptsOrder("move", this.playerController)) {
                    if (this.player.hex === hex) {
                        // Agent moving onto player hex
                        if (agent.controller.moveAgent(agent, hex, this)) {
                            return this.advance();
                        }
                    } else if (this.player.hex === agent.hex) {
                        if (agent.controller.moveAgent(agent, hex, this)) {
                            // Player moves with agent
                            this.playerController.moveAgent(this.player, hex, this);
                            return this.advance();
                        }
                    }
                }
                return Promise.resolve(false);
            },
            settle: (agent: Agent) => {
                if (agent.acceptsOrder("settle", this.playerController)) {
                    const settlement = agent.getSettlement(this);

                    if (settlement) {
                        const existing = this.getPlace(agent.hex);

                        if (existing) {
                            this.removePlace(existing);
                        }

                        const place = this.addPlace(settlement, agent.hex);

                        if (place !== undefined) {
                            const index = this._agents.indexOf(agent);

                            if (index >= 0) {
                                this._agents.splice(index, 1);
                                this.messageUpdateDelegates("settledVillage");
                                return this.advance();
                            }
                        }
                    }
                }
                return Promise.reject("Agent does not accept the settle order");
            }
        }
    }

    get agentsCatalog() {
        return this._agentsCatalog;
    }

    get assetsCatalog() {
        return this._assetsCatalog;
    }

    get fight() {
        return this._fight;
    }

    get random() {
        return this._random;
    }

    get places() {
        return this._places;
    }

    get placesCatalog() {
        return this._placesCatalog;
    }

    get player() {
        return this._playerController.getPlayer();
    }

    get playerController() {
        return this._playerController;
    }

    get state() {
        return this._state;
    }

    get terrain() {
        return this._terrain;
    }

    addAgent(type: string, hex: Hex, controller: Controller, roles: string[], terrain: Terrain) {
        const agentType = this._agentsCatalog.get(type);
        
        if (agentType) {
            const agent = new Agent({
                ...agentType,
                hex,
                roles
            }, controller, terrain);

            this.agents.push(agent);
            return agent;
        }
    }

    addAgents() {
        this.terrain.getHexes()
            .filter(hex => this.agents.find(agent => agent.hex === hex))
            .forEach(hex => {
                const agentType = this._agentsCatalog.generate(hex, this.random);

                if (agentType) {
                    const controller = this.getController(agentType!);
                    const roles: string[] = [];

                    this.agents.push(new Agent({...agentType, hex, roles}, controller, this.terrain));
                }
            });
    }

    advance() {
        return Promise.all(Object.values(this._controllers)
            .map(controller => {
                this.activeController = controller;
                return this.activeController.resolve(this);
            })).then(() => {
                this.generateStalkers();
                this.activeController = this.playerController;
                this.setTurn(this.turn + 1);
                this.save();
                this.messageUpdateDelegates("gameAdvance")
                return true;
            });
    }

    completeFight() {
        if (this.fight?.state === "complete") {
            const winner = this.fight.attacker.hearts <= 0 ? this.fight.defender.agent : this.fight.attacker.agent;
            const loser = this.fight.defender.hearts <= 0 ? this.fight.defender.agent : this.fight.attacker.agent;

            if (loser.controller === this.playerController) {
                this.save();
                return this.loseGame();
            } else if (winner.controller === this.playerController) {
                winner.removeDestroyedItems();
                this.reward(this.playerController, loser);
                this.removeAgent(loser);
            }
            this.advance().then(() => this.messageUpdateDelegates("fightComplete"));
        } else if (this.fight?.state === "abandoned") {
            // Dont' advance after fight otherwise player will get immediately attacked
            // Set the player as active in case they were attacked after moving
            this.activeController = this.playerController;
            this._state = "exploring";
            this.messageUpdateDelegates("fightComplete");
        }
    }

    feast(agent: Agent, place: Place) {
        if (place.reward && agent.controller === this.playerController) {
            this.playerController.recordFeast(place, this);
            this.playerController.claimReward(agent, place);
        }
    }

    getAgents(hex: Hex) {
        return this.agents.filter(agent => agent.hex === hex);
    }

    getAgentFromKey(key: number) {
        return this.agents.find(agent => agent.key === key);
    }

    getHex(position: Vector2) {
        return this._terrain.getHex(position);
    }

    getHexVisible(hex: Hex) {
        const agentSeesHex = (agent: Agent) => {
            return this._terrain.getHexDistance(agent.hex, hex) <= agent.getVision();
        }

        return this.agents.filter(agent => agent.controller === this.playerController).find(agentSeesHex);
    }

    getPlace(hex: Hex) {
        return this.places.filter(place => place.hex === hex).pop();
    }

    getPlaceFromKey(key: number) {
        return this._places.find(place => place.key === key);
    }

    getPlayerAttitude(controlled: IControlled) {
        if (controlled instanceof Agent && (controlled as Agent).getOutcast()) {
            return "enemy";
        }
        return this.playerController.getAttitude(controlled.controller.faction) || "neutral";
    }

    getSurroundingHexes(hex: Hex, range: number) {
        return this._terrain.getSurroundingHexes(hex, range);
    }

    load() {
        const save = localStorage.getItem("save");

        if (save) {
            this.serialiseIn(save);
            this._activeController = this._playerController;
            this.messageUpdateDelegates("load");
        }
    }

    loseGame() {
        this._state = "lost";
        this.messageUpdateDelegates("gameLost");
    }

    post(event: GameEvent) {
        const complete = this._achievements.test(event);
        
        if (complete.length > 0) {
            this.messageUpdateDelegates({message: "achievementsComplete", complete})
        }
    }

    removeAgent(agent: Agent) {
        const places = this.places.filter(place => place.controller === agent.controller);

        places.forEach(place => place.agents.remove());
        agent.controller.removeControlAgent(agent);
        this.agents.splice(this.agents.indexOf(agent), 1);
    }

    removePlace(place: Place) {
        place.controller.removeControlPlace(place);
        this.places.splice(this.places.indexOf(place), 1);
    }

    restart(seed: number) {
        this.reinitialise(seed);
    }

    save() {
        localStorage.setItem("save", JSON.stringify(this.serialiseOut()));
    }
    
    stageFight(attacker: Agent, defender: Agent) {
        const defended = this.getAgents(attacker.hex).find(agent => agent.type === "settlers");

        this._fight = new Fight({attacker, defender, game: this, defended});
        this.messageUpdateDelegates("fighting");
        this._state = "fighting";
    }

    updateDelegates = {
        add: (delegate: UpdateDelegate) => {
            this._updateDelegates.push(delegate)
        },
        remove: (delegate: UpdateDelegate) => {
            const index = this._updateDelegates.indexOf(delegate);

            if (index >= 0) {
                this._updateDelegates.splice(index, 1);
            }
        }
    }

    private get activeController() {
        return this._activeController;
    }

    private set activeController(controller) {
        this._activeController = controller;
    }

    private get agents() {
        return this._agents;
    }

    private addPlayerAgent(agentType: IAgentType) {
        const candidates = this.terrain!.getCandidateHexes();
        const hex = this.random.getRandom(candidates);

        if (hex) {
            const roles = ["player"];
            const type = "hero";

            this.agents.push(new Agent({
                ...agentType,
                description: "agentPlayer",
                hex,
                learn: {},
                roles,
                type
            }, this.playerController, this.terrain));
        }
    }

    private addPlaces() {
        this.terrain.getHexes().forEach(hex => {
            const placeType = this._placesCatalog.generate(hex, this, this.random, this.terrain);

            if (placeType) {
                const faction = this._factions[placeType.faction];
                const controller = new MonsterController(faction.controller, placeType.faction, this.random.get());

                this._controllers.push(controller);
                this.places.push(new Place({...placeType, hex}, controller, this.terrain, this._resourcesCatalog,
                    this._terrainsCatalog));
            }
        });
    }

    private addPlace(type: string, hex: Hex) {
        const placeType = this._placesCatalog.create(hex, type);

        if (placeType) {
            const faction = this._factions[placeType.faction];
            const controller = new MonsterController(faction.controller, placeType.faction, this._random.get());
            const place = new Place({...placeType, hex}, controller, this.terrain, this._resourcesCatalog,
                this._terrainsCatalog);

            this._controllers.push(controller);
            this.places.push(place);
            // Update the network
            this.places.forEach(place => place.updateNetwork(this));
            return place;
        }
    }

    private generateStalkers() {
        const distribution = this.agentsCatalog.getStalkersDistribution(this);
        const stalker = this.random.getFromDistribution(distribution);

        if (stalker && this.player) {
            const agentType = this.agentsCatalog.get(stalker)!;
            const candidateHexes = this.terrain.getSurroundingHexes(this.player.hex, 4, 2)
                .filter(hex => agentType.passable.includes(hex.type));
            const existingAgents = this._agents
                .filter(agent => agent.type === stalker)
                .filter(agent => candidateHexes.includes(agent.hex)).length;

            if (agentType.stalking!.max > existingAgents) {
                const hex = this.random.getRandom(candidateHexes);

                if (hex) {
                    const controller = this.getController(agentType!);
                    const roles: string[] = [];

                    this.agents.push(new Agent({...agentType, hex, roles}, controller, this.terrain));
                }
            }
        }
    }

    private getController(archetype: IArchetype) {
        const faction = this._factions[archetype.faction];
        const existing = this._controllers.find(controller => controller.faction === archetype.faction);

        if (! existing) {
            const controller = new MonsterController(faction.controller, archetype.faction, this.random.get());

            this._controllers.push(controller);
            return controller;
        }
        return existing;
    };

    private initialise(mapSize: MapSize) {
        Controller.initialise();
        Agent.initialise();
        Hex.initialise();

        this._achievements = new Achievements();
        this._random = new Random(Date.now());
        this._playerController = new PlayerController(this._factions.players.controller, this._random.get());
        this._terrain = new Terrain(mapSize, this.random.seed, this._resourcesCatalog, this._terrainsCatalog);
        this._activeController = this._playerController;
        this._agents = [];
        this._controllers = [];
        this._places = [];
        this.setTurn(0);
        this.addPlayerAgent(this._agentsCatalog.getPlayerType());
        this.addPlaces();
        this.addAgents();
    }

    private messageUpdateDelegates(message: any) {
        if (typeof message === "string") {
            message = {message};
        }
        this._updateDelegates.forEach(delegate => delegate(message));
    }

    private reinitialise(seed: number) {
        this._achievements.reinitialise();
        
        const achievements = this._achievements.serialiseOut();

        localStorage.setItem("save", JSON.stringify({
            achievements,
            game: {
                seed: seed,
                mapSize: this._mapSize,
                turn: 0
            }
        }));
        this.initialise(this._mapSize);
        this._achievements.serialiseIn(achievements);
        this.messageUpdateDelegates("reinitialise");
    }

    private reward(controller: PlayerController, loser: Agent) {
        const reward = controller.reward(this._fight!);

        this.updateReputation(controller.playerAgent, loser, reward)
        this._state = "exploring";
        controller.recordVictory(loser, this, reward);
    }

    private serialiseIn(raw: string) {
        const save = JSON.parse(raw) as ISave;
        const saveGame = save.game;

        this._achievements.serialiseIn(save.achievements);
        this._random = new Random(saveGame.seed);
        this._terrain = new Terrain(saveGame.mapSize, saveGame.terrainSeed, this._resourcesCatalog, this._terrainsCatalog);
        this._state = saveGame.state;
        this._controllers = saveGame.controllers.map(controllerSave =>
            MonsterController.serialiseIn(this._factions[controllerSave.faction].controller, controllerSave));
        this._playerController = PlayerController.serialiseIn(this._factions[saveGame.playerController.faction].controller,
            saveGame.playerController);
        saveGame.agents.forEach(agentSave => {
            if (agentSave.roles.includes("player")) {
                this._agents.push(Agent.serialiseIn(agentSave, this._playerController, this._agentsCatalog, this.terrain));
            } else {
                const controller = this._controllers.find(controller => controller.key === agentSave.controller);

                if (! controller) {
                    throw new Error(`Could not find controller ${agentSave.controller} for agent ${agentSave.key}`)
                }
                this._agents.push(Agent.serialiseIn(agentSave, controller, this._agentsCatalog, this.terrain));
            }
        });
        saveGame.places.forEach(place => {
            const controller = this._controllers.find(controller => controller.key === place.controller);

            if (! controller) {
                throw new Error(`Could not find controller ${place.controller} for place ${place.key}`)
            }
            this._places.push(Place.serialiseIn(place, controller, this._assetsCatalog, this._placesCatalog,
                this._resourcesCatalog, this._terrainsCatalog, this.terrain));
        });
        this.agents.forEach(agent => this.places.filter(place => place.controller === agent.controller)
            .forEach(place => place.agents.add()));
        this.setTurn(saveGame.turn);
    }

    private serialiseOut(): ISave {
        return {
            achievements: this._achievements.serialiseOut(),
            game: {
                agents: this._agents.map(agent => agent.serialiseOut()),
                controllers: [
                    ...this._controllers.map(controller => controller.serialiseOut())
                ],
                mapSize: this._mapSize,
                places: this._places.map(place => place.serialiseOut()),
                playerController: this.playerController.serialiseOut(),
                playerHex: this.player!.hex.position,
                terrainSeed: this.terrain.seed,
                seed: this._random.seed,
                state: this._state,
                turn: this.turn
            }
        };
    }

    private setTurn(turn: number) {
        this.turn = turn;
        store.dispatch(updateMap({update: this.turn, store: ["turn"]}));
    }

    private updateReputation(winner: Agent, loser: Agent, reward: IReward) {
        const reputationRange = 3;
        const immediateThreatBonus = 1.5;

        const places = this.terrain.getSurroundingHexes(winner.hex, reputationRange).map(hex => this.getPlace(hex))
            .filter(place => place !== undefined) as Place[];

        places.forEach(place => {
            const range = this.terrain.getHexDistance(winner.hex, place.hex);
            const maxImpressiveness = range === 0 ? loser.renown * immediateThreatBonus :
                range <= place.vision ? loser.renown :
                    loser.renown / (range + 1);
            const before = place.reputation || 0;

            place.impress(winner, this.random.get() * maxImpressiveness, this);
            if (place.reputation > before) {
                reward.reputation.push(place.key);
            }
            if (place.reward) {
                reward.feast.push(place.key);
            }
        });
    }
}
