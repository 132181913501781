import {IconButton, useTheme} from "@mui/material";
import {GiAnvilImpact, GiBattleGear, GiBeerStein, GiCampfire, GiSwordBrandish, GiVillage, GiWoodenSign}
    from "react-icons/gi";
import {Vector2} from "../../utility/vector2";
import {IconType} from "react-icons/lib";
import {createElement, MouseEventHandler, TouchEventHandler, useEffect, useReducer} from "react";
import {Game} from "../../game/game";
import {connect} from "react-redux";
import {update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";
import {selectMap} from "../../redux/selectors/map";
import {IMap} from "../../redux/slices/map";

const BUTTON_RADIUS_RATIO = 0.6;
interface IActionButton {
    action: MouseEventHandler<HTMLButtonElement>
    icon: IconType
    position: Vector2
}

interface IRadialInterface {
    dragging: boolean
    game: Game
    map: IMap
    position: Vector2
    size: Vector2
    updateDialog: Update
}

function RadialInterface({dragging, game, map, position, size, updateDialog}: IRadialInterface) {
    const theme = useTheme();
    const iconProps = {
        ...theme.map.icon,
        color: theme.palette.common.white
    };
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const styles = {
        button: {
            backgroundColor: theme.palette.primary.main,
            height: theme.map.button.size,
            position: "absolute",
            width: theme.map.button.size,
            zIndex: 10
        }
    };
    const section = Math.PI / 3;
    const sectionOffset = -section;
    const getIconPosition = (index: number) => {
        const angle = sectionOffset + index * section;
        const buttonRadius = (theme.map.button.size) * 0.5
        const radius = size.x * BUTTON_RADIUS_RATIO;

        return new Vector2(
            position.x + size.x * 0.5 - buttonRadius + Math.cos(angle) * radius,
            position.y + size.y * 0.5 - buttonRadius + Math.sin(angle) * radius
        );
    };
    const handleMouse: MouseEventHandler = (event) => event.stopPropagation();
    const handleTouch: TouchEventHandler = (event) => event.stopPropagation();
    const buttons: IActionButton[] = [{
        icon: GiWoodenSign,
        position: getIconPosition(0),
        action: () => updateDialog({update: {name: "details"}})
    }];
    const selectedAgent = map.selected.agent && game.getAgentFromKey(map.selected.agent);

    if (selectedAgent) {
        if (selectedAgent.controller.player) {
            const place = game.getPlace(selectedAgent.hex);
            const defender = game.getAgents(selectedAgent.hex)
                .filter(agent => agent !== selectedAgent && agent.type !== "settlers").pop();
            const broken = selectedAgent.equipment
                .filter(equipment => equipment.damage && equipment.repair.length > 0);
            const repairable = place?.assets.map(asset =>
                broken.filter(item => asset.canRepair(selectedAgent, item))
            ).flat() || [];

            buttons.push({
                icon: GiCampfire,
                position: getIconPosition(buttons.length),
                action: () => game.advance().then(() => forceUpdate())
            });
            buttons.push({
                icon: GiBattleGear,
                position: getIconPosition(buttons.length),
                action: () => {
                    updateDialog({update: {name: "equipment"}});
                    forceUpdate();
                }
            });
            if (repairable.length) {
                buttons.push({
                    icon: GiAnvilImpact,
                    position: getIconPosition(buttons.length),
                    action: () => {
                        repairable.forEach(broken => broken.repairItem());
                        forceUpdate();
                    }
                });
            }
            if (defender) {
                buttons.push({
                    icon: GiSwordBrandish,
                    position: getIconPosition(buttons.length),
                    action: () => {
                        game.stageFight(selectedAgent, defender);
                        updateDialog({update: {name: "fight"}});
                        forceUpdate();
                    }
                });
            }
            if (place?.reward) {
                buttons.push({
                    icon: GiBeerStein,
                    position: getIconPosition(buttons.length),
                    action: () => {
                        game.feast(selectedAgent, place);
                        updateDialog({update: {name: "feast"}});
                        forceUpdate();
                    }
                });
            }
        } else {
            if (game.player?.hex === selectedAgent.hex &&
                selectedAgent.acceptsOrder("settle", game.player.controller)) {
                buttons.push({
                    icon: GiVillage,
                    position: getIconPosition(buttons.length),
                    action: () => game.actions.settle(selectedAgent)
                });
            }
        }
    }
    useEffect(() => {
        if (game) {
            game.updateDelegates.add(forceUpdate);
            return () => game.updateDelegates.remove(forceUpdate);
        }
    }, [forceUpdate, game]);
    return (
        <>
            {buttons.map((button, index) =>
                <IconButton disabled={dragging} key={index} onClick={button.action} onMouseDown={handleMouse}
                            onMouseUp={handleMouse} onTouchStart={handleTouch} onTouchEnd={handleTouch}
                            size="small" sx={{...styles.button, left: button.position.x, top: button.position.y}}>
                    {createElement(button.icon, iconProps)}
                </IconButton>
            )}
        </>
    );
}

const mapStateToProps = (store: never) => ({
    map: selectMap(store)
});
const connected = connect(mapStateToProps, {
    updateDialog
})(RadialInterface);

export {connected as RadialInterface}
