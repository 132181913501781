import {ICountTest, IRequirementCount, RequirementCount} from "./requirementCount";

export interface IRequirementPlace extends IRequirementCount {
    type: "place"
    place: string
    range: number
}

export class RequirementPlace extends RequirementCount {

    private readonly place: string;
    private readonly range: number;

    constructor(data: IRequirementPlace) {
        super(data);
        this.place = data.place;
        this.range = data.range;
    }

    getCount(props: ICountTest) {
        return props.game.places
            .filter(test => (test.type === this.place || test.category === this.place) &&
                (this.range === 0 || test.hex !== props.hex))
            .reduce((total, test) =>
                props.game.terrain.getHexDistance(test.hex, props.hex) <= this.range ? total + 1 : total, 0);
    }
    
}
