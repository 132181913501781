import {
    MouseEventHandler,
    ReactElement,
    TouchEventHandler,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {Box} from "@mui/material";
import {Vector2} from "../../utility/vector2";
import {DebugContext} from "../debug/DebugProvider";
import {MapSize} from "./mapSize";
import {Bounds2} from "../../utility/bounds2";

const maximumYOffset = 0;

interface IMapController {
    children: ReactElement | ReactElement[] | null
    focus?: Vector2
    onSetOffset: (offset: Vector2) => void
    mapSize: MapSize
}
export function MapController({children, focus, onSetOffset, mapSize}: IMapController) {
    const debug = useContext(DebugContext);
    const [minimumYOffset, setMinimumYOffset] = useState(0);
    const [containerBounds, setContainerBounds] = useState(Bounds2.zeroEmpty);
    const [position, setPosition] = useState(new Vector2(0, 0));
    const [startPosition, setStartPosition] = useState(new Vector2(0, 0));
    const [dragging, setDragging] = useState<Vector2>(new Vector2(0, 0));
    const [mode, setMode] = useState<string>("default");
    const refContainer = useRef<HTMLDivElement>();
    const handlePanStart = (start: Vector2) => {
        setDragging(start);
        setStartPosition(new Vector2(position));
        setMode("panning");
    };
    const handlePanStop = () => {
        setMode("default");
    };
    const handlePanMove = useCallback((move: Vector2) => {
        const offset = startPosition.plus(move.minus(dragging)).maxY(minimumYOffset).minY(maximumYOffset);

        onSetOffset(offset);
        setPosition(offset);
    }, [dragging, minimumYOffset, onSetOffset, startPosition]);
    const handleContextMenu: MouseEventHandler = (event) =>
        event.preventDefault();
    const handleMouseDown: MouseEventHandler = ({button, pageX, pageY}) => {
        handlePanStart(new Vector2(pageX, pageY));
    };
    const handleMouseMove: MouseEventHandler = ({pageX, pageY}) => {
        if (mode === "panning") {
            handlePanMove(new Vector2(pageX, pageY));
        }
    };
    const handleMouseUp: MouseEventHandler = () => {
        if (mode === "panning") {
            handlePanStop();
        }
    }
    const handleMouseLeave: MouseEventHandler = () => {
        if (mode === "panning") {
            handlePanStop();
        }
    }
    const handleTouchMove: TouchEventHandler = (event) => {

        if (mode === "panning") {
            const touch = new Vector2(event.touches[0].pageX, event.touches[0].pageY);

            if (containerBounds.contains(touch)) {
                handlePanMove(touch);
            } else {
                handlePanStop();
            }
        }
    };
    const handleTouchStart: TouchEventHandler = (event) => {
        handlePanStart(new Vector2(event.touches[0].pageX, event.touches[0].pageY));
    }

    const handleTouchEnd: TouchEventHandler = (event) => {
        if (mode === "panning") {
            handlePanStop();
        }
    }

    const handleTouchCancel: TouchEventHandler = (event) => {
        if (mode === "panning") {
            handlePanStop();
        }
    }

    const style = {
        container: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        contained: {
            position: "absolute",
            height: mapSize.MapDimensions.y,
            width: mapSize.MapDimensions.x,
            top: position.y,
            left: position.x,
        },
    }

    useEffect(() => {
        const updateBounds = () => {
            if (refContainer.current) {
                const clientRect = refContainer.current.getBoundingClientRect();

                setContainerBounds(new Bounds2(new Vector2(clientRect.x, clientRect.y),
                    new Vector2(clientRect.x + clientRect.width, clientRect.y + clientRect.height)));
            }
        }
        const handleResize = () => {
            updateBounds();
        };

        updateBounds();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [debug, mapSize, mapSize]);
    useEffect(() => {
        const minimumYOffset = containerBounds.max.y - mapSize.MapDimensions.y;

        debug.logEvent(`minimumYOffset: ${minimumYOffset.toString()}`);
        setMinimumYOffset(minimumYOffset);
    }, [containerBounds, debug, mapSize]);
    useEffect(() => {
        if (focus) {
            const position = containerBounds.size().multiply(0.3)
                .minus(focus.multiply(mapSize.HexWidth))
                .maxY(minimumYOffset).minY(maximumYOffset);

            onSetOffset(position);
            setPosition(position);
        }
    }, [containerBounds, debug, focus, mapSize.HexWidth, minimumYOffset, onSetOffset]);
    useEffect(() => {
        focus && debug.logEvent(`focus: ${focus.toString()}`);
    }, [debug, focus]);
    return (
        <Box onContextMenu={handleContextMenu} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave}
             onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onTouchCancel={handleTouchCancel}
             onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}
             ref={refContainer} sx={style.container}>
            <Box sx={style.contained}>
                {children}
            </Box>
        </Box>
    );
}
