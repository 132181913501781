type UpdateDelegate = () => void
type UpdateValueDelegate = (value: any) => void

export class DebugController {
    private visible = false;
    private logEvents: string[] = [];
    private debugValues: {[value: string]: any} = [];
    private updateDelegates: UpdateDelegate[] = [];
    private updateValueDelegates: {[value: string]: UpdateValueDelegate[]} = {};

    addUpdateDelegate(delegate: UpdateDelegate) {
        this.updateDelegates.push(delegate);
    }
    removeUpdateDelegate(delegate: UpdateDelegate) {
        const index = this.updateDelegates.indexOf(delegate);

        if (index >= 0) {
            this.updateDelegates.splice(index, 1);
        }
    }
    addUpdateValueDelegates(value: string, delegate: UpdateValueDelegate) {
        if (! this.updateValueDelegates[value]) {
            this.updateValueDelegates[value] = [];
        }
        this.updateValueDelegates[value].push(delegate);
    }
    removeUpdateValueDelegates(value: string, delegate: UpdateValueDelegate) {
        if (! this.updateValueDelegates[value]) {
            const index = this.updateValueDelegates[value].indexOf(delegate);

            if (index >= 0) {
                this.updateValueDelegates[value].splice(index, 1);
            }
        }
    }
    getDebugValue(name: string) {
        return this.debugValues[name];
    }
    getLogEvents() {
        return this.logEvents;
    }
    getVisible() {
        return this.visible;
    }
    logEvent(event: string) {
        this.logEvents.push(event);
        this.onUpdate();
    }
    setDebugValue(name: string, value: any) {
        this.debugValues[name] = value;
        this.updateValueDelegates[name]?.forEach(delegate => delegate(value));
        this.onUpdate();
    }
    setVisible(visible: boolean) {
        this.visible = visible;
        this.onUpdate();
    }

    private onUpdate() {
        this.updateDelegates.forEach(delegate => delegate());
    }
}
