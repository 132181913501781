import {Box, Button, Dialog, IconButton, Typography, useTheme} from "@mui/material";
import {Game} from "../../game/game";
import {Localisation} from "../localisation/localisation";
import {createElement, useReducer} from "react";
import {IconType} from "react-icons";
import {
    GiBoots,
    GiNecklace,
    GiRing,
    GiScrollQuill,
    GiShieldReflect,
    GiShoulderArmor,
    GiSwapBag,
    GiSwordBrandish
} from "react-icons/gi";
import {Item} from "../../game/items/item";
import {Card, ICard} from "../../game/fight/card";
import {selectDialog} from "../../redux/selectors/dialog";
import {connect} from "react-redux";
import {IDialog, update as updateDialog} from "../../redux/slices/dialog";
import {Update} from "../../redux/slices/base";
import {Skill} from "../../game/skills/skill";

const slots: {[slot: string]: IconType} = {
    armour: GiShoulderArmor,
    boots: GiBoots,
    default: GiSwapBag,
    necklace: GiNecklace,
    papers: GiScrollQuill,
    ring: GiRing,
    shield: GiShieldReflect,
    weapon: GiSwordBrandish
};

interface IEquipmentDialog {
    game: Game
    dialog: IDialog
    updateDialog: Update
}
function EquipmentDialog({dialog, game, updateDialog}: IEquipmentDialog) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexGrow: 1,
            height: "60vh"
        },
        description: {
            flexGrow: 1
        },
        dialog: {
        },
        equipment: {
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            left: 0,
            margin: 1,
            minHeight: 0,
            position: "absolute",
            right: 0,
            top: 0
        },
        equipped: {
            color: theme.palette.primary.main
        },
        item: {
            display:"flex" 
        },
        list: {
            flexGrow: 1,
            margin: 1,
            overflowX: "hidden",
            overflowY: "auto"
        },
        subtitle: {
            fontStyle: "italic",
            fontWeight: "bold",
            textTransform: "capitalize"
        },
        title: {
            fontWeight: "bold",
            textTransform: "capitalize"
        },
        watermark: {
            backgroundImage: `url(./images/dialogs/equipment.jpg)`,
            backgroundSize: "cover",
            height: "100%",
            opacity: "30%",
            width: "100%"
        }
    }
    const player = game.player;
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const handleClose = () => {
        updateDialog({update: "", store: ["name"]});
    }

    if (! player) return null;

    const handleEquipToggle = (item: Item) => () => {
        if (player.equipped[item.slot] === item) {
            player.removeEquippedItem(item);
        } else {
            player.equipItem(item.type);
        }
        forceUpdate();
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={dialog.name === "equipment"} sx={styles.dialog}>
            <Box sx={styles.content}>
                <Box sx={styles.watermark}/>
                <Box sx={styles.equipment}>
                    <Box sx={styles.list}>
                        <Typography variant="h6">{Localisation.GetText("titleEquipment")}</Typography>
                        {player.equipment
                            .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
                            .sort((lhs, rhs) => lhs.slot.localeCompare(rhs.slot))
                            .map((item, index) =>
                            <Box key={index} sx={styles.item}>
                                <Box>
                                    <IconButton onClick={handleEquipToggle(item)}
                                                sx={player.equipped[item.slot]?.type === item.type ? styles.equipped : {}}>
                                        {createElement(slots[item.slot] || slots.default)}
                                    </IconButton>
                                </Box>
                                <Box sx={styles.description}>
                                    <Typography sx={styles.title}>
                                        {Localisation.GetText(item.name)}
                                        {item.damage > 0 && Localisation.GetText("itemDamaged")
                                            .replace("{item.damage}", item.damage.toString())}
                                    </Typography>
                                    <Typography>
                                        {Localisation.GetText(item.description)}
                                    </Typography>
                                    {Object.keys(item.cards).length > 0 &&
                                        <Typography sx={styles.subtitle}>Cards</Typography>
                                    }
                                    {Object.entries(item.cards)
                                        .map(([type, number]) =>
                                            [Card.catalog.get(type), number] as [ICard, number])
                                        .filter(([card]) => card !== undefined)
                                        .map(([card, number], index) =>
                                            <Box key={index}>
                                                <Typography component="span" sx={styles.title}>
                                                    {Localisation.GetText(card.name)} ({number})
                                                </Typography>
                                                <Typography>{Localisation.GetText(card.description)}</Typography>
                                            </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                        <Typography variant="h6">{Localisation.GetText("titleSkills")}</Typography>
                        {Object.keys(player.learn).map(skill =>
                            <Box key={skill}>
                                <Typography component="span" sx={styles.title}>
                                    {Localisation.GetText(Skill.catalog.get(skill)?.name)}
                                </Typography>
                                <Typography>{Localisation.GetText(Skill.catalog.get(skill)?.description)}</Typography>
                            </Box>
                        )}
                    </Box>
                    <Button onClick={handleClose}>Close</Button>
                </Box>
            </Box>
        </Dialog>
    );
}
const mapStateToProps = (store: never) => ({
    dialog: selectDialog(store)
});
const connected = connect(mapStateToProps, {
    updateDialog
})(EquipmentDialog);

export {connected as EquipmentDialog}
