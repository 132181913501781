import {Hex} from "../map/hex";
import {Controller, IReward} from "../controllers/controller";
import {Move} from "../actions/move";
import {Game} from "../game";
import {AgentsCatalog, IAgentType} from "./agentsCatalog";
import {Terrain} from "../map/terrain";
import {Item} from "../items/item";
import {Card} from "../fight/card";
import {Vector2} from "../../utility/vector2";
import {Place} from "../places/place";
import {IControlled} from "../controllers/controlled";

const TERRAIN_SKILLS: Record<string, string[]> = {
    "lakeWalker": ["sea"],
    "mountaineer": ["mountains", "mesas", "alpine"],
    "trekker": ["ice"]
};

type UpdateDelegate = () => void

interface IEquipmentSave {
    damage: number
    equipped: boolean
    item: string
}

export interface IAgentSave {
    controller: number
    equipment: IEquipmentSave[]
    faction?: string
    hex: Vector2
    key: number
    roles: string[]
    sailing: boolean
    type: string
}

export interface IFeast {
    place: string
    reward: string
}

export interface IVictory {
    type: string
    reward: IReward
}

export interface IAgentDetails extends IAgentType {
    hex: Hex
    roles: string[]
    type: string
}

export class Agent implements IControlled {
    private static _created = 0;

    protected _controller: Controller;
    private readonly _equipment: Item [] = [];
    private readonly _equipped: Record<string, Item> = {};
    private readonly _updateDelegates: UpdateDelegate[] = [];

    private readonly _key: number;

    private _sailing = false;

    constructor(private _details: IAgentDetails, controller: Controller, terrain: Terrain) {
        this._controller = controller;
        this._key = ++Agent._created;
        this._controller.giveControlAgent(this, terrain);
        this._details.items?.forEach(item => {
            this.addEquipment(item);
            this.equipItem(item)
        });
    }

    acceptsOrder(order: string, controller: Controller) {
        if (this.acceptsOrders) {
            if (order === "move" || order === "settle") {
                return ! this.getOutcast() && this.controller.getAttitude(controller.faction) === "friend";
            }
        }
        return false;
    }

    addEquipment(type: string) {
        if (!this._equipment.map(item => item.type).includes(type)) {
            const item = Item.catalog.create(type, Item);

            item && this._equipment.push(item);
            return item;
        }
    }

    addUpdateDelegate(delegate: UpdateDelegate) {
        this._updateDelegates.push(delegate)
    }

    getSkill(skill: string) {
        return this.skills?.includes(skill) || Object.values(this._equipped).some(item => item.skills.includes(skill));
    }

    getCards(game: Game) {
        const cards: Card[] = this.controller.getCards();

        cards.push(...Card.catalog.createFromDistribution(this._details.cards ?? {}, Card));
        Object.values(this.equipped).forEach(item => {
            cards.push(...item.getCards());
        });

        const guarded = game.getPlace(this.hex);

        if (guarded && this.getControls(guarded, game)) {
            cards.push(...guarded.getCards());
        }
        return cards;
    }

    getControls(place: Place, game: Game) {
        const guardian = place.getGuardian(game);

        return guardian === this || (! guardian && this.controller.player);
    }
    getEquipped() {
        return Object.values(this._equipped).filter(item => item !== undefined);
    }

    getEquippedItem(slot: string) {
        return this._equipped[slot];
    }

    getItem(type: string) {
        return this._equipment.find(item => item.type === type);
    }

    getOutcast() {
        return this.roles.includes("outcast");
    }

    getPassable(terrain: string) {
        return this.passable.includes("all") || this.passable.includes(terrain) ||
            this.getEquipped().some(item => item.skills.some(skill => TERRAIN_SKILLS[skill]?.includes(terrain)));
    }

    getSettlement(game: Game) {
        const settlement = (this._details.settlements || []).find(settlement => {
            if (settlement.constraint === undefined) {
                return true;
            } else if (settlement.constraint?.type === "place") {
                const place = game.getPlace(this.hex);

                return place?.type === settlement.constraint.data;
            }
            return false;
        });

        return settlement?.type;
    }

    getVision() {
        return this._details.vision + this.hex.vision;
    }

    equipItem(key: string) {
        const item = this.getItem(key);

        if (item && item.damage < item.resilience) {
            this._equipped[item.slot] = item;
        }
    }

    fight(enemy: Agent, game: Game): Promise<boolean> {
        if (enemy.controller.player) {
            return new Promise(resolve => {
                const handleUpdate = () => {
                    if (game.fight?.state === "complete") {
                        game.updateDelegates.remove(handleUpdate);
                        resolve(true);
                    }
                }

                game.stageFight(this, enemy);
                game.updateDelegates.add(handleUpdate);
            })
        } else {
            // AI vs AI
            return Promise.resolve(true);
        }
    }
    
    move(game: Game, hex: Hex): boolean {
        const moved = Move.resolve(this, hex, game);

        if (moved) {
            this._details.hex = hex;
            this._updateDelegates.forEach(delegate => delegate());
            return true;
        }
        return false;
    }

    removeDestroyedItems() {
        Object.entries(this._equipped).forEach(([slot, item]) => {
            if (item.damage >= item.resilience) {
                delete this._equipped[slot];
            }
        })
    }

    removeEquippedItem(item: Item) {
        if (this._equipment.includes(item)) {
            delete this._equipped[item.slot];
        }
    }

    removeRole(role: string) {
        const index = this._details.roles.indexOf(role);
        
        if (index >= 0) {
            this._details.roles.splice(index, 1);
        }
    }

    removeUpdateDelegate(delegate: UpdateDelegate) {
        const index = this._updateDelegates.indexOf(delegate);

        if (index >= 0) {
            this._updateDelegates.splice(index, 1);
        }
    }

    static initialise() {
        this._created = 0;
    }

    static serialiseIn(save: IAgentSave, controller: Controller, agentsCatalog: AgentsCatalog, terrain: Terrain) {
        const agentType = agentsCatalog.get(save.type);
        
        if (! agentType) {
            throw new Error(`Unknown agent type (${save.type}) being serialised in`);
        }

        const agent = new Agent({...agentType!, hex: terrain.getHex(save.hex), roles: save.roles}, controller, terrain);

        save.equipment.forEach(saved => {
            const added = agent.addEquipment(saved.item);

            if (added) {
                added.damageItem(saved.damage);
                if (saved.equipped) {
                    agent.equipItem(saved.item);
                }
            }
        });
        agent.setSailing(save.sailing); 
        return agent;
    }

    serialiseOut(): IAgentSave {
        return {
            controller: this.controller.key,
            equipment: this.equipment.map(item => ({
                damage: item.damage,
                equipped: Object.values(this.equipped).find(equipped => equipped.type === item.type) !== undefined,
                item: item.type
            })),
            hex: this.hex.position,
            key: this._key,
            roles: this.roles,
            sailing: this.sailing,
            type: this.type
        };
    }

    setSailing(sailing: boolean) {
        this._sailing = sailing;
    }

    get acceptsOrders() {
        return this._details.acceptsOrders;
    }

    get controller() {
        return this._controller;
    }

    get description() {
        return this._details.description;
    }

    get equipment() {
        return this._equipment;
    }

    get equipped() {
        return this._equipped;
    }

    get health() {
        return this._details.health;
    }

    get hex() {
        return this._details.hex;
    }

    get key() {
        return this._key;
    }

    get learn() {
        return this._details.learn;
    }
    
    get loot() {
        return this._details.loot;
    }

    get mobility() {
        return this._details.mobility;
    }

    get name() {
        return this._details.name;
    }

    get passable() {
        return this._details.passable;
    }

    get range() {
        return this._details.range;
    }

    get renown() {
        return this._details.renown;
    }

    get roles() {
        return this._details.roles;
    }

    get sailing() {
        return this._sailing;
    }

    get skills() {
        return this._details.skills;
    }
    get type() {
        return this._details.type;
    }

    get visual() {
        return this._details.visual;
    }
}