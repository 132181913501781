export class Random {
    constructor(private _seed: number = 0) {
    }

    get seed() {
        return this._seed;
    }

    // Mulberry32
    get() {
        let t = this._seed += 0x6D2B79F5;

        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }

    // Given an ordered distribution of types to probabilities it will select a random entry based on probability.
    // If there are two entries with a probability of 0.1 each then there is a 0.9 probability of no entry returned,
    // if there is A with p(1) and B with p(0) then there is a 50% chance of returning A and 50% of no entry. 
    getFromDistribution(distribution: Record<string, number>) {
        let max = 0;
        let total = 0;
        const cumulative = Object.fromEntries(Object.entries(distribution)
            .map(([type, probability]) => {
                ++max;
                total += probability;
                return [type, total];
            }));

        if (total > 0) {
            let random = this.get() * max;

            return Object.keys(cumulative).find(type => random <= cumulative[type]);
        }
    }

    // Given an array of likelihoods random index is selected
    selectFromArray(likelihoods: number[]) {
        const max = likelihoods.reduce((sum, likelihood) => sum + likelihood, 0);
        let random = this.get() * max;

        return likelihoods.findIndex(value => (random -= value) < 0);
    }

    // Given a set of values and relative likelihoods one member is guaranteed to be selected 
    selectFromDistribution(distribution: Record<string, number>) {
        return Object.keys(distribution)[this.selectFromArray(Object.values(distribution))];
    }

    getRandom<Type>(from: Type[]) {
        return from.length === 0 ? undefined : from[Math.floor(this.get() * from.length)];
    }
}
