import {Catalog} from "../catalog/catalog";
import skills from "../../data/catalogs/skills/skills.json";
import {IConstraints} from "../fight/constraints";

interface IMutation {
    constraint: string
    type: string
    value: number
}
interface ISkill {
    name: string
    description: string
    mutations: IMutation[]
}

interface IProps extends ISkill {
    type: string
}

export class Skill {
    static catalog = new Catalog<ISkill>(skills);

    constructor(private _props: IProps) {
    }

    get description() {
        return this._props.description;
    }

    get type() {
        return this._props.type;
    }

    mutate(constraints: IConstraints) {
        this._props.mutations.forEach(mutation => {
            const constraint = mutation.constraint as keyof IConstraints;

            if (constraint) {
                if (mutation.type === "add") {
                    constraints[constraint] += mutation.value;
                } else if (mutation.type === "set") {
                    constraints[constraint] = mutation.value;
                }
            }
        })
    }
}