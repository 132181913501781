
export interface IRequirement {
    type: string
}

export interface ITest {
}
export abstract class Requirement {

    protected type: string;

    protected constructor(requirement: IRequirement) {
        this.type = requirement.type;
    }

    protected getProperty(properties: any, path: string[]) {
        return path.reduce((property, member) => {
            return property ? property[member] : undefined;
        }, properties);
    }

    abstract test(properties: any): boolean;
}