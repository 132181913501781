import {IAssetData} from "./assetsCatalog";
import {Place} from "../places/place";
import {Item} from "../items/item";
import {Agent} from "../agents/agent";
import {Card} from "../fight/card";
import {IInteraction, Interactions, ISpawningControls} from "../places/placesCatalog";

export class Asset {
    constructor(private _type: string, private _data: IAssetData, private _place: Place) {
    }
    
    get cost() {
        return this._data.cost;
    }
    get development() {
        return this._data.development;
    }
    get type() {
        return this._type;
    }
    get interactions() {
        return this._data.interactions || {} as Record<Interactions, IInteraction>;
    }
    get name() {
        return this._data.name;
    }
    get place() {
        return this._place;
    }
    
    get spawning() {
        return this._data.spawning || {} as ISpawningControls
    }
    get states() {
        return this._data.states || [];
    }
    
    get upgrade() {
        return this._data.upgrade;
    }

    canRepair(agent: Agent, item: Item) {
        return item.repair.includes(this.type) &&
            (this._data.reputationRepair === undefined || this.place.reputation >= this._data.reputationRepair);
    }
    getCards() {
        return Card.catalog.createFromDistribution(this._data.cards ?? {}, Card);
    }
    getRewards(reputation: number) {
        if (this._data.rewards && this._data.reputationCreate && reputation >= this._data.reputationCreate) {
            return this._data.rewards;
        } else {
            return {}
        }
    }
}