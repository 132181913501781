import {Box, Divider, IconButton, Switch, Typography} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import {DebugController} from "../debug/debugController";

interface IDebugDialog {
    debug: DebugController
}
export function DebugDialog({debug}: IDebugDialog) {
    const styles = {
        log: {
            height: 100,
            overflowY: "scroll"
        }
    };
    const handleToggleDebugValue = (parameter: string) => () =>
        debug.setDebugValue(parameter, ! debug.getDebugValue(parameter));

    return (
        <Box>
            <Divider/>
            <Box display="flex" marginTop={1}>
                <Typography flexGrow={1}>Hex Coordinates Visible</Typography>
                <Switch checked={Boolean(debug.getDebugValue("HexCoordinatesVisible"))}
                        onChange={handleToggleDebugValue("HexCoordinatesVisible")}/>
            </Box>
            <Box display="flex">
                <Typography flexGrow={1}>New Hand View</Typography>
                <Switch checked={Boolean(debug.getDebugValue("NewHandView"))}
                        onChange={handleToggleDebugValue("NewHandView")}/>
            </Box>
            <Box display="flex">
                <Typography flexGrow={1}>DebugLog</Typography>
                <IconButton onClick={handleToggleDebugValue("LogVisible")}>
                    {debug.getDebugValue("LogVisible") ? <Remove/> : <Add/>}
                </IconButton>
            </Box>
            {debug.getDebugValue("LogVisible") &&
                <Box sx={styles.log}>
                    {debug.getLogEvents().map((line, index) =>
                        <Typography color="white" key={index}>{line}</Typography>)}
                </Box>
            }
        </Box>
    );    
}