import React, {MouseEventHandler, ReactElement, TouchEventHandler} from "react";
import {Box} from "@mui/material";
import {Vector2} from "../../utility/vector2";
import {Hex} from "../../game/map/hex";
import {MapSize} from "./mapSize";
import {TerrainType} from "../../game/map/terrainsCatalog";

const hexStyle = (options: IOptions, position: Vector2, mapOffset: Vector2, screenWidth: number) => {
    const height = Math.round(options.size.HexHeight);
    const oddLine = Math.abs(position.y) % 2;
    const offset = (options.size.HalfHexWidth + options.size.HexMargin) * oddLine;
    const mapWidth = options.size.MapSize.x * (options.size.HexWidth + options.size.HexMargin);
    let left = position.x * (options.size.HexWidth + options.size.HexMargin) - offset;

    while (left + mapOffset.x > screenWidth) {
        left -= mapWidth;
    }
    while (left + mapOffset.x < - options.size.HexWidth) {
        left += mapWidth;
    }
    return {
        "&:hover": {
            opacity: 0.8,
            transition: "opacity 0.2s ease-in-out"
        },
        backgroundColor: options.color,
        clipPath: "polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%)",
        cursor: "default",
        height,
        left,
        outline: "1px solid transparent",
        position: "absolute",
        top: Math.floor(position.y * (height - options.size.HalfHexEdge)),
        width: options.size.HexWidth,
    };
};
interface IOptions {
    color: string
    size: MapSize
    type: TerrainType
}
interface IHexView {
    children?: ReactElement[] | ReactElement | null
    hex: Hex
    onInteractionFinish: (position: Vector2) => void
    onInteractionStart: (position: Vector2) => void
    options: IOptions
    selected: boolean
    mapOffset: Vector2
    screenWidth: number
    visible: boolean
}
export function HexView({children = null, hex, onInteractionStart, options, onInteractionFinish, screenWidth, selected,
                            mapOffset, visible}: IHexView) {
    const styles = {
        children: {
            display: "flex",
            position: "absolute",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0
        },
        watermark: {
            backgroundImage: `url(./images/terrain/${options.type}.jpg)`,
            backgroundSize: "cover",
            height: options.size.HexHeight,
            width: options.size.HexWidth,
            mask: "radial-gradient(circle at center, #fff 30%, transparent 70%)",
        }
    }
    const handleMouseDown: MouseEventHandler = ({button, pageX, pageY}) => {
        if (button === 0) {
            onInteractionStart(new Vector2(pageX, pageY));
        }
    }
    const handleMouseUp: MouseEventHandler = ({pageX, pageY}) => {
        onInteractionFinish(new Vector2(pageX, pageY));
    }
    const handleTouchEnd: TouchEventHandler = ({changedTouches}) => {
        if (changedTouches.length) {
            onInteractionFinish(new Vector2(changedTouches[0].pageX, changedTouches[0].pageY));
        }
    }
    const handleTouchStart: TouchEventHandler = ({touches}) => {
        onInteractionStart(new Vector2(touches[0].pageX, touches[0].pageY));
    }
    const selectedStyle = {
        ...hexStyle({...options, color: "white"}, hex.position, mapOffset, screenWidth),
        zIndex: 1,
        transform: "scale(1.1)"
    };

    return (
        <>
            {selected &&
                <Box sx={selectedStyle}/>
            }
            <Box onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
                 onTouchEnd={handleTouchEnd} onTouchStart={handleTouchStart}
                 sx={hexStyle(options, hex.position, mapOffset, screenWidth)} zIndex={selected ? 2 : 0}>
                {visible && <>
                    <Box sx={styles.watermark}/>
                    <Box sx={styles.children}>
                        {children}
                    </Box>
                </>}
            </Box>
        </>
    );
}
