import {Achievement, IAchievement, Progress} from "./achievement";
import {GameEvent} from "./gameEvent";

interface IAchievementCount extends IAchievement {
    count: {
        key: string
    }
}
export class AchievementCount extends Achievement
{
    private readonly _key: string;

    constructor(props: IAchievement, progress: Progress) {
        super(props, progress);
        this._key = (props as IAchievementCount).count.key;
    }

    protected update(event: GameEvent) {
        if (this._progress !== true) {
            this._progress[this._key] = (this._progress[this._key] || 0) + 1;
        }
    }
}