import {Box, Typography} from "@mui/material";
import {Localisation} from "../../localisation/localisation";

interface ILabelledParameter {
    label: string
    value?: string
}

export function LabelledParameter({label, value}: ILabelledParameter) {
    const styles = {
        label: {
            flexGrow: 1,
            textTransform: "capitalize"
        },
        value: {
            textTransform: "capitalize"
        }
    };

    return (
        <Box display="flex">
            <Typography sx={styles.label}>{Localisation.GetText(label)}</Typography>
            {value &&
                <Typography sx={styles.value}>
                    {value}
                </Typography>
            }
        </Box>
    );
}