import {Box, Divider, Typography} from "@mui/material";
import {Hex} from "../../../game/map/hex";
import {Localisation} from "../../localisation/localisation";
import {Game} from "../../../game/game";
import {PlaceDetails} from "./PlaceDetails";
import React from "react";
import {TutorialTooltip} from "../../help/TutorialTooltip";

interface IHexDetails {
    game: Game
    hex: Hex
}
export function HexDetails({game, hex}: IHexDetails) {
    const styles = {
        label: {
            flexGrow: 1,
            textTransform: "capitalize"
        },
        section: {
            marginBottom: 1,
            marginTop: 1,
        },
        title: {
            flexGrow: 1,
            textTransform: "capitalize"
        },
        value: {
            textTransform: "capitalize"
        }
    };
    const place = game.getPlace(hex);

    return (
        <Box>
            <Box sx={styles.section}>
                <Typography sx={styles.title} variant="h6">{Localisation.GetText("terrain")}</Typography>
                <Box display="flex">
                    <Typography sx={styles.label}>{Localisation.GetText("type")}</Typography>
                    <TutorialTooltip next="PlaceTitle" title="TerrainType">
                        <Typography sx={styles.value}>{Localisation.GetText(hex.type)}</Typography>
                    </TutorialTooltip>
                </Box>
            </Box>
            <Divider/>
            {place &&
                <Box>
                    <Box sx={styles.section}>
                        <PlaceDetails place={place} player={game.playerController}/>
                    </Box>
                    <Divider/>
                </Box>
            }
            {hex.resource &&
                <Box sx={styles.section}>
                    <Box display="flex">
                        <Typography sx={styles.label}>{Localisation.GetText("resource")}</Typography>
                        <Typography sx={styles.value}>{Localisation.GetText(hex.resource)}</Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
}