import {IRequirement, ITest, Requirement} from "./requirement";
import {RequirementsFactory} from "./requirementsFactory";

export interface IRequirementNot extends IRequirement {
    type: "not"
    requirement: IRequirement
}
export class RequirementNot extends Requirement {
    private requirement: Requirement;

    constructor(data: IRequirementNot) {
        super(data);
        this.requirement = RequirementsFactory.Create(data.requirement);
    }
    test(props: ITest): boolean {
        return ! this.requirement.test(props);
    }
    
}